import { useMemo } from 'react';
import DataLayout from './layout/DataLayout';
import { Typography } from 'antd';
import Table from './Table';
import RestrictionValidator from './RestrictionValidator';

const RestrictionsColumns = [
  {
    Header: 'Restriction',
    accessor: 'label',
  },
  {
    Header: 'Value(s)',
    accessor: 'values',
    Cell: ({ cell }: { cell: any }) =>
      (cell.value || []).length ? (
        <ul style={{ listStyleType: 'none' }}>
          {cell.value.map((v: string) => (
            <li>{v}</li>
          ))}
        </ul>
      ) : (
        '-'
      ),
  },
  {
    Header: 'Validation',
    accessor: 'validation',
    width: 200,
    Cell: ({ cell }: { cell: any }) => (
      <RestrictionValidator values={cell.row.original.values || []} type={cell.row.original.type} />
    ),
  },
];

const PoolRestrictions = ({ pool }: { pool: any }) => {
  const { productFamily, oemUUID, emailDomain, hasRestrictions } = useMemo(() => {
    const productFamily = pool?.restrictions?.productFamily || [];
    const oemUUID = pool?.restrictions?.oemUUID || [];
    const emailDomain = pool?.restrictions?.emailDomain || [];
    const hasRestrictions = productFamily.length || oemUUID.length || emailDomain.length;
    return { productFamily, oemUUID, emailDomain, hasRestrictions };
  }, [pool]);

  const content = () => {
    if (!hasRestrictions) {
      return (
        <Typography.Paragraph style={{ width: '100%', textAlign: 'center' }}>
          There are currently no product or OEM restrictions configured on this pool.
        </Typography.Paragraph>
      );
    }
    return (
      <Table
        columns={RestrictionsColumns}
        data={[
          {
            label: 'Product Family',
            values: productFamily,
            type: 'productFamily',
          },
          {
            label: 'OEM (Manufacturer) UUID',
            values: oemUUID,
            type: 'oemuuid',
          },
          {
            label: 'Allowed Email Domains',
            values: emailDomain,
            type: 'emailDomain',
          },
        ]}
      />
    );
  };

  return (
    <DataLayout title="OEM & Product Restrictions" nested>
      {content()}
    </DataLayout>
  );
};

export default PoolRestrictions;
