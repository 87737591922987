import React from 'react';
import styled from 'styled-components';
import TopNav from './TopNav';
import SideNav from './SideNav';
import Routes from './Routes';

const MainWrap = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${p => p.theme.colour.lightSilver};
`;

const ContentWrap = styled.div`
  padding-top: 40px;
  background-color: ${p => p.theme.colour.lightSilver};
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

const RouteWrap = styled.div`
  background-color: ${p => p.theme.colour.lightSilver};
  padding-bottom: 2rem;
  margin-left: 200px;
  width: calc(100vw - 200px);
`;

const MainLayout = () => {
  return (
    <MainWrap>
      <ContentWrap>
        <TopNav />
        <SideNav />
        <RouteWrap>
          <Routes />
        </RouteWrap>
      </ContentWrap>
    </MainWrap>
  );
};

export default MainLayout;
