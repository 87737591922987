import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { RouteProps } from 'react-router-dom';
import DataLayout from '../components/layout/DataLayout';
import Loader from '../components/Loader';
import { SKUTree, FindSKUs } from '../graphql/queries';
import { ClientType, getClient } from '../graphql/client';
import { notification } from 'antd';
import DebounceSelect from '../components/DebounceSelect';
import Tree from 'react-d3-tree';
import SKUTreeElement from '../components/SKUTreeElement';
import { formatDescription } from '../formatter/pool';
import SKUDetailModal from '../components/SKUDetailModal';
import Button from '../components/Button';
import { useMsal } from '@azure/msal-react';

const Results = styled.div`
  margin-top: 3rem;
`;

const LegendWrap = styled.div`
  display: inline-block;
  margin-right: 24px;
  margin-top: 12px;
  border: 1px solid #323232;
  padding: 8px;
  width: fit-content;
  border-radius: 5px;
`;

const Legend = styled.div`
  border-radius: 50px;
  min-width: 20px;
  min-height: 20px;
  margin-left: 12px;
  display: inline-block;
  border: 1px solid black;
  vertical-align: text-top;
`;

const mapNodeAttributes = (node: any) => ({
  createdAt: node.created?.at,
  enabled: node.enabled ? 'Yes' : 'No',
  productFamily: node.productFamily,
  type: node.type,
  description: node.description,
  priceUSD: node.priceUSD,
  depth: node._depth,
  features: node.features.map((f: any) => `(${f.id} => ${f.value.join('; ')})`).join(', '),
});

const generateNode = (skuTree: any[]) => (node: any): any => {
  const upgrades = skuTree.filter((sku: any) => (node.upgradeSKUs || []).includes(sku.sku));
  return {
    attributes: mapNodeAttributes(node),
    name: node.sku,
    children: upgrades.map(generateNode(skuTree)),
  };
};

const getDepth = (children: any[]): number => {
  return children.reduce((depth, child) => {
    return Math.max(depth, 1 + getDepth(child.children)); // increment depth of children by 1, and compare it with accumulated depth of other children within the same element
  }, 0);
};

const generateTree = (skuTree: any[], baseSKU: string) => {
  const origin = skuTree.find(sku => sku.sku === baseSKU);
  const upgrades = skuTree.filter((sku: any) => (origin.upgradeSKUs || []).includes(sku.sku));
  const children = upgrades.map(generateNode(skuTree));
  return {
    treeData: {
      attributes: mapNodeAttributes(origin),
      name: origin.sku,
      children,
    },
    maxDepth: getDepth(children),
  };
};

const SKUs: React.FC<RouteProps> = () => {
  const { instance } = useMsal();

  const client = getClient(ClientType.poolAllocations, instance);

  const [loading, setLoading] = useState(false);
  const [skuDetail, setSKUDetail] = useState<any>(null);
  const [baseSKU, setBaseSKU] = useState<any>(undefined);
  const [skuTree, setSKUTree] = useState<any[]>([]);
  const [selectOpen, setSelectOpen] = useState<boolean>(false);
  const [orientation, setOrientation] = useState<'horizontal' | 'vertical'>('vertical');

  const shouldRecenterTreeRef = useRef(true);
  const [treeTranslate, setTreeTranslate] = useState({ x: 0, y: 0 });
  const treeContainerRef = useRef<HTMLDivElement>(null);

  const fetchSKUTree = useCallback(
    async (baseSKU: string) => {
      setSKUTree([]);
      try {
        const result = await client.query({
          query: SKUTree,
          variables: { sku: baseSKU, onlyEnabled: true },
        });
        if (result.data?.skuTree) {
          setSKUTree(result.data?.skuTree);
        }
      } catch (error) {
        console.error(error);
        notification.error({ message: 'Unable to retrieve data for SKU.' });
      }
      setLoading(false);
    },
    [client]
  );

  async function fetchSKUs(search: string): Promise<any> {
    try {
      const result = await client.query({
        query: FindSKUs,
        variables: { query: search, onlyEnabled: true, limit: 10 },
      });
      return (result.data?.skuSearch || []).map((sku: any) => ({
        label: (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ display: 'flex' }}>{sku.sku}</span>
            <span style={{ display: 'flex', fontSize: 12 }}>
              <strong style={{ marginRight: 2 }}>{sku.type} </strong>
              {' - '}
              {formatDescription(sku.description)}
            </span>
          </div>
        ),
        value: sku.sku,
      }));
    } catch (error) {
      console.error(error);
      notification.error({ message: 'Unable to retrieve SKU list.' });
      return [];
    }
  }

  useEffect(() => {
    if (baseSKU && baseSKU.length) {
      const sku = baseSKU[0];
      setLoading(true);
      fetchSKUTree(sku);
    } else {
      setSKUTree([]);
    }
  }, [baseSKU, fetchSKUTree]);

  // eslint-disable-next-line
  useEffect(() => {
    if (treeContainerRef.current && shouldRecenterTreeRef.current) {
      shouldRecenterTreeRef.current = false;
      if (treeContainerRef.current) {
        const dimensions = treeContainerRef.current.getBoundingClientRect();
        setTreeTranslate({
          x: dimensions.width / 2,
          y: dimensions.height / 2,
        });
      }
    }
  });

  let content = <Loader />;

  if (!baseSKU) {
    content = <></>;
  } else if (!loading && baseSKU && skuTree.length) {
    const { maxDepth, treeData } = generateTree(skuTree, baseSKU[0]);
    content = (
      <Results
        ref={treeContainerRef}
        style={{
          width: '100%',
          height: `calc(100vh - 330px)`,
          marginTop: 12,
          borderRadius: 5,
          border: '1px solid #323232',
        }}
      >
        <Tree
          orientation={orientation}
          translate={treeTranslate}
          pathFunc="step"
          initialDepth={maxDepth > 2 ? 2 : undefined}
          shouldCollapseNeighborNodes={true}
          separation={{ siblings: 2.5, nonSiblings: 2.5 }}
          data={treeData}
          renderCustomNodeElement={rd3tProps => (
            <SKUTreeElement
              nodeDatum={rd3tProps.nodeDatum}
              toggleNode={rd3tProps.toggleNode}
              orientation="vertical"
              onNodeClick={(nodeData: any) => {
                if (nodeData.type === 'click') {
                  const sku = skuTree.find((sku: any) => sku.sku === nodeData.target.textContent);
                  setSKUDetail(sku);
                }
              }}
            />
          )}
        />
        {skuDetail && <SKUDetailModal detail={skuDetail} onClose={() => setSKUDetail(null)} />}
        <div>
          <LegendWrap>
            <label style={{ fontWeight: 'bold' }}>Legend:</label>
            <Legend style={{ backgroundColor: '#777' }} /> SKU with Upgrade(s)
            <Legend style={{ backgroundColor: 'transparent' }} /> SKU at a Terminal
          </LegendWrap>
          <Button
            label={`Change Orientation (${orientation === 'horizontal' ? 'Vertical' : 'Horizontal'})`}
            style={{ display: 'inline-block', minHeight: 45 }}
            onClick={() => {
              setOrientation(orientation === 'horizontal' ? 'vertical' : 'horizontal');
            }}
          />
        </div>
      </Results>
    );
  }
  return (
    <DataLayout title="SKU Viewer">
      <p style={{ marginTop: -16 }}>
        The SKU Viewer can be used to visualise the hierarchy of upgrades and options from a starting or base SKU. Click
        on a SKU's ID (eg DAV-H-1080-1x000) to view more details.
      </p>
      <label style={{ fontWeight: 'bold' }}>Starting / Base SKU:</label>
      <DebounceSelect
        mode="multiple"
        value={baseSKU}
        open={selectOpen}
        allowClear
        placeholder="Search for a SKU..."
        onFocus={() => {
          setSelectOpen(true);
        }}
        fetchOptions={fetchSKUs}
        onChange={newValue => {
          setSKUTree([]);
          if (!newValue.length) {
            setBaseSKU(undefined);
            setSelectOpen(true);
          } else {
            setBaseSKU([newValue[newValue.length - 1]]);
            setSelectOpen(false);
          }
        }}
        style={{ width: 400, marginLeft: 12 }}
      />
      {content}
    </DataLayout>
  );
};

export default SKUs;
