import React, { useCallback, useEffect, useState } from 'react';
import { RouteProps } from 'react-router-dom';
import DataLayout from '../components/layout/DataLayout';
import { capitalize, get, isEmpty, orderBy, uniq } from 'lodash';
import { ClientType, getClient } from '../graphql/client';
import { useParams } from 'react-router-dom';
import { Error, IDNotFound } from './Errors';
import gql from 'graphql-tag';
import {
  InboundAllocationsWithFilter,
  PaginatedOutboundAllocationsWithFilter,
  PoolQuery,
  SLSLicenseDataForEntitlementIdsQuery,
} from '../graphql/queries';
import Table, { DataColumns } from '../components/Table';
import { formatDestination, formatFastspringLink, formatTimestamp, mapPoolSKUFeatures } from '../formatter/pool';
import { PATHS } from '../components/layout/Routes';
import Tooltip from '../components/Tooltip';
import Loader from '../components/Loader';
import Button, { ButtonList } from '../components/Button';
import { CopyOutlined, DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import PoolRestrictions from '../components/PoolRestrictions';
import { Col, Dropdown, Menu, Row, message, notification } from 'antd';
import { write as writeXLSX, utils as utilXLSX } from 'xlsx';
import { PaginatedOutboundTable } from '../components/PaginatedTable';
import { useMsal } from '@azure/msal-react';

const FeatureColumns = [
  {
    accessor: 'sku',
    Header: 'SKU',
  },
  {
    accessor: 'type',
    Header: 'SKU Type',
  },
  {
    accessor: 'id',
    Header: 'Feature ID',
  },
  {
    accessor: 'value',
    Header: 'Value(s)',
    Cell: ({ cell }: { cell: any }) => <span>{(cell.value || []).join(', ')}</span>,
  },
];

export const InboundColumns = [
  {
    accessor: 'created',
    Header: 'Date / time',
    Cell: ({ cell }: { cell: any }) => <span>{formatTimestamp(cell.value.at)}</span>,
  },
  {
    accessor: 'source.name',
    Header: 'Source',
    Cell: ({ cell }: { cell: any }) => capitalize(cell.value || '-'),
  },
  {
    accessor: 'source',
    Header: 'Reference',
    Cell: ({ cell }: { cell: any }) => formatFastspringLink(cell.value),
  },
  {
    accessor: 'quantity',
    Header: 'Quantity',
  },
  {
    accessor: 'description',
    Header: 'Description',
    Cell: ({ cell }: { cell: any }) => <div style={{ textAlign: 'left' }}>{cell.value}</div>,
  },
];

export const InboundColumnsWithAction = [
  ...InboundColumns,
  {
    Header: 'Actions',
    accessor: 'pool.uuid',
    // @ts-ignore
    Cell: ({ cell }) =>
      cell.value ? (
        <ButtonList>
          <Button
            label="View Pool"
            icon={<EyeOutlined />}
            path={`${PATHS.POOLS}/${cell.value}?refParams=${encodeURIComponent(window.location.search)}`}
          />
          <Button
            label="Pool to Clipboard"
            icon={<CopyOutlined />}
            onClick={() => {
              notification.success({ message: 'Pool ID copied to your clipboard' });
              navigator.clipboard.writeText(cell.value);
            }}
          />
        </ButtonList>
      ) : null,
  },
];

export const OutboundColumns = [
  {
    accessor: 'no',
    Header: 'No.',
    width: 20,
    Cell: ({ cell }: { cell: any }) => (cell.value),
  },
  {
    accessor: 'created',
    Header: 'Date / time',
    Cell: ({ cell }: { cell: any }) => formatTimestamp(cell.value.at),
  },
  {
    accessor: 'destination',
    Header: 'Destination',
    Cell: ({ cell }: { cell: any }) => formatDestination(cell.value),
  },
  {
    accessor: 'metadata.refEmailAddress',
    Header: 'Reference Email Address',
    Cell: ({ cell }: { cell: any }) => (!cell.value || isEmpty(cell.value) ? '-' : cell.value),
  },
  {
    accessor: 'superseded',
    Header: 'Superseded',
    width: 40,
    Cell: ({ cell }: { cell: any }) => (!!cell.value ? <strong>Yes</strong> : 'No'),
  },
];

export const OutboundColumnsWithAction = [
  ...OutboundColumns,
  {
    Header: 'Actions',
    accessor: 'pool.uuid',
    // @ts-ignore
    Cell: ({ cell }) =>
      cell.value ? (
        <ButtonList>
          <Button
            label="View Pool"
            icon={<EyeOutlined />}
            path={`${PATHS.POOLS}/${cell.value}?refParams=${encodeURIComponent(window.location.search)}`}
          />
          <Button
            label="Pool to Clipboard"
            icon={<CopyOutlined />}
            onClick={() => {
              notification.success({ message: 'Pool ID copied to your clipboard' });
              navigator.clipboard.writeText(cell.value);
            }}
          />
        </ButtonList>
      ) : null,
  },
];

const Pool: React.FC<RouteProps> = () => {
  let { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>(null);
  const [inboundAllocations, setInboundAllocations] = useState<any>(null);
  const [error, setError] = useState<string | undefined>(undefined);
  const [outboundTotal, setOutboundTotal] = useState(0);
  const { instance } = useMsal();


  const load = useCallback(async () => {
    const client = getClient(ClientType.poolAllocations, instance);

    setData(null);
    setInboundAllocations([]);
    setError(undefined);
    setLoading(true);
    setOutboundTotal(0);

    try {
      const poolQuery = await client.query({
        query: gql`
          ${PoolQuery}
        `,
        variables: {
          id,
        },
      });

      setData(poolQuery.data.pool);

      const inboundQuery = await client.query({
        query: InboundAllocationsWithFilter(`$id: String!`, `pool: { uuid: $id}`),
        variables: {
          id,
        },
      });

      // Just do first call to check Outbound Total Summary using pageInfo result of paginatedOutbound call
      const outboundQuery = await client.query({
        query: PaginatedOutboundAllocationsWithFilter(1, 1, `pool: "${id}"`),
      });

      setInboundAllocations(orderBy(inboundQuery.data?.inboundAllocations || [], ['created.at'], ['desc']));
      setOutboundTotal( outboundQuery.data?.paginatedOutboundData?.pageInfo?.totalCount || 0);
    } catch (error) {
      setLoading(false);
      console.error(error);
      if (error instanceof Error) {
        setError(error.toString());
      }
    }

    setLoading(false);
  }, [id, instance]);

  useEffect(() => {
    load();
  }, [load]);

  const onExportEntitlements = useCallback(
    (type = 'json') => {
      setLoading(true);
      const client = getClient(ClientType.poolAllocations, instance);

      // Retrieve all outbound based on the total outboundCount
      client
        .query({
          query: PaginatedOutboundAllocationsWithFilter(1, outboundTotal, `pool: "${id}"`),
        })
          .then(response => {
            const allOutboundAllocations = response?.data?.paginatedOutboundData?.result || [];
            const entitlementIds = uniq(
              (allOutboundAllocations || [])
                .map((oa: any) => {
                  return get(oa, 'destination.entitlement', null);
                })
                .filter((id: any) => !!id)
            );
              client
              .query({
                query: gql`
                  ${SLSLicenseDataForEntitlementIdsQuery}
                `,
                variables: {
                  ids: entitlementIds,
                },
              })
              .then(response => {
                const activationData = get(response, 'data.getSLSActivationDataForEntitlementIds');
                if (!(activationData || []).length) {
                  return message.error('Unable to retrieve data.');
                }
      
                const result = activationData.map((item: any) => {
                  const outboundAllocation = allOutboundAllocations.findLast(
                    (oa: any) => oa.destination.entitlement === item.licenseId
                  );
                  return {
                    ...item,
                    superseded: outboundAllocation?.superseded,
                    dateTime: outboundAllocation?.created.at,
                  };
                });
      
                let exportResult = new Blob();
      
                if (type === 'xlsx') {
                  const rows = [
                    [
                      'Date & Time',
                      'Activation ID',
                      'License ID',
                      'Superseded',
                      'Manufacturer ID',
                      'Manufacturer Name',
                      'Model ID',
                      'Model Name',
                      'Product Code',
                      'Product Name',
                      'Client IP Address',
                      'MAC Address',
                      'SoC UID',
                      'Hardware UUID',
                    ],
                  ];
                  for (const activation of result) {
                    const endpoint = get(activation, 'endpoint');
                    rows.push([
                      activation.dateTime,
                      activation.id,
                      activation.licenseId,
                      activation.superseded ? 'Yes' : 'No',
                      endpoint.manufacturer.id,
                      endpoint.manufacturer.name,
                      endpoint.model.id,
                      endpoint.model.name,
                      endpoint.product.code,
                      endpoint.product.name,
                      activation.clientIpAddress,
                      endpoint.macAddress.join(', '),
                      endpoint.socUid,
                      endpoint.hardwareUuid,
                    ]);
                  }
                  let worksheet = utilXLSX.aoa_to_sheet(rows);
                  if (!worksheet['!cols']) worksheet['!cols'] = [];
                  worksheet['!autofilter'] = { ref: 'A1:L1' };
                  const workbook = utilXLSX.book_new();
                  for (let c = 0; c <= 12; c++) {
                    worksheet['!cols'][c] = { wch: 36 };
                  }
      
                  utilXLSX.book_append_sheet(workbook, worksheet, 'Activations');
                  const buffer = writeXLSX(workbook, { bookType: 'xlsx', compression: true, type: 'buffer' });
                  exportResult = new Blob([buffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  });
                } else {
                  const jsonString = JSON.stringify(
                    {
                      poolId: id,
                      activations: result,
                    },
                    null,
                    2
                  );
                  exportResult = new Blob([jsonString]);
                }
      
                const url = window.URL.createObjectURL(exportResult);
      
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `pool-${id}-activation-data.${type}`);
      
                document.body.appendChild(link);
                link.click();
      
                if (link && link.parentNode) {
                  link.parentNode.removeChild(link);
                }
      
                notification.success({
                  message: <span>{type.toUpperCase()} export downloading&hellip;</span>,
                  duration: 10,
                });
              })
              .catch(e => {
                console.error(e);
                return message.error('Unable to retrieve JSON data.');
              })
              .finally(() => {
                setLoading(false);
              });
          })
        .catch(e => {
          console.error(e);
          return message.error('Unable to retrieve all outbound data.');
        });
    },
    [instance, id, outboundTotal]
  );

  let content = <Loader />;

  if (!id || (!loading && !data && !error)) {
    content = <IDNotFound />;
  } else if (error) {
    content = <Error message={error} />;
  } else if (data) {
    const mappedSKUFeatures = mapPoolSKUFeatures(data);
    const poolData = [
      {
        label: 'Pool UUID',
        value: <strong>{data.uuid}</strong>,
      },
      {
        label: 'Owner',
        value: get(data, 'owner.email', '-'),
      },
      {
        label: (
          <span>
            Pool Balance <Tooltip message="Total inbound allocations - Outbound allocations." />
          </span>
        ),
        value: get(data, 'balance', 0),
      },
      {
        label: (
          <span>
            Credit limit <Tooltip message="For OEM post-pay pools only." />
          </span>
        ),
        value: get(data, 'creditLimit', '-'),
      },
    ];
    content = (
      <>
        <DataLayout title="Summary" nested>
          <Table negativeMargin data={poolData} columns={DataColumns} headless={true} sorting={false} />
        </DataLayout>
        <PoolRestrictions pool={data} />
        <DataLayout title={`[ ${(mappedSKUFeatures || []).length} ] Features in SKU(s)`} nested>
          <Table data={mappedSKUFeatures} columns={FeatureColumns} sorting={false} />
        </DataLayout>
        <DataLayout title={`[ ${(inboundAllocations || []).length} ] Inbound allocations (purchases)`} nested>
          <Table data={inboundAllocations || []} columns={InboundColumns} sorting={false} />
        </DataLayout>
        <DataLayout title={`[ ${outboundTotal} ] Outbound allocations (activations)`} nested>
          {outboundTotal > 0 ? (
            <Row>
              <Col span={8}>
                {outboundTotal ? (
                  <>
                    <Dropdown
                      disabled={loading}
                      overlay={
                        <Menu>
                          <Menu.Item>
                            <div onClick={() => onExportEntitlements()}>JSON</div>
                          </Menu.Item>
                          <Menu.Item>
                            <div onClick={() => onExportEntitlements('xlsx')}>Excel (XLSX)</div>
                          </Menu.Item>
                        </Menu>
                      }
                    >
                      <Button
                        icon={<DownloadOutlined />}
                        label="Export all"
                        type="primary"
                        style={{ marginBottom: 12, marginTop: -12 }}
                        loading={loading}
                      />
                    </Dropdown>
                  </>
                ) : null}
              </Col>
            </Row>
          ) : (
            <React.Fragment />
          )}
          <PaginatedOutboundTable columns={OutboundColumns} tableName={`poolOutbound`} filter={`pool: "${id}"`} instance={instance}/>
        </DataLayout>
      </>
    );
  }
  return (
    <DataLayout
      showRefresh={true}
      refreshAction={load}
      backPath={PATHS.POOLS}
      backLabel="Back to Pools &amp; Transactions - Search"
      title={`Pool detail`}
    >
      {content}
    </DataLayout>
  );
};

export default Pool;
