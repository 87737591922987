import React, { useCallback, useEffect, useState } from 'react';
import { RouteProps, useNavigate } from 'react-router-dom';
import DataLayout from '../components/layout/DataLayout';
import { PATHS } from '../components/layout/Routes';
import { get, isEqual, isNil, pick, uniq } from 'lodash';
import { ClientType, getClient } from '../graphql/client';
import { useParams } from 'react-router-dom';
import { Error, IDNotFound } from './Errors';
import gql from 'graphql-tag';
import {
  FindSKUs,
  PoolQuery,
  PoolRestrictionUpdate,
  ProductConfigCreate,
  ProductConfigQuery,
  ProductConfigQueryByManufacturerModelId,
  ProductConfigQueryVersionByManufacturerModelId,
  ProductConfigUpdate,
  SKUTree,
  SKUs,
} from '../graphql/queries';
import Loader from '../components/Loader';
import Button from '../components/Button';
import {
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  InfoCircleTwoTone,
  LockOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  SaveOutlined,
  WarningTwoTone,
} from '@ant-design/icons';
import { Card, Divider, Form, Input, InputNumber, List, message, Modal, Radio, Tooltip, Typography, Checkbox } from 'antd';
import { DirtyFieldIndicator, FormItem, TOOLTIPS } from '../components/Form';
import { ASCIItoHex, hextoASCII, isValidUUIDv4, REGEXP } from '../helper/form';
import {
  LOCALES,
  PRODUCT_CONFIGURATION_DEFAULTS,
  formToProductConfig,
  generatePrefixedLocaleList,
  initConfigFromSKU,
  productConfigToForm,
} from '../helper/productConfig';
import { hasWriteAccess } from '../helper/authms';
import { ButtonGroup, LanguageGroup, ProductConfigurationHeader } from './ProductConfig.styles';
import AsyncSelect from 'react-select/async';
import debounce from 'debounce-promise';
import { components } from 'react-select';
import { formatDescription } from '../formatter/pool';
import styled from 'styled-components';
import SKUDetailModal from '../components/SKUDetailModal';
import TextArea from 'antd/lib/input/TextArea';
import PoolDataLoader from '../components/PoolDataLoader';
import { useMsal } from '@azure/msal-react';

const SKUOption = (props: any) => {
  return (
    <components.Option {...props} style={{ display: 'flex', flexDirection: 'column' }}>
      <div>
        <strong>{props.label}</strong>
      </div>
      <div>{formatDescription(props.value.description, 50)}</div>
    </components.Option>
  );
};

const SKUTreeList = styled(List)`
  div > div > ul > li:first-child {
    padding-top: 0;
  }
`;

const Product: React.FC<RouteProps> = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { instance } = useMsal();;

  const [form] = Form.useForm();

  const [checked, setChecked] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [data, setData] = useState<any>(null);
  const [skuTree, setSKUTree] = useState<any>(undefined);
  const [selectedProductSKUIndex, setSelectedProductSKUIndex] = useState<number>(0);
  const [error, setError] = useState<string | undefined>(undefined);
  const [hasTouchedManfModelId, setHasTouchedManfModelId] = useState(false);
  const [hasTouchedPoolUUIDConfig, setHasTouchedPoolUUIDConfig] = useState(false);
  const [skuDetail, setSKUDetail] = useState<any>(null);
  const isNew = id === 'new';
  const canEdit = hasWriteAccess(instance.getActiveAccount());
  const submitButtonLabel = editing ? 'Save Product' : 'Edit Product';
  const submitButtonIcon = editing ? <SaveOutlined /> : <EditOutlined />;
  const submitButtonAction = editing ? () => onSubmit() : () => setEditing(!editing);

  let content = <Loader />;

  const getSKUTreeState = (skuTree: any, productSKUs = [], selectedIndex: number, baseSKU: string) => {
    const selectedSKU = (productSKUs[selectedIndex] as any)?.sku;
    return skuTree.map((sku: any) => {
      const isBaseSKU = sku.sku === baseSKU;
      const productSKU = productSKUs.find((ps: any) => ps.sku && ps.sku?.sku === sku.sku) as any;
      const isSelected = selectedSKU && selectedSKU.sku === sku.sku;
      return {
        ...sku,
        skuData: sku,
        isBaseSKU,
        isSelected,
        selectedSKU,
        isEnabled: !!productSKU || isSelected,
      };
    });
  };

  const onVersionChange = (field: any) => (e: any) => {
    if (!e.target.value) {
      return;
    }

    if (e.target.validity.valid) {
      if (Number(e.target.value) < 0) {
        form.setFieldValue(field, '');
        form.validateFields([field]);
      }
      return;
    }

    form.setFieldValue(field, '');
    form.validateFields([field]);
  };

  const getDataForSKUbyId = useCallback(
    async (skuIds: string[] = []) => {
      const client = getClient(ClientType.poolAllocations, instance);
      const result = await client.query({
        query: SKUs,
        variables: { skuIds },
      });
      return result.data?.skus || [];
    },
    [instance]
  );

  const onSKUSearch = debounce((search: string) => {
    const client = getClient(ClientType.poolAllocations, instance);
    return new Promise<any>(async resolve => {
      try {
        const skuSearchResult = await client.query({
          query: FindSKUs,
          variables: { query: search, onlyEnabled: true, limit: 10, type: 'PRODUCT', productBase: true },
        });
        const results = (skuSearchResult.data?.skuSearch || []).filter((sku: any) => sku.type === 'PRODUCT');
        resolve(
          results.map((sku: any) => ({
            label: sku.sku,
            value: sku,
          }))
        );
      } catch (e) {
        resolve([]);
      }
    });
  }, 500);

  const onResolveSKUTree = useCallback(
    async (baseSKU: string) => {
      const client = getClient(ClientType.poolAllocations, instance);
      try {
        const skuSearchResult = await client.query({
          query: SKUTree,
          variables: { sku: baseSKU, onlyEnabled: true, excludeBaseSKU: true },
        });
        const results = (skuSearchResult.data?.skuTree || [])
          .filter((sku: any) => sku.type === 'PRODUCT' && !sku.factoryOnly)
          .sort((a: any, b: any) => (a.priceUSD || 0) - (b.priceUSD || 0));
        setSKUTree(results);
        return results;
      } catch (e) {
        console.error(e);
        setError('Unable to obtain SKU tree for product.');
      }
    },
    [instance]
  );

  const onFeatureSKUSearch = debounce((productFamily: string, search: string) => {
    const client = getClient(ClientType.poolAllocations, instance);
    return new Promise<any>(async resolve => {
      try {
        const skuSearchResult = await client.query({
          query: FindSKUs,
          variables: { query: search, onlyEnabled: true, limit: 10, productFamily, type: 'ADD_FEATURE' },
        });
        const results = (skuSearchResult.data?.skuSearch || []).filter((sku: any) => !sku.factoryOnly);
        resolve(
          results.map((sku: any) => ({
            label: sku.sku,
            value: sku,
          }))
        );
      } catch (e) {
        resolve([]);
      }
    });
  }, 500);

  const loadProduct = useCallback(() => {
    const client = getClient(ClientType.productConfiguration, instance);
    setError(undefined);
    setData(null);
    setLoading(true);
    client
      .query({
        query: gql`
          ${ProductConfigQuery}
        `,
        variables: {
          id,
        },
      })
      .then((response: any) => {
        const exists = !isNil(get(response, 'data.configuration'));
        if (!exists) {
          navigate('/products');
          message.info(`Product not found, it may have been deleted.`);
          return;
        }
        // Obtain all of the SKU data we need...
        const data = response.data.configuration;
        const skuIDs = uniq([
          ...(data.productSKUs || []).map((ps: any) => get(ps, 'sku')),
          ...(data.featureSKUs || []).map((ps: any) => get(ps, 'sku')),
        ]);
        if (!skuIDs.length) {
          const formData = productConfigToForm(response.data.configuration, []);
          setData({ original: response.data.configuration, form: formData });
          setLoading(false);
        }

        return getDataForSKUbyId(skuIDs)
          .then((skuData: any[]) => {
            const formData = productConfigToForm(response.data.configuration, skuData);
            setData({ original: response.data.configuration, form: formData });
            if (formData.baseSKU?.sku) {
              onResolveSKUTree(formData.baseSKU.sku);
            }
          })
          .finally(() => setLoading(false));
      })
      .catch((error: any) => {
        console.error(error);
        setData(null);
        setError(error.toString());
        setLoading(false);
      });
  }, [id, instance, navigate, getDataForSKUbyId, onResolveSKUTree]);

  // Reset form on component load.
  useEffect(() => {
    form.resetFields();
    setSelectedProductSKUIndex(0);
    /* eslint-disable */
  }, []);

  // Adjust state based on whether product is new or existing, and whether the user is editing it.
  useEffect(() => {
    if (!isNew && loading) {
      loadProduct();
    } else if (isNew && !editing) {
      setData({ form: PRODUCT_CONFIGURATION_DEFAULTS });
      setEditing(true);
      setLoading(false); // No need to load no product.
    }
  }, [editing, isNew, loading, loadProduct]);

  // Submit validation handler.
  const onSubmit = useCallback(() => {
    form
      .validateFields()
      .then(() => {
        const formData = form.getFieldsValue();
        if (!formData.baseSKU || !(formData.productSKUs || []).length) {
          message.error('You must select a Product Base SKU before creating a product.', 6);
          return;
        }

        const productConfig = formToProductConfig(
          form.getFieldsValue(),
          data ? data.original : undefined,
          isNew ? undefined : id
        );
        if (!productConfig) {
          message.error('There was an unknown error preparing this product.', 6);
          return;
        }
        setSubmitting(true);
        form.submit();
      })
      .catch((errors: any) => {
        const errorFields = errors?.errorFields || [];
        if (errorFields.length) {
          const productSKUErrors = errorFields.filter((ef: any) => get(ef, 'name')[0] === 'productSKUs');
          if (productSKUErrors.length) {
            const lastError = productSKUErrors[productSKUErrors.length - 1];
            setSelectedProductSKUIndex(lastError.name[1]); // field name [1] contains the field index.
          }
        }
        message.error('There are problems with your product. Please correct them and try again.', 8);
        setSubmitting(false);
      });
  }, [data, form, id, isNew]);

  if (isNew && !canEdit) {
    navigate('/products');
  }

  const checkDirtyManfModelId = (e: React.FormEvent<HTMLInputElement>) => {
    if (!isNew && !hasTouchedManfModelId) {
      Modal.confirm({
        title: 'Confirm Change',
        icon: <ExclamationCircleOutlined />,
        content: (
          <div>
            <strong>Warning: </strong>
            Changing the manufacturer ID or model ID for an existing product will break live end user IFE functionality.
            Endpoints that have this manufacturer ID : model ID will no longer appear in the Dante Activator GUI. Are
            you sure you want to do this?
          </div>
        ),
        okText: 'Cancel',
        cancelText: <span>I understand the risks&hellip;</span>,
        width: 600,
        onCancel: () => {
          setHasTouchedManfModelId(true);
          message.info('You can now make changes to the Manufacturer or Model ID.');
        },
      });
      return false;
    }
    return true;
  };

  const checkPoolUUID = (e: React.FormEvent<HTMLInputElement>, origPool:string, index:number) => {
    if (!isNew && !hasTouchedPoolUUIDConfig) {
      Modal.confirm({
        title: 'Confirm Pool UUID',
        icon: <ExclamationCircleOutlined />,
        content: (
          <div>
            <strong>Warning: </strong>
            Adding or updating the pool UUID for an existing product will break live end user IFE functionality.
            Are you sure you want to do this?
          </div>
        ),
        okText: 'Cancel',
        cancelText: <span>I understand the risks&hellip;</span>,
        width: 600,
        onCancel: () => {
          setHasTouchedPoolUUIDConfig(true);
        },
        onOk: () => {
          form.setFieldValue(['productSKUs', index, 'oemPoolUUID'], origPool);
          setHasTouchedPoolUUIDConfig(true);
        },
      });
      return false;
    }
    return true;
  };

  // Submission handler (when validated).
  const processSubmit = useCallback(async () => {
    const productConfig = formToProductConfig(
      form.getFieldsValue(),
      data ? data.original : undefined,
      isNew ? undefined : id
    );
    const client = getClient(ClientType.productConfiguration, instance);
    const hasVersion = !!productConfig.version;

    try {
      // Check to see if this product already exists (matching model, manufacturer and version combo)
      const existing = await client.query({
        query: gql`
          ${hasVersion ? ProductConfigQueryVersionByManufacturerModelId : ProductConfigQueryByManufacturerModelId}
        `,
        variables: {
          manufacturerId: productConfig.manufacturerId,
          modelId: productConfig.modelId,
          ...(hasVersion ? { version: productConfig.version } : {}),
        },
      });

      const productExists = !isNil(get(existing, 'data.configuration._id'));

      if (isNew && productExists) {
        message.error('A product that matches this model, manufacturer and version already exists.', 8);
        setSubmitting(false);
        return;
      }

      // If an OEM pool UUID or OEM domain locked pool UUID has been set, verify that it exists and has the correct features.

      // Check each OEM Pool UUID and OEM domain locked Pool UUID.
      const pamClient = getClient(ClientType.poolAllocations, instance);
      for (const [index, productSKU] of productConfig.productSKUs.entries()) {
        if (productSKU.associatedPoolId) {
          const existingPool = await pamClient.query({
            query: gql`
              ${PoolQuery}
            `,
            variables: {
              id: productSKU.associatedPoolId,
            },
          });

          const existingPoolData = get(existingPool, 'data.pool');
          if (!existingPoolData) {
            message.error(`OEM Pays Pool " ${productSKU.associatedPoolId} " does not exist.`, 8);
            setSubmitting(false);
            return;
          }

          const poolProductSKUEnabled = get(existingPoolData, 'productSKU.enabled', false);
          if (!poolProductSKUEnabled) {
            message.error(`OEM Pays Pool " ${productSKU.associatedPoolId} " has a disabled Product SKU.`, 8);
            setSubmitting(false);
            return;
          }

          const existingPoolProductSKU = get(existingPoolData, 'productSKU.sku');
          if (existingPoolProductSKU !== productSKU.sku) {
            message.error(
              `OEM Pays Pool " ${productSKU.associatedPoolId} " is a ${existingPoolProductSKU} not ${productSKU.sku}`,
              8
            );
            setSubmitting(false);
            return;
          }

          const domainList = ['productSKUs', index, 'domainList'];
          const domainLockOption = ['productSKUs', index, 'domainPoolOption'];
          const isDomainLocked = JSON.parse(form.getFieldValue(domainLockOption));
          const emailDomain = isDomainLocked ? form.getFieldValue(domainList) : [];

          const domainUpdate = await pamClient.query({
            query: gql`
              ${PoolRestrictionUpdate}
            `,
            variables: {
              poolUUID: productSKU.associatedPoolId,
              emailDomain: emailDomain
            },
          });

          if (!(domainUpdate.data.updatePoolRestriction?.status === "complete")) {
            message.error(`Unable to update OEM/ODM Email Domain Locked Pool " ${productSKU.associatedPoolId} "`, 9);
            setSubmitting(false);
            return;
          }
        }
      }

      if (isNew) {
        const result = await client.mutate({
          mutation: gql`
            ${ProductConfigCreate}
          `,
          variables: {
            data: productConfig,
          },
        });
        const newId = get(result, 'data.insertOneConfiguration._id');
        if (newId) {
          setTimeout(() => {
            window.location.href = `/products/${newId}`;
          }, 2000);
        }
      } else {
        await client.mutate({
          mutation: gql`
            ${ProductConfigUpdate}
          `,
          variables: {
            id,
            data: productConfig,
          },
        });
        setSubmitting(false);
        setEditing(false);
      }
    } catch (e) {
      message.error('An error occured communicating with the database.');
      setSubmitting(false);
      console.error(e);
      return;
    }

    message.success(`Product ${isNew ? 'created' : 'updated'} successfully.`);
    setTimeout(() => loadProduct(), 2500);
  }, [form, id, instance, data, isNew]);

  // Page Content
  if (!isNew && (!id || (!loading && !data && !error))) {
    content = <IDNotFound />;
  } else if (error) {
    content = <Error message={error} />;
  } else if (data || (isNew && editing)) {
    content = (
      <Form
        colon={false}
        layout="horizontal"
        disabled={!editing || submitting}
        initialValues={data ? data.form : undefined}
        onFinish={() => {
          if (submitting) {
            processSubmit();
          }
        }}
        scrollToFirstError={false}
        form={form}
      >
        <DataLayout title="Product Details" nested>
          <FormItem
            label="Manufacturer ID"
            required
            shouldUpdate={(prev, cur) =>
              !isEqual(
                pick(prev, ['manufacturerIdHex', 'manufacturerIdASCII']),
                pick(cur, ['manufacturerIdHex', 'manufacturerIdASCII'])
              )
            }
          >
            {() => {
              return (
                <div style={{ marginTop: 15, position: 'relative' }}>
                  <Form.Item
                    tooltip={TOOLTIPS.manufacturerId}
                    label="Hex:"
                    style={{ display: 'inline-block', width: 300 }}
                    name="manufacturerIdHex"
                    rules={
                      !editing
                        ? []
                        : [
                            { pattern: new RegExp(REGEXP.manufacturerModelId), message: 'Invalid hexadecimal value.' },
                            { required: true, message: 'Manufacturer ID is required.' },
                            { max: 16, message: 'Manufacturer ID can not exceed 16 characters.' },
                          ]
                    }
                  >
                    <Input
                      addonBefore="0x"
                      maxLength={16}
                      style={{ fontFamily: 'monospace' }}
                      onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        if (checkDirtyManfModelId(e)) {
                          const targetValue = (e.currentTarget.value || '').toLowerCase();
                          form.setFieldValue('manufacturerIdHex', targetValue);
                          form.setFieldValue('manufacturerIdASCII', hextoASCII(targetValue));
                          form.validateFields(['manufacturerIdASCII']);
                        } else {
                          form.resetFields(['manufacturerIdASCII', 'manufacturerIdHex']);
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{ display: 'inline-block', width: 300, marginLeft: 12 }}
                    label="String:"
                    tooltip={TOOLTIPS.manufacturerIdASCII}
                    name="manufacturerIdASCII"
                    rules={
                      !editing
                        ? []
                        : [{ pattern: new RegExp(REGEXP.manufacturerModelIdASCII), message: 'Invalid ASCII ID.' }]
                    }
                  >
                    <Input
                      maxLength={8}
                      onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        if (checkDirtyManfModelId(e)) {
                          form.setFieldValue('manufacturerIdHex', ASCIItoHex(e.currentTarget.value).toLowerCase());
                          form.validateFields(['manufacturerIdHex']);
                        } else {
                          form.resetFields(['manufacturerIdASCII', 'manufacturerIdHex']);
                        }
                      }}
                    />
                  </Form.Item>
                  <DirtyFieldIndicator
                    form={form}
                    dirtyFields={['manufacturerIdHex', 'manufacturerIdASCII']}
                    enabled={editing}
                  />
                </div>
              );
            }}
          </FormItem>
          <FormItem
            label="Model ID"
            required
            shouldUpdate={(prev, cur) =>
              !isEqual(pick(prev, ['modelIdHex', 'modelIdASCII']), pick(cur, ['modelIdHex', 'modelIdASCII']))
            }
          >
            {() => {
              return (
                <div style={{ marginTop: 15, position: 'relative' }}>
                  <Form.Item
                    label="Hex:"
                    style={{ display: 'inline-block', width: 300 }}
                    tooltip={TOOLTIPS.modelId}
                    name="modelIdHex"
                    rules={
                      !editing
                        ? []
                        : [
                            { pattern: new RegExp(REGEXP.manufacturerModelId), message: 'Invalid hexadecimal value.' },
                            { required: true, message: 'Model ID is required.' },
                            { max: 16, message: 'Model ID can not exceed 16 characters.' },
                          ]
                    }
                  >
                    <Input
                      addonBefore="0x"
                      maxLength={16}
                      style={{ fontFamily: 'monospace' }}
                      onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        if (checkDirtyManfModelId(e)) {
                          const targetValue = (e.currentTarget.value || '').toLowerCase();
                          form.setFieldValue('modelIdHex', targetValue);
                          form.setFieldValue('modelIdASCII', hextoASCII(targetValue));
                          form.validateFields(['modelIdASCII']);
                        } else {
                          form.resetFields(['modelIdASCII', 'modelIdHex']);
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    tooltip={TOOLTIPS.modelIdASCII}
                    style={{ display: 'inline-block', width: 300, marginLeft: 12 }}
                    label="String:"
                    name="modelIdASCII"
                    rules={
                      !editing
                        ? []
                        : [{ pattern: new RegExp(REGEXP.manufacturerModelIdASCII), message: 'Invalid ASCII ID.' }]
                    }
                  >
                    <Input
                      maxLength={8}
                      onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        if (checkDirtyManfModelId(e)) {
                          form.setFieldValue('modelIdHex', ASCIItoHex(e.currentTarget.value).toLowerCase());
                          form.validateFields(['modelIdHex']);
                        } else {
                          form.resetFields(['modelIdASCII', 'modelIdHex']);
                        }
                      }}
                    />
                  </Form.Item>
                  <DirtyFieldIndicator form={form} dirtyFields={['modelIdHex', 'modelIdASCII']} enabled={editing} />
                </div>
              );
            }}
          </FormItem>
          <FormItem
            label="Product version"
            tooltip={TOOLTIPS.version}
            shouldUpdate={(prev, cur) =>
              !isEqual(
                pick(prev, ['versionA', 'versionB', 'versionC']),
                pick(cur, ['versionA', 'versionB', 'versionC'])
              )
            }
          >
            {() => {
              const placeholder =
                form.getFieldValue('versionA') || form.getFieldValue('versionB') || form.getFieldValue('versionC')
                  ? '0'
                  : '*';
              return (
                <div style={{ position: 'relative', marginTop: 15 }}>
                  <Form.Item
                    name="versionA"
                    style={{ display: 'inline-block' }}
                    rules={!editing ? [] : [{ max: 6, message: 'Cannot exceed 6 digits' }]}
                  >
                    <Input
                      onChange={onVersionChange('versionA')}
                      type="number"
                      placeholder={placeholder}
                      addonBefore="#"
                      style={{ width: 130 }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="versionB"
                    style={{ display: 'inline-block' }}
                    rules={!editing ? [] : [{ max: 6, message: 'Cannot exceed 6 digits' }]}
                  >
                    <Input
                      onChange={onVersionChange('versionB')}
                      type="number"
                      placeholder={placeholder}
                      style={{ width: 110 }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="versionC"
                    style={{ display: 'inline-block' }}
                    rules={!editing ? [] : [{ max: 6, message: 'Cannot exceed 6 digits' }]}
                  >
                    <Input
                      onChange={onVersionChange('versionC')}
                      type="number"
                      placeholder={placeholder}
                      style={{ width: 110 }}
                    />
                  </Form.Item>
                  <DirtyFieldIndicator
                    form={form}
                    dirtyFields={['versionA', 'versionB', 'versionC']}
                    enabled={editing}
                  />
                </div>
              );
            }}
          </FormItem>
          <FormItem
            label="Product enabled?"
            required
            tooltip={TOOLTIPS.active}
            shouldUpdate={(prev, cur) => !isEqual(pick(prev, ['active']), pick(cur, ['active']))}
          >
            {() => {
              return (
                <div style={{ position: 'relative', marginTop: 15 }}>
                  <Form.Item
                    name="active"
                    rules={!editing ? [] : [{ required: true, message: 'Please select an option.' }]}
                  >
                    <Radio.Group>
                      <Radio value={'false'}>No - disabled and not visible</Radio>
                      <Radio value={'true'}>Yes - enabled and visible</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <DirtyFieldIndicator form={form} dirtyFields={['active']} enabled={editing} />
                </div>
              );
            }}
          </FormItem>
          <FormItem
            label="Require factory pre-activation?"
            tooltip={TOOLTIPS.factoryPreactivation}
            required
            shouldUpdate={(prev, cur) =>
              !isEqual(pick(prev, ['preActivationOption']), pick(cur, ['preActivationOption']))
            }
          >
            {() => {
              return (
                <div style={{ position: 'relative', marginTop: 15 }}>
                  <Form.Item
                    name="preActivationOption"
                    rules={!editing ? [] : [{ required: true, message: 'Please select an option.' }]}
                  >
                    <Radio.Group>
                      <Radio value={'false'}>No - not required</Radio>
                      <Radio value={'true'}>Yes - required</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <DirtyFieldIndicator form={form} dirtyFields={['preActivationOption']} enabled={editing} />
                </div>
              );
            }}
          </FormItem>
          <FormItem
            label="OEM / ISV Contact"
            required
            tooltip={TOOLTIPS.owner}
            shouldUpdate={(prev, cur) => !isEqual(pick(prev, ['owner']), pick(cur, ['owner']))}
          >
            {() => {
              return (
                <div style={{ marginTop: 15, position: 'relative', display: 'flex' }}>
                  <Form.Item
                    name="owner"
                    rules={
                      !editing
                        ? []
                        : [
                            { type: 'email', message: 'Must be a valid email address.' },
                            { required: true, message: 'Please enter an email address.' },
                          ]
                    }
                  >
                    <Input addonBefore="@" style={{ width: 600 }} />
                  </Form.Item>
                  <DirtyFieldIndicator form={form} dirtyFields={['owner']} enabled={editing} />
                </div>
              );
            }}
          </FormItem>
          <FormItem
            label="Reference Number"
            tooltip={TOOLTIPS.reference}
            shouldUpdate={(prev, cur) => !isEqual(pick(prev, ['reference']), pick(cur, ['reference']))}
          >
            {() => {
              return (
                <div style={{ marginTop: 15, position: 'relative', display: 'flex' }}>
                  <Form.Item name="reference">
                    <Input style={{ width: 600 }} />
                  </Form.Item>
                  <DirtyFieldIndicator form={form} dirtyFields={['reference']} enabled={editing} />
                </div>
              );
            }}
          </FormItem>
          <FormItem
            label="Comments / Notes"
            shouldUpdate={(prev, cur) => !isEqual(pick(prev, ['notes']), pick(cur, ['notes']))}
          >
            {() => {
              return (
                <div style={{ marginTop: 15, position: 'relative', display: 'flex' }}>
                  <Form.Item name="notes">
                    <TextArea rows={3} disabled={!editing} style={{ width: 600 }} />
                  </Form.Item>
                  <DirtyFieldIndicator form={form} dirtyFields={['notes']} enabled={editing} />
                </div>
              );
            }}
          </FormItem>
          <FormItem
            label="Product Base SKU"
            required
            tooltip={TOOLTIPS.productBaseSKU}
            shouldUpdate={(prev, cur) =>
              !isEqual(pick(prev, ['baseSKU.sku', 'productSKUs']), pick(cur, ['baseSKU.sku', 'productSKUs']))
            }
          >
            {() => {
              return (
                <div style={{ marginTop: 15, position: 'relative', display: 'flex', paddingBottom: 15 }}>
                  <Form.Item name="baseSKU" rules={!editing ? [] : [{ required: true, message: 'Must be set.' }]}>
                    <AsyncSelect
                      name="baseSKU"
                      cacheOptions
                      value={form.getFieldValue('baseSKU')}
                      placeholder="Select a SKU..."
                      components={{ Option: SKUOption }}
                      noOptionsMessage={({ inputValue }) =>
                        !inputValue ? 'Begin typing to find a SKU...' : 'No results found'
                      }
                      isDisabled={!editing || (form.getFieldValue('productSKUs') || []).length}
                      loadOptions={onSKUSearch}
                      onChange={sku => {
                        form.setFieldValue('baseSKU', {
                          ...sku.value,
                          label: sku.label,
                        });
                      }}
                      styles={{
                        control: base => ({
                          ...base,
                          height: 40,
                          minHeight: 40,
                          minWidth: 475,
                        }),
                      }}
                    />
                  </Form.Item>
                  {editing && (
                    <Form.Item>
                      <Button
                        block
                        label={!(form.getFieldValue('productSKUs') || []).length ? 'Lock' : 'Un-lock'}
                        type="primary"
                        style={{ height: 40, margin: 0 }}
                        disabled={!form.getFieldValue('baseSKU')}
                        onClick={() =>
                          Modal.confirm({
                            title: `${
                              !(form.getFieldValue('productSKUs') || []).length ? 'Lock' : 'Un-lock'
                            } Product Base SKU`,
                            width: 600,
                            cancelText: 'No, go back',
                            onOk: () => {
                              if (!(form.getFieldValue('productSKUs') || []).length) {
                                form.setFieldValue('featureSKUs', []);
                                onResolveSKUTree(form.getFieldValue('baseSKU').sku).then(results => {
                                  if (results.length) {
                                    form.setFieldValue('productSKUs', [initConfigFromSKU(results[0])]);
                                  } else {
                                    message.error(
                                      'This Product Base SKU has no product upgrades and can not be used.',
                                      8
                                    );
                                    form.setFieldValue('baseSKU', '');
                                  }
                                });
                              } else {
                                form.setFieldValue('featureSKUs', []);
                                form.setFieldValue('productSKUs', []);
                                form.setFieldValue('baseSKU', '');
                                setSKUTree(undefined);
                              }
                            },
                            okText: !(form.getFieldValue('productSKUs') || []).length
                              ? 'Yes, use this SKU'
                              : 'Yes, un-lock and erase',
                            content: !(form.getFieldValue('productSKUs') || []).length ? (
                              <p>
                                After locking the Product Base SKU, you will not be able to change it again without
                                erasing any Feature or Product SKUs that are defined for this product.
                                <br />
                                <br />
                                Are you sure that you would like to lock in{' '}
                                <strong>"{form.getFieldValue('baseSKU').sku}"</strong> as the Product Base SKU?
                              </p>
                            ) : (
                              <p>
                                You should only un-lock the Product Base SKU if you would like to re-configure all
                                Feature / Addon and Product SKUs from scratch. This will remove any existing
                                configurations you have for this product.
                                <br />
                                <br />
                                Are you sure that you would like to un-lock the Product Base SKU{' '}
                                <strong>"{form.getFieldValue('baseSKU').sku}"</strong>?
                              </p>
                            ),
                          })
                        }
                        icon={<LockOutlined />}
                      />
                    </Form.Item>
                  )}

                  <DirtyFieldIndicator form={form} dirtyFields={['baseSKU']} enabled={editing} />
                </div>
              );
            }}
          </FormItem>
          <Divider plain style={{ backgroundColor: '#888', marginTop: 68 }} />
        </DataLayout>

        <Form.Item
          shouldUpdate={(prev, cur) =>
            (get(prev, 'productSKUs') || []).length !== (get(cur, 'productSKUs') || []).length
          }
        >
          {() => {
            if (!(form.getFieldValue('productSKUs') || []).length) {
              return (
                <Typography style={{ width: '100%', textAlign: 'center', marginTop: 52, fontSize: 18 }}>
                  Please <LockOutlined /> <strong>Lock</strong> a <strong>Product Base SKU </strong>above before
                  configuring the remainder of the product&hellip;
                </Typography>
              );
            }

            if (!skuTree) {
              return <Loader />;
            }
            return (
              <DataLayout title={`Product SKUs`} nested>
                <Form.List name="productSKUs">
                  {(fields, { add, remove }) => {
                    const treeData = getSKUTreeState(
                      skuTree,
                      form.getFieldValue('productSKUs'),
                      selectedProductSKUIndex,
                      form.getFieldValue('baseSKU')?.sku
                    );
                    return (
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <SKUTreeList
                          style={{ flex: 2 }}
                          itemLayout="vertical"
                          dataSource={treeData}
                          renderItem={(item: any) => {
                            let subLabel = 'Enabled';
                            let cardColour = '#ffaaaa';
                            if (!item.isEnabled) {
                              subLabel = `Not Enabled${editing ? ', Tick the box to Add' : ''}`;
                              cardColour = '#FBD9D3';
                            } else if (item.isSelected) {
                              subLabel = 'Selected';
                              cardColour = '#D1FFBD';
                            } else if (item.selectedSKU?.upgradeSKUs.includes(item.sku)) {
                              subLabel = 'Possible Upgrade';
                              cardColour = '#C6B7FE';
                            }
                            
                            const itemOverridePriceIndex = form
                                  .getFieldValue('productSKUs')
                                  .findIndex((ps: any) => ps.sku?.sku === item.sku);
                            const fieldId = ['productSKUs', itemOverridePriceIndex, 'overridePriceUSD'];
                            const overridePriceLabel = form.getFieldValue(fieldId);
                            
                            return (
                              <List.Item>
                                <Card
                                  onClick={() => {
                                    if (!item.isEnabled) {
                                      return null;
                                    } else {
                                      const whichIndex = form
                                        .getFieldValue('productSKUs')
                                        .findIndex((ps: any) => ps.sku?.sku === item.sku);
                                      setSelectedProductSKUIndex(whichIndex);
                                  }}}
                                  bordered
                                  size="small"
                                  style={{
                                    border: '1px solid #d9d9d9',
                                    borderRadius: 6,
                                    width: '100%',
                                    cursor: !editing && !item.isEnabled ? 'not-allowed' : 'pointer',
                                    backgroundColor: cardColour,
                                    boxShadow: item.isSelected ? 'rgba(0, 0, 0, 0.35) 0px 5px 7px' : undefined,
                                    borderColor: item.isSelected ? 'black' : '#AAA',
                                    borderWidth: item.isSelected ? 2 : undefined,
                                  }}
                                >
                                  <Checkbox id={item.sku} onChange={() => {
                                    if (!editing) {
                                      setDisabled(!disabled);
                                      return null;
                                    } else {
                                      setDisabled(disabled);
                                    // For an item that isn't enabled, we add it.
                                    if (!item.isEnabled) {
                                      subLabel = 'Enabled';
                                      cardColour = '#ffaaaa';
                                      setSelectedProductSKUIndex(form.getFieldValue('productSKUs').length);
                                      add(initConfigFromSKU(item.skuData));
                                    } else {
                                      Modal.confirm({
                                        title: 'Disable Product SKU',
                                        width: 600,
                                        icon: <CloseCircleOutlined />,
                                        content: (
                                          <p>
                                            Are you sure you want to disable this Product SKU ({item.sku})?
                                            <br />
                                            <br />
                                            It will no longer appear as upgrade option, as well as any other enabled
                                            dependant SKUs. It will also remove any labels, custom messages and price
                                            overrides for this SKU.
                                          </p>
                                        ),
                                        cancelText: 'Yes, disable',
                                        okText: 'No, go back',
                                        onCancel: () => {
                                          setSelectedProductSKUIndex(0);
                                          const removeIndex = form
                                            .getFieldValue('productSKUs')
                                            .findIndex((ps: any) => ps.sku?.sku === item.sku);
                                          remove(removeIndex);
                                        },
                                        onOk: () => {
                                          setChecked(!checked);
                                          subLabel = `Not Enabled${editing ? ', Click to Add' : ''}`;
                                          cardColour = '#FBD9D3';
                                        }
                                      });
                                      } 
                                    }
                                  }} checked={item.isEnabled === true}>{' '}</Checkbox>
                                  <Tooltip title={item.description}>{item.sku}</Tooltip>{' '}
                                  <InfoCircleOutlined
                                    onClick={() => {
                                      setSKUDetail(item.skuData);
                                    }}
                                  />
                                  {(parseFloat(overridePriceLabel) >=0) ? (
                                    <div
                                      style={{
                                        float: 'right',
                                        fontWeight: 'bold',
                                        flex: 1,
                                        color: item.isEnabled ? 'black' : '#888',
                                      }}
                                    >
                                    <Tooltip title="Price is Overridden"><WarningTwoTone twoToneColor="#bf873e"></WarningTwoTone></Tooltip>
                                    $USD {overridePriceLabel}
                                    </div>
                                  ) : <div
                                    style={{
                                      float: 'right',
                                      fontWeight: 'bold',
                                      flex: 1,
                                      color: item.isEnabled ? 'black' : '#888',
                                    }}
                                  >
                                    $USD {item.priceUSD}
                                  </div>
                                  }
                                  {subLabel ? (
                                    <div
                                      style={{
                                        textAlign: 'right',
                                        flex: 1,
                                        color: item.isEnabled ? 'black' : '#888',
                                      }}
                                    >
                                      {subLabel}
                                    </div>
                                  ) : null}
                                </Card>
                              </List.Item>
                            );
                          }}
                        />

                        <div style={{ flex: 7 }}>
                          {fields.map((field, index) => (
                            <Form.Item
                              key={field.key}
                              style={{ display: index !== selectedProductSKUIndex ? 'none' : 'block' }}
                            >
                              <FormItem
                                tooltip={TOOLTIPS.oemPaysOption}
                                required
                                label="OEM Pays (e.g. free trial)"
                                shouldUpdate={(prev, cur) =>
                                  !isEqual(
                                    pick(prev, [`productSKUs.${index}.oemPaysOption`]),
                                    pick(cur, [`productSKUs.${index}.oemPaysOption`])
                                  )
                                }
                              >
                                {() => {
                                  return (
                                    <div style={{ position: 'relative', marginTop: 15 }}>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, 'oemPaysOption']}
                                        rules={
                                          !editing ? [] : [{ required: true, message: 'Please select an option.' }]
                                        }
                                      >
                                        <Radio.Group
                                          onChange={event => {
                                            form.validateFields([['productSKUs', index, 'oemPoolUUID']]);
                                              if (event.target.value === 'false') {
                                                form.setFieldValue(['productSKUs', index, 'domainPoolOption'], 'false');
                                                form.setFieldValue(['productSKUs', index, 'oemPoolUUID'], null);
                                              }
                                          }}
                                        >
                                          <Radio value={'false'}>No (Customer Pays)</Radio>
                                          <Radio value={'true'}>Yes (Requires OEM Pool)</Radio>
                                        </Radio.Group>
                                      </Form.Item>

                                      <DirtyFieldIndicator
                                        form={form}
                                        dirtyFields={[['productSKUs', field.name, 'oemPaysOption']]}
                                        enabled={editing}
                                      />
                                    </div>
                                  );
                                }}
                              </FormItem>
                              <FormItem
                                label="OEM Pool UUID (OEM pays only)"
                                tooltip={TOOLTIPS.oemPoolUUID}
                                shouldUpdate={(prev, cur) =>
                                  !isEqual(
                                    pick(prev, [
                                      `productSKUs.${index}.oemPaysOption`,
                                      `productSKUs.${index}.oemPoolUUID`,
                                    ]),
                                    pick(cur, [
                                      `productSKUs.${index}.oemPaysOption`,
                                      `productSKUs.${index}.oemPoolUUID`,
                                    ])
                                  )
                                }
                              >
                                {() => {
                                  const { productSKUs } = form.getFieldsValue();
                                  const featureSet = productSKUs && productSKUs[index];
                                  const oemPaysEnabled =
                                    featureSet && featureSet.oemPaysOption && featureSet.oemPaysOption === 'true';
                                  const domainPoolEnabled =
                                    featureSet && featureSet.domainPoolOption && featureSet.domainPoolOption === 'true';
                                    const domainList = ['productSKUs', field.name, 'domainList'];
                                    const currentDomainListValue = form.getFieldValue(domainList);
                                    const domainPool = ['productSKUs', field.name, 'oemPoolUUID'];
                                    const currentdomainPoolValue = form.getFieldValue(domainPool);
                                    const domainPoolOption = ['productSKUs', field.name, 'domainPoolOption'];
                                    const currentdomainPoolOption = form.getFieldValue(domainPoolOption);

                                  return (
                                    <div style={{ position: 'relative' }}>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, 'oemPoolUUID']}
                                        rules={
                                          !editing || !oemPaysEnabled
                                            ? []
                                            : [
                                                ({ getFieldValue }) => ({
                                                  validator() {
                                                    if (
                                                      getFieldValue(['productSKUs', index, 'oemPaysOption']) === 'true'
                                                    ) {
                                                      if (
                                                        !isValidUUIDv4(
                                                          getFieldValue(['productSKUs', index, 'oemPoolUUID']) || ''
                                                        )
                                                      )
                                                        return Promise.reject(
                                                          'A valid pool UUID is required when using OEM Pays.'
                                                        );
                                                    }
                                                    return Promise.resolve();
                                                  },
                                                }),
                                              ]
                                        }
                                      >
                                        <Input
                                          addonBefore="UUIDv4"
                                          disabled={!oemPaysEnabled}
                                          onChange={async (e: React.FormEvent<HTMLInputElement>) => {
                                            form.setFieldValue(['productSKUs', index, 'domainPoolOption'], 'false');
                                            const targetValue = (e.currentTarget.value || '');
                                            
                                            const origPool = get(data.form, `productSKUs.${index}.oemPoolUUID`);
                                            const validationResult =  await form.validateFields([['productSKUs', index, 'oemPoolUUID']]);

                                            if (targetValue !== origPool && validationResult) {
                                              form.resetFields([['productSKUs', index, 'domainList']]);
                                              if(checkPoolUUID(e, origPool, index)) {
                                                form.setFieldValue(['productSKUs', index, 'oemPoolUUID'], targetValue);
                                                form.setFieldValue(['productSKUs', index, 'domainList'], currentDomainListValue);
                                                setHasTouchedPoolUUIDConfig(true);
                                              } else {
                                                form.setFieldValue(['productSKUs', index, 'oemPoolUUID'], targetValue);
                                                form.setFieldValue(['productSKUs', index, 'domainList'], currentDomainListValue);
                                                setHasTouchedPoolUUIDConfig(false);
                                              }
                                            }

                                          }}
                                          style={{
                                            width: '60%',
                                            maxWidth: 500,
                                            marginTop: 20,
                                            fontFamily: 'monospace',
                                          }}
                                        />
                                      </Form.Item>
                                      {currentdomainPoolValue && (
                                        <p style={{ lineHeight: 1 }}>
                                          <strong>Note:</strong> The end user will{' '}
                                          <strong>not</strong> be charged for purchases associated with this SKU while activations are still available in the ISV pool.
                                        </p>
                                      )}
                                      {currentdomainPoolValue && <Form.Item
                                        {...field}
                                        name={[field.name, 'domainPoolOption']}
                                        style={{ marginLeft: 12, marginBottom: 0 }}
                                        label="Enable domain locking: "
                                        rules={
                                          !editing ? [] : [{ required: true, message: 'Please select an option.' }]
                                        }
                                      >
                                      
                                        <Radio.Group
                                          defaultValue={[undefined, ''].includes(currentdomainPoolOption) ? 'false' : 'true'}
                                          onChange={async event => {
                                            if (currentdomainPoolOption !== event.target.value) {
                                              form.setFieldValue(['productSKUs', index, 'domainPoolOption'], event.target.value);
                                              form.validateFields([['productSKUs', index, 'domainPoolOption']]);
                                              setHasTouchedPoolUUIDConfig(event.target.value);
                                            } else {
                                              form.setFieldValue(['productSKUs', index, 'domainPoolOption'], currentdomainPoolOption);
                                              form.validateFields([['productSKUs', index, 'domainPoolOption']]);
                                              setHasTouchedPoolUUIDConfig(currentdomainPoolOption);
                                            }
                                          }}
                                        >
                                          <Radio value={'false'}>No</Radio>
                                          <Radio value={'true'}>Yes</Radio>
                                        </Radio.Group>
                                      </Form.Item>}

                                      {currentdomainPoolValue && !editing && (
                                        <div style={{ marginTop: 0, marginLeft: 15, marginBottom: 10 }}>
                                          <PoolDataLoader values={currentdomainPoolValue} isPoolIdDomainLocked={domainPoolEnabled} action="view" />
                                        </div>
                                        )
                                      }
                                      {domainPoolEnabled && currentdomainPoolValue && hasTouchedPoolUUIDConfig && (currentdomainPoolValue !== get(data.form, `productSKUs.${index}.oemPoolUUID`)) && editing && (
                                        <div>
                                          <PoolDataLoader values={currentdomainPoolValue} action="edit" form={form} index={index} fields={field}/>
                                        </div>
                                       ) 
                                      }
                                      {domainPoolEnabled && currentdomainPoolValue && (currentdomainPoolValue === get(data.form, `productSKUs.${index}.oemPoolUUID`)) && (!isNil(get(data.form, `productSKUs.${index}.oemPoolUUID`))) && editing && (
                                        <div>
                                          <PoolDataLoader values={currentdomainPoolValue} action="edit" form={form} index={index} fields={field}/>
                                        </div>
                                       ) 
                                      }
                                      
                                      <DirtyFieldIndicator
                                        form={form}
                                        topOffset={20}
                                        dirtyFields={[['productSKUs', field.name, 'oemPoolUUID']]}
                                        enabled={editing}
                                      />
                                      <DirtyFieldIndicator
                                        form={form}
                                        topOffset={120}
                                        dirtyFields={[['productSKUs', field.name, 'domainPoolOption']]}
                                        enabled={editing}
                                      />
                                    </div>
                                  );
                                }}
                              </FormItem>
                              <Form.Item
                                style={{ margin: 0 }}
                                hidden
                                name={[field.name, 'showLabelLocales']}
                                initialValue={'false'}
                              />
                              <Form.Item
                                style={{ margin: 0 }}
                                hidden
                                name={[field.name, 'showAILocales']}
                                initialValue={'false'}
                              />
                              <FormItem required label="Label" tooltip={TOOLTIPS.label}>
                                <Form.Item
                                  style={{ marginBottom: 0 }}
                                  shouldUpdate={(prev, cur) =>
                                    !isEqual(
                                      pick(prev, [
                                        ...generatePrefixedLocaleList(`productSKUs.${index}.label`),
                                        `productSKUs.${index}.showLabelLocales`,
                                      ]),
                                      pick(cur, [
                                        ...generatePrefixedLocaleList(`productSKUs.${index}.label`),
                                        `productSKUs.${index}.showLabelLocales`,
                                      ])
                                    )
                                  }
                                >
                                  {() => {
                                    const { productSKUs } = form.getFieldsValue();
                                    const isHidden =
                                      !get(productSKUs, `${index}.showLabelLocales`) ||
                                      get(productSKUs, `${index}.showLabelLocales`) === 'false';
                                    return (
                                      <div style={{ position: 'relative' }}>
                                        <Input.Group style={{ marginTop: 12 }}>
                                          <Form.Item
                                            name={[field.name, 'labelEN']}
                                            style={{ marginBottom: 0 }}
                                            rules={
                                              !editing
                                                ? []
                                                : [
                                                    { required: true, message: 'Please specify a value.' },
                                                    { min: 1, message: 'Please specify a value.' },
                                                    { max: 256, message: 'Must not exceed 256 characters.' },
                                                  ]
                                            }
                                          >
                                            <Input
                                              addonBefore={`English (EN${editing ? ', required)' : ')'}`}
                                              style={{ width: '80%', maxWidth: 800 }}
                                            />
                                          </Form.Item>
                                          <button
                                            style={{
                                              textDecoration: 'underline',
                                              display: 'block',
                                              marginLeft: 0,
                                              background: 'none',
                                              border: 0,
                                              marginTop: 5,
                                              cursor: 'pointer',
                                            }}
                                            onClick={e => {
                                              let { productSKUs } = form.getFieldsValue();
                                              const value =
                                                productSKUs && productSKUs[index].showLabelLocales === 'true';
                                              if (!value === true) {
                                                e.currentTarget.scrollIntoView({
                                                  block: 'center',
                                                  behavior: 'smooth',
                                                });
                                              }
                                              form.setFieldValue(
                                                ['productSKUs', index, 'showLabelLocales'],
                                                (!value).toString()
                                              );
                                            }}
                                          >{`[ ${isHidden ? 'Show' : 'Hide'} Other Locales ]`}</button>
                                        </Input.Group>
                                        <DirtyFieldIndicator
                                          form={form}
                                          dirtyFields={LOCALES.map((locale: string) => [
                                            'productSKUs',
                                            field.name,
                                            `label${locale}`,
                                          ])}
                                          enabled={editing}
                                        />
                                      </div>
                                    );
                                  }}
                                </Form.Item>
                                <Form.Item
                                  shouldUpdate={(prev, cur) =>
                                    !isEqual(
                                      pick(prev, [
                                        ...generatePrefixedLocaleList(`productSKUs.${index}.label`),
                                        `productSKUs.${index}.showLabelLocales`,
                                      ]),
                                      pick(cur, [
                                        ...generatePrefixedLocaleList(`productSKUs.${index}.label`),
                                        `productSKUs.${index}.showLabelLocales`,
                                      ])
                                    )
                                  }
                                >
                                  {() => {
                                    const { productSKUs } = form.getFieldsValue();
                                    const isHidden =
                                      !get(productSKUs, `${index}.showLabelLocales`) ||
                                      get(productSKUs, `${index}.showLabelLocales`) === 'false';
                                    return (
                                      <Form.Item hidden={isHidden}>
                                        <LanguageGroup>
                                          <Form.Item name={[field.name, 'labelDE']}>
                                            <Input addonBefore="German (DE)" />
                                          </Form.Item>
                                          <Form.Item name={[field.name, 'labelES']}>
                                            <Input addonBefore="Spanish (ES)" />
                                          </Form.Item>
                                          <Form.Item name={[field.name, 'labelFR']}>
                                            <Input addonBefore="French (FR)" />
                                          </Form.Item>
                                          <Form.Item name={[field.name, 'labelJA']}>
                                            <Input addonBefore="Japanese (JA)" />
                                          </Form.Item>
                                          <Form.Item name={[field.name, 'labelKO']}>
                                            <Input addonBefore="Korean (KO)" />
                                          </Form.Item>
                                          <Form.Item name={[field.name, 'labelPT']}>
                                            <Input addonBefore="Portugese (PT)" />
                                          </Form.Item>
                                          <Form.Item name={[field.name, 'labelZH']}>
                                            <Input addonBefore="Chinese (ZH)" />
                                          </Form.Item>
                                        </LanguageGroup>
                                      </Form.Item>
                                    );
                                  }}
                                </Form.Item>
                              </FormItem>
                              <FormItem
                                label="Price Override"
                                tooltip={TOOLTIPS.priceOverride}
                                shouldUpdate={(prev, cur) => true}
                              >
                                {() => {
                                  const fieldId = ['productSKUs', field.name, 'overridePriceUSD'];
                                  const currentValue = form.getFieldValue(fieldId);
                                  return (
                                    <div style={{ position: 'relative', marginTop: 15, display: 'flex' }}>
                                      <Form.Item style={{ display: 'inline-block' }}>
                                        <Radio.Group
                                          defaultValue={[undefined, ''].includes(currentValue) ? 'false' : 'true'}
                                          onChange={event => {
                                            if (event.target.value === 'false') {
                                              form.setFieldValue(fieldId, undefined);
                                            } else {
                                              form.setFieldValue(fieldId, 0);
                                            }
                                          }}
                                        >
                                          <Radio value={'false'}>SKU Default</Radio>
                                          <Radio value={'true'}>Use:</Radio>
                                        </Radio.Group>
                                      </Form.Item>
                                      <Form.Item
                                        name={[field.name, 'overridePriceUSD']}
                                        style={{ display: 'inline-block', marginTop: -20 }}
                                        rules={
                                          !editing || [undefined, ''].includes(currentValue)
                                            ? []
                                            : [
                                                () => ({
                                                  validator(_, value) {
                                                    const asFloat = parseFloat(value);
                                                    var split = (asFloat + '').split('.');
                                                    if (split.length > 1) {
                                                      if (split[1].length > 2) {
                                                        return Promise.reject(
                                                          'Price cannot exceed two decimal places.'
                                                        );
                                                      }
                                                    }
                                                    if (asFloat <= 0) {
                                                      return Promise.reject('Can not be less than or equal to 0');
                                                    } else if (asFloat > 9999) {
                                                      return Promise.reject('Cannot exceed 9999.');
                                                    }
                                                    return Promise.resolve();
                                                  },
                                                }),
                                              ]
                                        }
                                      >
                                        <InputNumber
                                          addonBefore="$USD"
                                          stringMode
                                          disabled={currentValue === undefined}
                                          style={{ marginTop: 20, fontFamily: 'monospace', width: '70%' }}
                                        />
                                      </Form.Item>
                                    </div>
                                  );
                                }}
                              </FormItem>
                              <FormItem
                                required
                                label="Additional Information Type"
                                tooltip={TOOLTIPS.additionalInformationType}
                                shouldUpdate={(prev, cur) =>
                                  !isEqual(
                                    pick(prev, [`productSKUs.${index}.additionalInformationType`]),
                                    pick(cur, [`productSKUs.${index}.additionalInformationType`])
                                  )
                                }
                              >
                                {() => {
                                  return (
                                    <div style={{ position: 'relative', marginTop: 15 }}>
                                      <Form.Item
                                        name={[field.name, 'additionalInformationType']}
                                        initialValue="NONE"
                                        rules={
                                          !editing ? [] : [{ required: true, message: 'Please select an option.' }]
                                        }
                                      >
                                        <Radio.Group>
                                          <Radio value="NONE">None</Radio>
                                          <Radio value="INFO">
                                            Info ( <InfoCircleTwoTone /> )
                                          </Radio>
                                          <Radio value="WARNING">
                                            Warning ( <WarningTwoTone twoToneColor="#bf873e" /> )
                                          </Radio>
                                        </Radio.Group>
                                      </Form.Item>
                                      <DirtyFieldIndicator
                                        form={form}
                                        dirtyFields={[['productSKUs', field.name, 'additionalInformationType']]}
                                        enabled={editing}
                                      />
                                    </div>
                                  );
                                }}
                              </FormItem>
                              <FormItem
                                label="Additional Information Label"
                                tooltip={TOOLTIPS.additionalInformationLabel}
                              >
                                <Form.Item
                                  style={{ marginBottom: 0 }}
                                  shouldUpdate={(prev, cur) =>
                                    !isEqual(
                                      pick(prev, [
                                        ...generatePrefixedLocaleList(`productSKUs.${index}.aiLabel`),
                                        `productSKUs.${index}.showAILocales`,
                                        `productSKUs.${index}.additionalInformationType`,
                                      ]),
                                      pick(cur, [
                                        ...generatePrefixedLocaleList(`productSKUs.${index}.aiLabel`),
                                        `productSKUs.${index}.showAILocales`,
                                        `productSKUs.${index}.additionalInformationType`,
                                      ])
                                    )
                                  }
                                >
                                  {() => {
                                    const { productSKUs } = form.getFieldsValue();
                                    const isHidden =
                                      !get(productSKUs, `${index}.showAILocales`) ||
                                      get(productSKUs, `${index}.showAILocales`) === 'false';
                                    return (
                                      <div style={{ position: 'relative' }}>
                                        <Input.Group style={{ marginTop: 12 }}>
                                          <Form.Item
                                            name={[field.name, 'aiLabelEN']}
                                            style={{ marginBottom: 0 }}
                                            rules={
                                              !editing
                                                ? []
                                                : [
                                                    ({ getFieldValue }) => ({
                                                      validator() {
                                                        if (
                                                          getFieldValue([
                                                            'productSKUs',
                                                            index,
                                                            'additionalInformationType',
                                                          ]) !== 'NONE' &&
                                                          (!getFieldValue(['productSKUs', index, 'aiLabelEN']) ||
                                                            !getFieldValue(['productSKUs', index, 'aiLabelEN']).length)
                                                        ) {
                                                          return Promise.reject(
                                                            'Specify an additional information label if a type is selected.'
                                                          );
                                                        }
                                                        return Promise.resolve();
                                                      },
                                                    }),
                                                    { min: 1, message: 'Please specify a value.' },
                                                  ]
                                            }
                                          >
                                            <Input
                                              addonBefore={`English (EN${editing ? ', required)' : ')'}`}
                                              style={{ width: '80%', maxWidth: 800 }}
                                            />
                                          </Form.Item>

                                          <button
                                            style={{
                                              textDecoration: 'underline',
                                              display: 'block',
                                              marginLeft: 0,
                                              background: 'none',
                                              border: 0,
                                              marginTop: 5,
                                              cursor: 'pointer',
                                            }}
                                            onClick={e => {
                                              let { productSKUs } = form.getFieldsValue();
                                              const value = productSKUs && productSKUs[index].showAILocales === 'true';
                                              if (!value === true) {
                                                e.currentTarget.scrollIntoView({
                                                  block: 'center',
                                                  behavior: 'smooth',
                                                });
                                              }
                                              form.setFieldValue(
                                                ['productSKUs', index, 'showAILocales'],
                                                (!value).toString()
                                              );
                                            }}
                                          >{`[ ${isHidden ? 'Show' : 'Hide'} Other Locales ]`}</button>
                                        </Input.Group>
                                        <DirtyFieldIndicator
                                          form={form}
                                          dirtyFields={LOCALES.map((locale: string) => [
                                            'productSKUs',
                                            field.name,
                                            `aiLabel${locale}`,
                                          ])}
                                          enabled={editing}
                                        />
                                      </div>
                                    );
                                  }}
                                </Form.Item>
                                <Form.Item
                                  shouldUpdate={(prev, cur) =>
                                    !isEqual(
                                      pick(prev, [
                                        ...generatePrefixedLocaleList(`productSKUs.${index}.aiLabel`),
                                        `productSKUs.${index}.showAILocales`,
                                      ]),
                                      pick(cur, [
                                        ...generatePrefixedLocaleList(`productSKUs.${index}.aiLabel`),
                                        `productSKUs.${index}.showAILocales`,
                                      ])
                                    )
                                  }
                                >
                                  {() => {
                                    const { productSKUs } = form.getFieldsValue();
                                    const isHidden =
                                      !get(productSKUs, `${index}.showAILocales`) ||
                                      get(productSKUs, `${index}.showAILocales`) === 'false';
                                    return (
                                      <Form.Item hidden={isHidden}>
                                        <LanguageGroup>
                                          <Form.Item name={[field.name, 'aiLabelDE']}>
                                            <Input addonBefore="German (DE)" />
                                          </Form.Item>
                                          <Form.Item name={[field.name, 'aiLabelES']}>
                                            <Input addonBefore="Spanish (ES)" />
                                          </Form.Item>
                                          <Form.Item name={[field.name, 'aiLabelFR']}>
                                            <Input addonBefore="French (FR)" />
                                          </Form.Item>
                                          <Form.Item name={[field.name, 'aiLabelJA']}>
                                            <Input addonBefore="Japanese (JA)" />
                                          </Form.Item>
                                          <Form.Item name={[field.name, 'aiLabelKO']}>
                                            <Input addonBefore="Korean (KO)" />
                                          </Form.Item>
                                          <Form.Item name={[field.name, 'aiLabelPT']}>
                                            <Input addonBefore="Portugese (PT)" />
                                          </Form.Item>
                                          <Form.Item name={[field.name, 'aiLabelZH']}>
                                            <Input addonBefore="Chinese (ZH)" />
                                          </Form.Item>
                                        </LanguageGroup>
                                      </Form.Item>
                                    );
                                  }}
                                </Form.Item>
                              </FormItem>
                            </Form.Item>
                          ))}
                        </div>
                      </div>
                    );
                  }}
                </Form.List>
                <Divider plain style={{ backgroundColor: '#888', marginTop: 38 }} />
              </DataLayout>
            );
          }}
        </Form.Item>

        <Form.Item
          shouldUpdate={(prev, cur) =>
            (get(prev, 'productSKUs') || []).length !== (get(cur, 'productSKUs') || []).length ||
            (get(prev, 'featureSKUs') || []).length !== (get(cur, 'featureSKUs') || []).length
          }
        >
          {() => {
            if (!(form.getFieldValue('productSKUs') || []).length) {
              return null;
            }

            return (
              <DataLayout title="Feature / Addon SKUs" nested>
                <Form.List name="featureSKUs">
                  {(fields, { add, remove }) => {
                    const { baseSKU } = form.getFieldsValue();
                    return (
                      <>
                        {fields.map((field, index) => (
                          <Form.Item key={field.key}>
                            <Form.Item
                              noStyle
                              shouldUpdate={(prev, cur) =>
                                !isEqual(
                                  pick(prev, [`featureSKUs.${index}.labelEN`]),
                                  pick(cur, [`featureSKUs.${index}.labelEN`])
                                )
                              }
                            >
                              {() => {
                                return (
                                  <ProductConfigurationHeader>
                                    <label>
                                      {form.getFieldValue(['featureSKUs', index, 'sku', 'sku'])}
                                      {` ( ${form.getFieldValue(['featureSKUs', index, 'labelEN']) || ' No Label'} )`}
                                    </label>
                                    {editing && (
                                      <div>
                                        <Tooltip title="Delete Feature / Addon SKU">
                                          <Button
                                            type="ghost"
                                            style={{ border: 'none', color: 'white' }}
                                            onClick={() => {
                                              Modal.confirm({
                                                title: 'Delete Feature / Addon SKU',
                                                width: 600,
                                                icon: <DeleteOutlined />,
                                                content: `Are you sure you want to delete ${form.getFieldValue([
                                                  'featureSKUs',
                                                  index,
                                                  'sku',
                                                  'sku',
                                                ])} from this product?`,
                                                cancelText: 'Yes, delete',
                                                okText: 'No, go back',
                                                onCancel: () => {
                                                  remove(field.name);
                                                },
                                              });
                                            }}
                                            icon={<DeleteOutlined />}
                                          />
                                        </Tooltip>
                                      </div>
                                    )}
                                  </ProductConfigurationHeader>
                                );
                              }}
                            </Form.Item>

                            <Form.Item
                              style={{ margin: 0 }}
                              hidden
                              name={[field.name, 'showLabelLocales']}
                              initialValue={'false'}
                            />
                            <Form.Item
                              style={{ margin: 0 }}
                              hidden
                              name={[field.name, 'showAILocales']}
                              initialValue={'false'}
                            />
                            <FormItem required label="Label" tooltip={TOOLTIPS.label}>
                              <Form.Item
                                style={{ marginBottom: 0 }}
                                shouldUpdate={(prev, cur) =>
                                  !isEqual(
                                    pick(prev, [
                                      ...generatePrefixedLocaleList(`featureSKUs.${index}.label`),
                                      `featureSKUs.${index}.showLabelLocales`,
                                    ]),
                                    pick(cur, [
                                      ...generatePrefixedLocaleList(`featureSKUs.${index}.label`),
                                      `featureSKUs.${index}.showLabelLocales`,
                                    ])
                                  )
                                }
                              >
                                {() => {
                                  const { featureSKUs } = form.getFieldsValue();
                                  const isHidden =
                                    !get(featureSKUs, `${index}.showLabelLocales`) ||
                                    get(featureSKUs, `${index}.showLabelLocales`) === 'false';
                                  return (
                                    <div style={{ position: 'relative' }}>
                                      <Input.Group style={{ marginTop: 12 }}>
                                        <Form.Item
                                          name={[field.name, 'labelEN']}
                                          style={{ marginBottom: 0 }}
                                          rules={
                                            !editing
                                              ? []
                                              : [
                                                  { required: true, message: 'Please specify a value.' },
                                                  { min: 1, message: 'Please specify a value.' },
                                                ]
                                          }
                                        >
                                          <Input
                                            addonBefore={`English (EN${editing ? ', required)' : ')'}`}
                                            style={{ width: '80%', maxWidth: 800 }}
                                          />
                                        </Form.Item>
                                        <button
                                          style={{
                                            textDecoration: 'underline',
                                            display: 'block',
                                            marginLeft: 0,
                                            background: 'none',
                                            border: 0,
                                            marginTop: 5,
                                            cursor: 'pointer',
                                          }}
                                          onClick={e => {
                                            let { featureSKUs } = form.getFieldsValue();
                                            const value = featureSKUs && featureSKUs[index].showLabelLocales === 'true';
                                            if (!value === true) {
                                              e.currentTarget.scrollIntoView({
                                                block: 'center',
                                                behavior: 'smooth',
                                              });
                                            }
                                            form.setFieldValue(
                                              ['featureSKUs', index, 'showLabelLocales'],
                                              (!value).toString()
                                            );
                                          }}
                                        >{`[ ${isHidden ? 'Show' : 'Hide'} Other Locales ]`}</button>
                                      </Input.Group>
                                      <DirtyFieldIndicator
                                        form={form}
                                        dirtyFields={LOCALES.map((locale: string) => [
                                          'featureSKUs',
                                          field.name,
                                          `label${locale}`,
                                        ])}
                                        enabled={editing}
                                      />
                                    </div>
                                  );
                                }}
                              </Form.Item>
                              <Form.Item
                                shouldUpdate={(prev, cur) =>
                                  !isEqual(
                                    pick(prev, [
                                      ...generatePrefixedLocaleList(`featureSKUs.${index}.label`),
                                      `featureSKUs.${index}.showLabelLocales`,
                                    ]),
                                    pick(cur, [
                                      ...generatePrefixedLocaleList(`featureSKUs.${index}.label`),
                                      `featureSKUs.${index}.showLabelLocales`,
                                    ])
                                  )
                                }
                              >
                                {() => {
                                  const { featureSKUs } = form.getFieldsValue();
                                  const isHidden =
                                    !get(featureSKUs, `${index}.showLabelLocales`) ||
                                    get(featureSKUs, `${index}.showLabelLocales`) === 'false';
                                  return (
                                    <Form.Item hidden={isHidden}>
                                      <LanguageGroup>
                                        <Form.Item name={[field.name, 'labelDE']}>
                                          <Input addonBefore="German (DE)" />
                                        </Form.Item>
                                        <Form.Item name={[field.name, 'labelES']}>
                                          <Input addonBefore="Spanish (ES)" />
                                        </Form.Item>
                                        <Form.Item name={[field.name, 'labelFR']}>
                                          <Input addonBefore="French (FR)" />
                                        </Form.Item>
                                        <Form.Item name={[field.name, 'labelJA']}>
                                          <Input addonBefore="Japanese (JA)" />
                                        </Form.Item>
                                        <Form.Item name={[field.name, 'labelKO']}>
                                          <Input addonBefore="Korean (KO)" />
                                        </Form.Item>
                                        <Form.Item name={[field.name, 'labelPT']}>
                                          <Input addonBefore="Portugese (PT)" />
                                        </Form.Item>
                                        <Form.Item name={[field.name, 'labelZH']}>
                                          <Input addonBefore="Chinese (ZH)" />
                                        </Form.Item>
                                      </LanguageGroup>
                                    </Form.Item>
                                  );
                                }}
                              </Form.Item>
                            </FormItem>
                            <FormItem
                              label="Price Override"
                              tooltip={TOOLTIPS.priceOverride}
                              shouldUpdate={(prev, cur) => true}
                            >
                              {() => {
                                const fieldId = ['featureSKUs', field.name, 'overridePriceUSD'];
                                const currentValue = form.getFieldValue(fieldId);
                                return (
                                  <div style={{ position: 'relative', marginTop: 15, display: 'flex' }}>
                                    <Form.Item style={{ display: 'inline-block' }}>
                                      <Radio.Group
                                        defaultValue={[undefined, ''].includes(currentValue) ? 'false' : 'true'}
                                        onChange={event => {
                                          if (event.target.value === 'false') {
                                            form.setFieldValue(fieldId, undefined);
                                          } else {
                                            form.setFieldValue(fieldId, 0);
                                          }
                                        }}
                                      >
                                        <Radio value={'false'}>SKU Default</Radio>
                                        <Radio value={'true'}>Use:</Radio>
                                      </Radio.Group>
                                    </Form.Item>
                                    <Form.Item
                                      name={[field.name, 'overridePriceUSD']}
                                      style={{ display: 'inline-block', marginTop: -20 }}
                                      rules={
                                        !editing || [undefined, ''].includes(currentValue)
                                          ? []
                                          : [
                                              () => ({
                                                validator(_, value) {
                                                  const asFloat = parseFloat(value);
                                                  var split = (asFloat + '').split('.');
                                                  if (split.length > 1) {
                                                    if (split[1].length > 2) {
                                                      return Promise.reject('Price cannot exceed two decimal places.');
                                                    }
                                                  }
                                                  if (asFloat < 0) {
                                                    return Promise.reject('Can not be less than 0');
                                                  } else if (asFloat > 9999) {
                                                    return Promise.reject('Cannot exceed 9999.');
                                                  }
                                                  return Promise.resolve();
                                                },
                                              }),
                                            ]
                                      }
                                    >
                                      <InputNumber
                                        addonBefore="$USD"
                                        min={0}
                                        max={9999}
                                        stringMode
                                        disabled={currentValue === undefined}
                                        style={{ marginTop: 20, fontFamily: 'monospace', width: '70%' }}
                                      />
                                    </Form.Item>
                                  </div>
                                );
                              }}
                            </FormItem>
                            <FormItem
                              required
                              label="Additional Information Type"
                              tooltip={TOOLTIPS.additionalInformationType}
                              shouldUpdate={(prev, cur) =>
                                !isEqual(
                                  pick(prev, [`featureSKUs.${index}.additionalInformationType`]),
                                  pick(cur, [`featureSKUs.${index}.additionalInformationType`])
                                )
                              }
                            >
                              {() => {
                                return (
                                  <div style={{ position: 'relative', marginTop: 15 }}>
                                    <Form.Item
                                      name={[field.name, 'additionalInformationType']}
                                      initialValue="NONE"
                                      rules={!editing ? [] : [{ required: true, message: 'Please select an option.' }]}
                                    >
                                      <Radio.Group>
                                        <Radio value="NONE">None</Radio>
                                        <Radio value="INFO">
                                          Info ( <InfoCircleTwoTone /> )
                                        </Radio>
                                        <Radio value="WARNING">
                                          Warning ( <WarningTwoTone twoToneColor="#bf873e" /> )
                                        </Radio>
                                      </Radio.Group>
                                    </Form.Item>
                                    <DirtyFieldIndicator
                                      form={form}
                                      dirtyFields={[['featureSKUs', field.name, 'additionalInformationType']]}
                                      enabled={editing}
                                    />
                                  </div>
                                );
                              }}
                            </FormItem>
                            <FormItem
                              label="Additional Information Label"
                              tooltip={TOOLTIPS.additionalInformationLabel}
                            >
                              <Form.Item
                                style={{ marginBottom: 0 }}
                                shouldUpdate={(prev, cur) =>
                                  !isEqual(
                                    pick(prev, [
                                      ...generatePrefixedLocaleList(`featureSKUs.${index}.aiLabel`),
                                      `featureSKUs.${index}.showAILocales`,
                                      `featureSKUs.${index}.additionalInformationType`,
                                    ]),
                                    pick(cur, [
                                      ...generatePrefixedLocaleList(`featureSKUs.${index}.aiLabel`),
                                      `featureSKUs.${index}.showAILocales`,
                                      `featureSKUs.${index}.additionalInformationType`,
                                    ])
                                  )
                                }
                              >
                                {() => {
                                  const { featureSKUs } = form.getFieldsValue();
                                  const isHidden =
                                    !get(featureSKUs, `${index}.showAILocales`) ||
                                    get(featureSKUs, `${index}.showAILocales`) === 'false';
                                  return (
                                    <div style={{ position: 'relative' }}>
                                      <Input.Group style={{ marginTop: 12 }}>
                                        <Form.Item
                                          name={[field.name, 'aiLabelEN']}
                                          style={{ marginBottom: 0 }}
                                          rules={
                                            !editing
                                              ? []
                                              : [
                                                  ({ getFieldValue }) => ({
                                                    validator() {
                                                      if (
                                                        getFieldValue([
                                                          'featureSKUs',
                                                          index,
                                                          'additionalInformationType',
                                                        ]) !== 'NONE' &&
                                                        (!getFieldValue(['featureSKUs', index, 'aiLabelEN']) ||
                                                          !getFieldValue(['featureSKUs', index, 'aiLabelEN']).length)
                                                      ) {
                                                        return Promise.reject(
                                                          'Specify an additional information label if a type is selected.'
                                                        );
                                                      }
                                                      return Promise.resolve();
                                                    },
                                                  }),
                                                  { min: 1, message: 'Please specify a value.' },
                                                ]
                                          }
                                        >
                                          <Input
                                            addonBefore={`English (EN${editing ? ', required)' : ')'}`}
                                            style={{ width: '80%', maxWidth: 800 }}
                                          />
                                        </Form.Item>

                                        <button
                                          style={{
                                            textDecoration: 'underline',
                                            display: 'block',
                                            marginLeft: 0,
                                            background: 'none',
                                            border: 0,
                                            marginTop: 5,
                                            cursor: 'pointer',
                                          }}
                                          onClick={e => {
                                            let { featureSKUs } = form.getFieldsValue();
                                            const value = featureSKUs && featureSKUs[index].showAILocales === 'true';
                                            if (!value === true) {
                                              e.currentTarget.scrollIntoView({
                                                block: 'center',
                                                behavior: 'smooth',
                                              });
                                            }
                                            form.setFieldValue(
                                              ['featureSKUs', index, 'showAILocales'],
                                              (!value).toString()
                                            );
                                          }}
                                        >{`[ ${isHidden ? 'Show' : 'Hide'} Other Locales ]`}</button>
                                      </Input.Group>
                                      <DirtyFieldIndicator
                                        form={form}
                                        dirtyFields={LOCALES.map((locale: string) => [
                                          'featureSKUs',
                                          field.name,
                                          `aiLabel${locale}`,
                                        ])}
                                        enabled={editing}
                                      />
                                    </div>
                                  );
                                }}
                              </Form.Item>
                              <Form.Item
                                shouldUpdate={(prev, cur) =>
                                  !isEqual(
                                    pick(prev, [
                                      ...generatePrefixedLocaleList(`featureSKUs.${index}.aiLabel`),
                                      `featureSKUs.${index}.showAILocales`,
                                    ]),
                                    pick(cur, [
                                      ...generatePrefixedLocaleList(`featureSKUs.${index}.aiLabel`),
                                      `featureSKUs.${index}.showAILocales`,
                                    ])
                                  )
                                }
                              >
                                {() => {
                                  const { featureSKUs } = form.getFieldsValue();
                                  const isHidden =
                                    !get(featureSKUs, `${index}.showAILocales`) ||
                                    get(featureSKUs, `${index}.showAILocales`) === 'false';
                                  return (
                                    <Form.Item hidden={isHidden}>
                                      <LanguageGroup>
                                        <Form.Item name={[field.name, 'aiLabelDE']}>
                                          <Input addonBefore="German (DE)" />
                                        </Form.Item>
                                        <Form.Item name={[field.name, 'aiLabelES']}>
                                          <Input addonBefore="Spanish (ES)" />
                                        </Form.Item>
                                        <Form.Item name={[field.name, 'aiLabelFR']}>
                                          <Input addonBefore="French (FR)" />
                                        </Form.Item>
                                        <Form.Item name={[field.name, 'aiLabelJA']}>
                                          <Input addonBefore="Japanese (JA)" />
                                        </Form.Item>
                                        <Form.Item name={[field.name, 'aiLabelKO']}>
                                          <Input addonBefore="Korean (KO)" />
                                        </Form.Item>
                                        <Form.Item name={[field.name, 'aiLabelPT']}>
                                          <Input addonBefore="Portugese (PT)" />
                                        </Form.Item>
                                        <Form.Item name={[field.name, 'aiLabelZH']}>
                                          <Input addonBefore="Chinese (ZH)" />
                                        </Form.Item>
                                      </LanguageGroup>
                                    </Form.Item>
                                  );
                                }}
                              </Form.Item>
                            </FormItem>
                          </Form.Item>
                        ))}
                        {editing && (
                          <Form.Item>
                            <AsyncSelect
                              cacheOptions
                              value={null}
                              placeholder={
                                <span>
                                  <PlusCircleOutlined /> Add{' '}
                                  {form.getFieldValue('featureSKUs').length ? 'another' : 'a'} Feature / Addon SKU...
                                </span>
                              }
                              components={{ Option: SKUOption }}
                              noOptionsMessage={({ inputValue }) =>
                                !inputValue ? 'Begin typing to find a SKU...' : 'No results found'
                              }
                              isDisabled={!editing}
                              loadOptions={(search: string) => onFeatureSKUSearch(baseSKU.productFamily, search)}
                              onChange={(sku: any) => {
                                add(initConfigFromSKU(sku.value));
                                setTimeout(() => {
                                  window.scrollTo({ top: 0, behavior: 'smooth' });
                                }, 500);
                              }}
                              styles={{
                                container: base => ({
                                  ...base,
                                  width: 350,
                                }),
                                control: base => ({
                                  ...base,
                                  height: 40,
                                  minHeight: 40,
                                }),
                              }}
                            />
                          </Form.Item>
                        )}
                      </>
                    );
                  }}
                </Form.List>
              </DataLayout>
            );
          }}
        </Form.Item>
      </Form>
    );
  }

  // Layout & Buttons
  return (
    <DataLayout
      showRefresh={!isNew && !editing}
      refreshAction={loadProduct}
      backPath={PATHS.PRODUCTS}
      backLabel="Back to Products - Summary"
      duplicateActionAtBottom={!loading}
      action={
        <ButtonGroup>
          <>
            {!isNew &&
              canEdit &&
              (editing ? (
                <Button
                  label="Cancel Changes"
                  icon={<MinusCircleOutlined />}
                  onClick={() => {
                    Modal.confirm({
                      title: 'Cancel Changes',
                      width: 600,
                      icon: <MinusCircleOutlined />,
                      content: 'Are you sure you want to discard your changes?',
                      cancelText: 'Yes, discard',
                      okText: 'No, go back',
                      onCancel: () => {
                        form.resetFields();
                        setSelectedProductSKUIndex(0);
                        setTimeout(() => {
                          if (form.getFieldValue('baseSKU')) {
                            onResolveSKUTree(form.getFieldValue('baseSKU').sku);
                          }
                        });
                        setEditing(false);
                        setLoading(false);
                      },
                    });
                  }}
                />
              ) : (
                <Button
                  label="New Product"
                  disabled={loading || submitting}
                  icon={<PlusCircleOutlined />}
                  onClick={() => {
                    window.location.href = '/products/new';
                  }}
                />
              ))}
            {canEdit && (
              <Button
                disabled={loading}
                type="primary"
                loading={submitting}
                label={submitButtonLabel}
                icon={submitButtonIcon}
                onClick={submitButtonAction}
              />
            )}
          </>
        </ButtonGroup>
      }
      title={isNew ? `New Product` : `Product details`}
    >
      {content}
      {skuDetail && <SKUDetailModal detail={skuDetail} onClose={() => setSKUDetail(null)} />}
    </DataLayout>
  );
};

export default Product;
