import { gql } from '@apollo/client';

const RESULTS_LIMIT = 10000;

const SKUFields = `
  sku
  type
  enabled
  factoryOnly
  description
  upgradeSKUs
  features {
    id
    value
  }
  priceUSD
  productFamily
  created {
    at
    by
  }
`;

const ProductConfigFields = `
  _id
  version
  reference
  notes
  active
  activationClients
  entitlementCreationClients
  manufacturerId
  modelId
  version
  owner {
    email
    uuid
  }
  support {
    url
  }
  image {
    url
  }
  baseSKU {
    ${SKUFields}
  }
  featureSKUs {
    sku
    label {
      locale
      string
    }
    overridePriceUSD
    message {
      type
      content {
        locale
        string
      }
    }
  }
  productSKUs {
    sku
    label {
      locale
      string
    }
    overridePriceUSD
    associatedPoolId
    isPoolIdDomainLocked
    message {
      type
      content {
        locale
        string
      }
    }
  }
`;

const SKUs = gql`
query getSKUs($skuIds: [String!]) {
  skus(query: { sku_in: $skuIds }) {
    ${SKUFields}
  }
}
`;

const PoolFields = `
  name
  creditLimit
  uuid
  productSKU {
    ${SKUFields}
  }
  featureSKUs {
    ${SKUFields}
  }
  owner {
    email
  }
  created {
    at
    by
  }
  restrictions {
    productFamily
    oemUUID
    emailDomain
  }
  balance
`;

const InboundFields = `
  source {
    name
    reference
    externalId
  }
  quantity
  description
  created {
    at
    by
  }
  pool {
    uuid
  }
`;

const OutboundFields = `
  created {
    at
    by
  }
  destination {
    device {
      manufacturer {
        id
        name
      }
      model {
        id
        name
      }
    }
    entitlement
    oemUUID
    productCode
  }
  metadata {
    paidUSD
    refEmailAddress
  }
  superseded {
    pool {
      uuid
    }
    at
    by
  }
  pool {
    uuid
  }
`;

const PaginatedOutboundFields = `
  pageInfo {
    currentPage
    nextPage
    totalCount
    totalPages
  }
  result {
    created {
        at
        by
    }
    destination {
        entitlement
        oemUUID
        productCode
    }
    metadata {
        paidUSD
        refEmailAddress
    }
    pool
  }
`;

const PoolProductFields = `
  family
  productCodes
`;

const SLSLicenseDataFields = `
id
licenseId
clientIpAddress
timestamp
endpoint {
  model {
    id
    name
  }
  manufacturer {
    id
    name
  }
  product {
    code
    name
  }
  macAddress
  socUid
  hardwareUuid
}`;

const SLSOEMFields = `
  _id
  name
  uuid
  valid
`;

const ProductConfigsQuery = `
  query {
    configurations(query: { }, limit: ${RESULTS_LIMIT}) {
      ${ProductConfigFields}
    }
  }
`;

const ProductConfigQuery = `
  query getConfiguration($id: ObjectId!) {
    configuration(query: { _id: $id }) {
      ${ProductConfigFields}
    }
  }
`;

const PoolProductsQuery = `
query getProductMaps($families: [String!]) {
  productMaps(query: { family_in: $families }) {
    ${PoolProductFields}
  }
}
`;

const EntitlementActivationJSONQuery = `
query entitlementActivationJSON($entitlementId: String!) {
  getEntitlementActivationJSON(input: $entitlementId)
}
`;

const SLSOEMsQuery = `
query getSLSOEMs($oemUUIDs: [String!]) {
  getSLSOEMs(input: $oemUUIDs) {
    ${SLSOEMFields}
  }
}
`;

const SLSLicenseDataForEntitlementIdsQuery = `
query getSLSActivationDataForEntitlementIds($ids: [String!]) {
  getSLSActivationDataForEntitlementIds(input: $ids) {
    ${SLSLicenseDataFields}
  }
}
`;

const ProductConfigQueryByManufacturerModelId = `
  query getConfiguration($manufacturerId: String!, $modelId: String!) {
    configuration(query: { manufacturerId: $manufacturerId, modelId: $modelId }) {
      _id
    }
  }
`;

const ProductConfigQueryVersionByManufacturerModelId = `
  query getConfiguration($manufacturerId: String!, $modelId: String!, $version: String) {
    configuration(query: { manufacturerId: $manufacturerId, modelId: $modelId, version: $version }) {
      _id
    }
  }
`;

const PoolQuery = `
  query getPool($id: String!) {
    pool(query: { uuid: $id }) {
      ${PoolFields}
    }
  }
`;

const PoolDataLoaderQuery = `
  query getPool($id: String!) {
    pool(query: { uuid: $id }) {
      uuid
      restrictions {
        productFamily
        oemUUID
        emailDomain
      }
      balance
    }
  }
`;

const PoolRestrictionUpdate = `
mutation UpdatePoolRestriction($emailDomain: [String!], $poolUUID: String!) {
    updatePoolRestriction(input: { emailDomain: $emailDomain, poolUUID: $poolUUID }) {
        status
    }
}
`;

const PoolsWithFilter = (args: string, filter: string) => gql`
query getPools(${args}) {
  pools(query: {${filter}}, limit: ${RESULTS_LIMIT}) {
    ${PoolFields}
  }
}
`;

const InboundAllocationsWithFilter = (args: string, filter: string) => gql`
query getInboundAllocations(${args}) {
  inboundAllocations(query: {${filter}}, limit: ${RESULTS_LIMIT}) {
    ${InboundFields}
  }
}
`;

const OutboundAllocationsWithFilter = (args: string, filter: string) => gql`
query getOutboundAllocations(${args}) {
  outboundAllocations(query: {${filter}}, limit: ${RESULTS_LIMIT}) {
    ${OutboundFields}
  }
}
`;

const PaginatedOutboundAllocationsWithFilter = (pageNo: number | undefined, pageSize: number | undefined, filter: string) => gql`
query PaginatedOutboundData {
  paginatedOutboundData(input: {page: ${pageNo} size: ${pageSize} filter:{${filter}}} ) {
    ${PaginatedOutboundFields}
  }
}
`;

const ProductConfigCreate = `
mutation createProduct($data: ConfigurationInsertInput!) {
  insertOneConfiguration(data: $data) {
    ${ProductConfigFields}
   }
}
`;

const ProductConfigUpdate = `
mutation updateProduct($id: ObjectId!, $data: ConfigurationInsertInput!) {
  replaceOneConfiguration(query: {_id: $id}, data: $data) {
    ${ProductConfigFields}
   }
}
`;

const SKUTree = gql`
  query skuTree($sku: String!, $onlyEnabled: Boolean, $excludeBaseSKU: Boolean) {
    skuTree(input: { sku: $sku, onlyEnabled: $onlyEnabled, excludeBaseSKU: $excludeBaseSKU }) {
      _depth
      ${SKUFields}
    }
  }
`;

const FindSKUs = gql`
  query skus($query: String!, $limit: Int, $onlyEnabled: Boolean, $productFamily: String, $type: String, $productBase: Boolean) {
    skuSearch(input: { limit: $limit, onlyEnabled: $onlyEnabled, query: $query, productFamily: $productFamily, type: $type, productBase: $productBase }) {
      ${SKUFields}  
    }
  }
`;

export {
  ProductConfigsQuery,
  ProductConfigQuery,
  PoolQuery,
  InboundAllocationsWithFilter,
  OutboundAllocationsWithFilter,
  PoolsWithFilter,
  PaginatedOutboundAllocationsWithFilter,
  PoolRestrictionUpdate,
  PoolDataLoaderQuery,
  PoolProductsQuery,
  ProductConfigCreate,
  ProductConfigUpdate,
  ProductConfigQueryByManufacturerModelId,
  ProductConfigQueryVersionByManufacturerModelId,
  EntitlementActivationJSONQuery,
  SLSLicenseDataForEntitlementIdsQuery,
  SLSOEMsQuery,
  SKUTree,
  FindSKUs,
  SKUs,
};
