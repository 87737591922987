import { ClientType, getClient } from '../graphql/client';
import { Tag, Select, FormInstance, Form } from 'antd';
import { MailOutlined, LoadingOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { ReactNode, useEffect, useState } from 'react';
import { PoolDataLoaderQuery } from '../graphql/queries';
import { gql } from '@apollo/client';
import { REGEXP } from '../helper/form';
import { DirtyFieldIndicator } from './Form';
import { isEqual, isNil, pick } from 'lodash';
import { useMsal } from '@azure/msal-react';

const PoolDataTag = ({
  icon,
  message,
  color,
}: {
  icon: ReactNode;
  message: string | JSX.Element;
  color: string;
}) => {
  return (
    <Tag color={color} style={{ maxWidth: 500, whiteSpace: 'normal', fontSize: 16, padding: '4px 6px' }}>
      {icon}{' '}
      <span style={{ marginLeft: 4 }}>
        {message}
      </span>
    </Tag>
  );
};


const PoolDataLoader: React.FunctionComponent<any> = ({ values, action, isPoolIdDomainLocked, form, index, fields }: { values: string[]; action: 'edit' | 'view'; isPoolIdDomainLocked: string; form?: FormInstance; index?: any; fields?: any; }) => {
  const { instance } = useMsal();
  const [loading, setLoading] = useState(!!values);
  const [valid, setValid] = useState(false);
  const [message, setMessage] = useState<string | JSX.Element>('');
  const [currentDomainList, setCurrentDomainList] = useState<string[]>([]);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [tagError, setTagError] = useState<string>('');


  const handleBlur = () => {
    setTagError('');
  };

  const handleChange = (value: React.SetStateAction<string[]>) => {
    if (form) {
      const currentSKUConfig = form.getFieldValue(['productSKUs', index]) || {};
      const updatedSKUConfig = { ...currentSKUConfig, domainList: value };
      const updatedProductSKUconfigs = [...form.getFieldValue('productSKUs')];
      updatedProductSKUconfigs[index] = updatedSKUConfig;

      form.setFieldsValue({ productSKUs: updatedProductSKUconfigs });
      setCurrentDomainList(value);
      setShowDropdown(false);
    }
  };

  const isValidEmailDomain = (emailDomain: string): boolean => {
    const regex = new RegExp(REGEXP.emailDomain);
    return regex.test(emailDomain);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    setTagError('');
    if (e.key === 'Enter' && target.value) {
      e.preventDefault();
      handleInputChange(target.value);
      setShowDropdown(false);
    }
  };

  const handleInputChange = (tag: string) => {
    if (form) {
      if (isValidEmailDomain(tag) && !currentDomainList.includes(tag)) {
        const updatedDomainList = [...currentDomainList, tag];

        const currentSKUConfig = form.getFieldValue(['productSKUs', index]) || {};
        const updatedSKUConfig = { ...currentSKUConfig, domainList: updatedDomainList };
        const updatedProductSKUconfigs = [...form.getFieldValue('productSKUs')];
        updatedProductSKUconfigs[index] = updatedSKUConfig;

        form.setFieldsValue({ productSKUs: updatedProductSKUconfigs });
        setCurrentDomainList(updatedDomainList);
      } else {
        const updatedDomainList = currentDomainList.filter(domain => domain !== tag);
        form.setFieldValue(['productSKUs', index, 'domainList'], updatedDomainList);
        setCurrentDomainList(updatedDomainList);
        setTagError('Please enter a valid email domain (e.g., @abc.com)');
      }
    }
  };

  useEffect(() => {
    const client = getClient(ClientType.poolAllocations, instance);
    if (values && loading) {
      client
        .query({
          query: gql`
        ${PoolDataLoaderQuery}
      `,
          variables: {
            id: values,
          }
        })
        .then(result => {
          const poolData = result.data?.pool;
          const poolUUID = result.data?.pool?.uuid;
          const poolDomain = result.data?.pool?.restrictions?.emailDomain;

          if (action === 'edit' && form) {
            if (isNil(poolUUID)) {
              setValid(false);
              setMessage('Pool not found');
            } else {
              setValid(true);
              if (!poolDomain) {
                setCurrentDomainList(
                  form.getFieldValue(['productSKUs', index, 'domainList']) || []
                );
              } else {
                setCurrentDomainList(
                  poolDomain
                );
              }
            }
          } else if (action === 'view') {
            if (!(poolData)) {
              setValid(false);
              setMessage('Error loading Pool data');
            } else {
              setValid(true);
              if (poolDomain) {
                setMessage(
                  <>
                    <span>{poolDomain.join(', ')}</span>
                  </>
                );
              }
            }

          }
        })
        .catch(() => {
          setValid(false);
          setMessage('Error getting Pool information.');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [loading, values, instance, action, form, index, isPoolIdDomainLocked]);

  if (loading) {
    return <PoolDataTag color="cyan" message="Checking..." icon={<LoadingOutlined />} />;
  }

  if (!valid) {
    return (
      <PoolDataTag
        color="red"
        message={message || 'Values are invalid.'}
        icon={<CloseCircleOutlined />}
      />
    );
  }

  if (action === 'edit') {

    return (
      <div>
        <div>
          <Form.Item
            label="Domains"
            style={{ marginBottom: 15, marginLeft: 15 }}
            shouldUpdate={(prev, cur) =>
              !isEqual(
                pick(prev, [
                  `productSKUs.${index}.domainList`,
                ]),
                pick(cur, [
                  `productSKUs.${index}.domainList`,
                ])
              )
            }
            {...fields}
            name={[fields.name, 'domainList']}
            rules={
              [
                ({ getFieldValue }) => ({
                  validator() {
                    if (
                      getFieldValue(['productSKUs', index, 'domainPoolOption']) === 'true'
                    ) {
                      if (
                        (!(getFieldValue(['productSKUs', index, 'domainList'])) && currentDomainList.length === 0)  || ((getFieldValue(['productSKUs', index, 'domainList']) && getFieldValue(['productSKUs', index, 'domainList']).length === 0))
                      ) {
                        return Promise.reject(
                          'At least one email domain is required'
                        );
                      }
                    }

                    return Promise.resolve();
                  },
                }),
              ]
            }>
            <Select
              mode="tags"
              style={{
                width: '80%',
                maxWidth: 500
              }}
              placeholder="Ex: @audinate.com (Press 'Enter' after every domain you add)"
              value={currentDomainList}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
              notFoundContent={null}
              open={showDropdown}
            />
            <br></br>
            {tagError && <span style={{ color: 'red' }}>{tagError}</span>}

          </Form.Item>
          <DirtyFieldIndicator
            form={form as FormInstance}
            topOffset={160}
            dirtyFields={[['productSKUs', fields.name, 'domainList']]}
            enabled={true}
          />
        </div>
      </div>
    );
  } else if (action === 'view' && message && isPoolIdDomainLocked) {
    return (
      <div>{`Domains `}<PoolDataTag color="green" message={message} icon={<MailOutlined />} /></div>
    );
  }

  return (<></>);
};

export default PoolDataLoader;
