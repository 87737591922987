import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { RouteProps, useSearchParams } from 'react-router-dom';
import DataLayout from '../components/layout/DataLayout';
import Select, { SingleValue } from 'react-select';
import Button from '../components/Button';
import Loader from '../components/Loader';
import { OutboundAllocationsWithFilter } from '../graphql/queries';
import { ClientType, getClient } from '../graphql/client';
import { OutboundColumnsWithAction } from './Pool';
import { Error } from './Errors';
import { SearchOutlined } from '@ant-design/icons';
import { Checkbox, Input } from 'antd';
import { PATHS } from '../components/layout/Routes';
import EntitlementDownload from '../components/EntitlementDownload';
import { orderBy } from 'lodash';
import Table from '../components/Table';
import { useMsal } from '@azure/msal-react';

const SEARCH_BY_OPTIONS = [{ value: 'entitlementId', label: 'Entitlement ID' }];

const FormOptions = styled.div`
  display: flex;
  flex-direction: column:
  width: 100%;
  `;

const FormOption = styled.div`
  display: flex;
  button {
    width: 120px;
    height: 35px;
    margin-left: 8px;
  }

  label {
    margin-right: 12px;
    line-height: 45px;
  }
  input {
    padding: 5px;
    border-radius: 4px;
    width: 350px;
  }
  > div {
    width: 100%;
  }
  > div {
    width: 250px;
  }
`;

const Results = styled.div`
  margin-top: 3rem;
  div {
    table {
      padding-bottom: 1rem;
      border-bottom: 1px dotted black;
    }
  }
  div {
    table {
      padding-bottom: 1rem;
      border-bottom: 1px dotted black;
    }
  }
  div:last-of-type {
    table {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
`;

const Entitlements: React.FC<RouteProps> = () => {
  const themeContext = useContext(ThemeContext);

  const { instance } = useMsal();

  let [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [showDeactivated, setShowDeactivated] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const [category, setCategory] = useState<string>(searchParams.get('category') || SEARCH_BY_OPTIONS[0].value);
  const [search, setSearch] = useState<string>(searchParams.get('search') || '');

  const [outboundAllocations, setOutboundAllocations] = useState<any[]>([]);

  const onSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setSearch(value);
  };

  const onSelectChange = (newValue: SingleValue<{ value: string; label: string }>) => {
    if (newValue) {
      setCategory(newValue.value);
    }
  };

  useEffect(
    () => {
      const refParams = searchParams.get('refParams');
      if (!searchParams.get('search') && !searchParams.get('category')) {
        setSearchParams({
          search,
          category,
          ...(refParams ? { refParams } : {}),
        });
      } else if (search.length && category.length) {
        handleSearch();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // If category is "entitlementId", show outbound allocations where entitlement = query.
  const handleSearch = useCallback(async () => {
    const trimmedQuery = search.trim();
    if (!trimmedQuery) {
      alert('Please enter something to search for.');
      return;
    }

    const refParams = searchParams.get('refParams');
    setSearchParams({
      search,
      category,
      ...(refParams ? { refParams } : {}),
    });

    clear();
    setLoading(true);

    try {
      const client = getClient(ClientType.poolAllocations, instance);
      const query = await client.query({
        query: OutboundAllocationsWithFilter(`$q: String!`, `destination: { entitlement_in: [$q] }, pool: { uuid_exists: true }`),
        variables: { q: trimmedQuery },
      });

      const outboundAllocations = query.data?.outboundAllocations || [];
      if (outboundAllocations.length) {
        setOutboundAllocations(orderBy(outboundAllocations, ['created.at'], ['desc']));
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      if (error instanceof Error) {
        setError(error.toString());
      }
    }

    setLoading(false);
  }, [setError, setLoading, search, category, instance, setSearchParams, searchParams]);

  const onFormSubmit = (event: React.FormEvent<HTMLButtonElement | HTMLInputElement>) => {
    event.preventDefault();
    handleSearch();
  };

  const clear = () => {
    setError(undefined);
    setOutboundAllocations([]);
  };

  let content = <Loader />;

  const deactivatedCount = (outboundAllocations || []).filter((oa: any) => oa.deactivated).length;
  const filteredOutboundAllocations = (outboundAllocations || []).filter((oa: any) =>
    showDeactivated ? true : !oa.deactivated
  );

  if (!loading && error) {
    content = <Error message={error.toString()} />;
  } else if (!loading) {
    content = (
      <Results>
        {!!(outboundAllocations || []).length && (
          <DataLayout nested title={`Current Activation`}>
            <EntitlementDownload entitlementId={search} />
          </DataLayout>
        )}
        <DataLayout nested title={`[ ${filteredOutboundAllocations.length} ] Outbound allocations (purchases)`}>
          <>
            {deactivatedCount > 0 && (
              <Checkbox
                style={{ float: 'right', marginBottom: 12 }}
                value={showDeactivated}
                onChange={() => setShowDeactivated(!showDeactivated)}
              >
                Show {deactivatedCount} deactivated allocation(s)?
              </Checkbox>
            )}
            <Table
              columns={OutboundColumnsWithAction.filter(column => column.accessor !== 'destination' && column.accessor !== 'no')}
              data={filteredOutboundAllocations || [] }
            />
          </>
        </DataLayout>
      </Results>
    );
  }
  return (
    <DataLayout
      title="Endpoint Entitlement / Device - Search"
      backPath={PATHS.POOLS}
      backLabel="Back to Pools &amp; Transactions - Search"
    >
      <form>
        <FormOptions>
          <FormOption style={{ flex: 3 }}>
            <label>Search by:</label>
            <div>
              <Select
                isDisabled={loading}
                styles={{
                  control: base => ({
                    ...base,
                    height: 40,
                    minHeight: 40,
                  }),
                }}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: themeContext.colour.darkSilver,
                    primary: themeContext.colour.mediumGrey,
                  },
                })}
                isSearchable
                value={SEARCH_BY_OPTIONS.find((s: any) => s.value === category)}
                options={SEARCH_BY_OPTIONS}
                onChange={onSelectChange}
              />
            </div>
          </FormOption>
          <FormOption style={{ flex: 6, display: 'flex' }}>
            <label>Search for:</label>
            <div style={{ display: 'inline-block', flex: '1' }}>
              <Input
                autoFocus
                type="text"
                disabled={loading}
                value={search}
                onChange={onSearchChange}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter') {
                    onFormSubmit(e);
                  }
                }}
              />
              <Button
                type="primary"
                loading={loading}
                icon={<SearchOutlined />}
                disabled={loading}
                label="Search"
                onClick={onFormSubmit}
              />
            </div>
          </FormOption>
        </FormOptions>
      </form>
      {content}
    </DataLayout>
  );
};

export default Entitlements;
