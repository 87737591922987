import React from 'react';

interface DanteLogoProps {
  className?: string;
  icon?: boolean;
}

const DanteLogo: React.FunctionComponent<DanteLogoProps> = ({ className = '', icon = false }) => (
  <svg width={icon ? '20' : '56'} height={icon ? '20' : '56'} viewBox="0 0 56 56" fill="none" className={className}>
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="white"
    />
    <path
      d="M28 54.4631C42.6152 54.4631 54.4631 42.6152 54.4631 28C54.4631 13.3848 42.6152 1.53687 28 1.53687C13.3848 1.53687 1.53687 13.3848 1.53687 28C1.53687 42.6152 13.3848 54.4631 28 54.4631Z"
      fill="#333333"
    />
    <path
      d="M14.1993 19.7389C11.6679 20.9865 9.62539 22.5658 8.22199 24.3606C6.81859 26.1555 6.09001 28.1202 6.08984 30.1103C6.08973 31.8586 6.65183 33.5898 7.74405 35.205C8.83627 36.8203 10.4372 38.2879 12.4555 39.5242C14.4738 40.7604 16.8698 41.7411 19.5069 42.4102C22.1439 43.0792 24.9703 43.4236 27.8246 43.4236C30.6789 43.4236 33.5053 43.0792 36.1423 42.4102C38.7794 41.7411 41.1754 40.7604 43.1937 39.5242C45.212 38.2879 46.8129 36.8203 47.9051 35.205C48.9974 33.5898 49.5595 31.8586 49.5594 30.1103C49.5593 28.1219 48.8321 26.1589 47.4312 24.3652C46.0303 22.5716 43.9912 20.9929 41.4638 19.7452C41.3164 22.0262 39.8231 24.1112 38.0172 25.3603C34.0221 28.0906 28.9716 28.7665 24.3204 27.982C20.848 27.3756 17.1435 25.9183 15.143 22.7053C14.5432 21.8207 14.2435 20.7883 14.1993 19.7389Z"
      fill="white"
    />
    <path
      d="M40.2444 14.8954C40.2444 16.9129 38.9359 18.8479 36.6067 20.2745C34.2776 21.7012 31.1185 22.5026 27.8246 22.5026C24.5307 22.5026 21.3716 21.7012 19.0425 20.2745C16.7133 18.8479 15.4048 16.9129 15.4048 14.8954C15.4048 13.8964 15.726 12.9071 16.3502 11.9842C16.9743 11.0612 17.8892 10.2226 19.0425 9.51621C20.1957 8.80981 21.5649 8.24946 23.0717 7.86716C24.5786 7.48485 26.1936 7.28809 27.8246 7.28809C29.4556 7.28809 31.0706 7.48485 32.5774 7.86716C34.0843 8.24946 35.4534 8.80981 36.6067 9.51621C37.76 10.2226 38.6749 11.0612 39.299 11.9842C39.9232 12.9071 40.2444 13.8964 40.2444 14.8954Z"
      fill="#E51937"
    />
  </svg>
);

export default DanteLogo;
