import { capitalize, omit } from 'lodash';

const hextoASCII = (hex: string) => {
  let str = '';
  for (let n = 0; n < hex.length; n += 2) {
    str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
  }
  // Remove non-printable ASCII.
  str = str.replace(/[^\x20-\x7E]+/g, '').trim();

  return str.length > 0 ? str : '(Non-ASCII ID)';
};

const fixedFeatureSetFeatures = (featureSet: any, shortFormat = true) => {
  const singular = featureSet.features.length <= 1;
  const hasTX = !!featureSet.features.find((f: any) => f.sku === 'IFE-AUDIO-CHANNEL-TX');
  return featureSet.features
    .sort((a: any, b: any) => b.sku.localeCompare(a.sku))
    .map((feature: any, i: number) => {
      const last = i === featureSet.features.length - 1;
      if (shortFormat) {
        return (
          <span key={i}>
            {singular && !hasTX && '0x'}
            {feature.quantity}
            {!last ? 'x' : ''}
            {singular && hasTX && 'x0'}
          </span>
        );
      } else {
        return (
          <span key={i}>
            {feature.sku}: {feature.quantity}
            <br />
          </span>
        );
      }
    });
};

const formatPriceMultiplier = (data: any) => {
  const pricing = omit(data, '__typename');

  if (!pricing.oem && !pricing.retail) {
    return 'None (Default)';
  }
  return Object.keys(pricing).map((price: any, i: number) => {
    return (
      <span key={i}>
        {capitalize(price)}: x{pricing[price].multiplier}
        <br />
      </span>
    );
  });
};

export { hextoASCII, fixedFeatureSetFeatures, formatPriceMultiplier };
