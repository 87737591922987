import { Form } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { NamePath } from 'antd/lib/form/interface';
import styled from 'styled-components';

const FormItem = styled(Form.Item)`
  background-color: ${p => p.theme.colour.silver};
  min-height: 50px;
  line-height: 50px;
  width: 100%;
  border-bottom: 2px solid ${p => p.theme.colour.lightSilver};
  padding-bottom: 4px;
  margin-bottom: 0;
  > .ant-form-item-row > .ant-form-item-label {
    min-width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-right: 2px solid ${p => p.theme.colour.lightSilver};
  }
  s > .ant-form-item-row > .ant-form-item-control {
    margin-left: 16px;
    display: flex;
    justify-content: center;
    justify-items: center;
  }

  .ant-form-item-control > div > div > div > div > div .ant-form-item-required::before {
    display: none;
  }
`;

const DirtyFieldWrap = styled.div`
  display: inline-block;
  position: absolute;
  top: 8px;
  right: 12px;
`;

const DirtyIndicator = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 18px;
  background-color: #ff9933;
  transition: opacity 500ms;
  opacity: 0;
`;

const DirtyFieldIndicator = ({
  form,
  dirtyFields,
  topOffset,
  enabled = true,
}: {
  form: FormInstance;
  dirtyFields: NamePath[];
  topOffset?: number;
  enabled?: boolean;
}) => {
  const dirty = form.isFieldsTouched(dirtyFields, false);
  return enabled ? (
    <DirtyFieldWrap>
      <DirtyIndicator style={{ opacity: dirty ? 1 : 0, marginTop: topOffset || 0, top: 8 }} />
    </DirtyFieldWrap>
  ) : null;
};

const TOOLTIPS = {
  manufacturerId:
    'Manufacturer-specific ID, assigned by Audinate. The manufacturer ID is provided as a 64-bit value (16 hexadecimal digits). Value entered must be a 16 digit hexadecimal string.',
  modelId:
    'Product-specific ID. Must be unique for every Dante product or Dante application made by an OEM or ISV. Assigned by the OEM or ISV. This is a 64-bit value (16 hexadecimal digits). Value entered must be a 16 digit hexadecimal.',
  manufacturerIdASCII: 'ASCII string equivalent of the Manufacturer ID.',
  modelIdASCII: 'ASCII string equivalent of the Model ID.',
  modelVersion:
    'Product version, assigned by the manufacturer. Composed of three sub-fields are formatted as 3 dot-separated non-negative integers (e.g., 1.2.3) to a maximum of 32 bits (8.8.16). This is always incremented for any new firmware update that is released by the manufacturer.',
  oemPaysOption:
    'If yes, all customer activations of this product configuration will be deducted from an OEM pool (instead of the customer paying directly).',
  oemPoolUUID: 'ID of the OEM pool from which to deduct customer activations of this product configuration.',
  domainPoolOption:
    'If yes, all OEM/ODM user activations (with an email domain locked in the specified pool) of this product configuration will be deducted from an OEM/ODM pool (instead of the OEM/ODM user paying directly).',
  productBaseSKU:
    'The SKU that represents the starting point for future upgrades of this product, and also sets the product family for Feature / Addon SKUs.',
  label: 'Description shown in Dante Activator for this product configuration.',
  additionalInformationType:
    "Chooses the icon to show for additional information. 'None' means no additional information is shown.",
  additionalInformationLabel:
    'Additional information is for communicating important information to customers before they choose this product configuration, such as pre-requisites or functional limitations. Supplied by the OEM.',
  active: 'Makes this product visible and usable with activation sources (such as Dante Activator).',
  allowedActivationUpgradeSources: 'Which activation sources are allowed to upgrade this product?',
  owner: 'The OEM / ISV contact e-mail address for this product.',
  priceOverride: 'Overrides the purchase price of this upgrade from the price originally set by BuSY.',
  factoryPreactivation:
    'Do not show / use this device in activation sources (eg Dante Activator) unless the device has been factory pre-activated.',
  version: (
    <>
      The OEM specified product version that the config can be applied to (where a product is uniquely identified by
      Manufacturer ID and Model ID as well as product version).
      <br />
      <br />
      If the OEM uses the same configuration for all versions of the product the "*.*.*" (wildcard / default) product
      version should be used.
      <br />
      <br />
      If the OEM has more than one or more configuration for different product versions the following applies:
      <br />
      <ul>
        <li>
          The product version is the minimum version supported for that config (i.e. a configuration with v1.0.0 will
          match the product version of v1.0.0 or higher).
        </li>
        <li>
          If more than one configuration matches, the highest version will be used (i.e. there is a configuration of
          v1.0.0 and v2.0.0 and the product version is v2.1.0, the v2.0.0 configuration will be used).
        </li>
        <li>If no version matches, then the "*.*.*" (default) configuration will be used if it exists.</li>
      </ul>
    </>
  ),
  reference: 'The ticket or reference number associated with this product.',
};

export { FormItem, TOOLTIPS, DirtyFieldIndicator };
