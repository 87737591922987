import { ClientType, getClient } from '../graphql/client';
import { Tag } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { ReactNode, useEffect, useState } from 'react';
import { PoolProductsQuery, SLSOEMsQuery } from '../graphql/queries';
import { gql } from '@apollo/client';
import { useMsal } from '@azure/msal-react';

const ValidationTag = ({
  icon,
  prefix,
  message,
  color,
}: {
  icon: ReactNode;
  prefix: string | undefined;
  message: string | JSX.Element;
  color: string;
}) => {
  return (
    <Tag color={color} style={{ maxWidth: 500, whiteSpace: 'normal', fontSize: 16, padding: '4px 6px' }}>
      {icon}{' '}
      <span style={{ marginLeft: 4 }}>
        {prefix ? `${prefix} -` : ''} {message}
      </span>
    </Tag>
  );
};

const RestrictionValidator = ({ values, type }: { values: string[]; type: 'oemuuid' | 'productFamily' | 'emailDomain'}) => {
  const { instance } = useMsal();
  const [loading, setLoading] = useState(!!values.length);
  const [valid, setValid] = useState(false);
  const [reason, setReason] = useState<string | JSX.Element>('');

  useEffect(() => {
    const client = getClient(ClientType.poolAllocations, instance);
    if (type && values.length && loading) {
      if (type === 'oemuuid') {
        client
          .query({
            query: gql`
              ${SLSOEMsQuery}
            `,
            variables: {
              oemUUIDs: values,
            },
          })
          .then(result => {
            const manufacturers = result.data?.getSLSOEMs || [];
            const missing = values.filter(
              (v: string) => !manufacturers.find((m: any) => m.valid && m.uuid.toUpperCase() === v.toUpperCase())
            );
            if (missing.length) {
              setValid(false);
              setReason(
                <>
                  <span>OEMs are missing in the SLS:</span>
                  <ol style={{ marginTop: 4 }}>
                    {missing.map((v: string, idx: number) => (
                      <li key={idx}>{v}</li>
                    ))}
                  </ol>
                </>
              );
            } else {
              setValid(true);
              setReason(
                <>
                  <span>OEMs exist in the SLS:</span>
                  <br />
                  <ul style={{ listStyleType: 'none' }}>
                    {manufacturers.map((oem: any, idx: number) => (
                      <li key={idx}>
                        <strong>{oem.name}</strong> (<span style={{ fontFamily: 'monospace' }}>0x{oem._id}</span>)
                      </li>
                    ))}
                  </ul>
                </>
              );
            }
          })
          .catch(() => {
            setValid(false);
            setReason('Error getting OEM information.');
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (type === 'productFamily') {
        client
          .query({
            query: gql`
              ${PoolProductsQuery}
            `,
            variables: {
              families: values,
            },
          })
          .then(result => {
            const productCodes: string[] = [];
            if (
              result.data &&
              values.every((v: string) =>
                result.data?.productMaps?.find((product: any) => {
                  if (product.family === v) {
                    for (const code of product.productCodes) {
                      productCodes.push(code);
                    }
                  }
                  return product.family === v;
                })
              )
            ) {
              setValid(true);
              setReason(
                <>
                  <span>Product families exist in the PAM, pool supports codes:</span>
                  <br />
                  <span>{productCodes.join(', ')}</span>.
                </>
              );
            } else {
              setValid(false);
              setReason(
                `Unrecognised by the PAM: ${values
                  .filter((v: string) => !(result.data?.products || []).find((product: any) => product.family === v))
                  .join(', ')}`
              );
            }
          })
          .catch(() => {
            setValid(false);
            setReason('Error getting product information.');
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (type === 'emailDomain') {
        setValid(true);
        setLoading(false);
      }
    }
  }, [loading, values, type, instance]);

  if (loading) {
    return <ValidationTag color="cyan" message="Checking..." icon={<LoadingOutlined />} prefix={undefined} />;
  }

  if (!values.length) {
    return (
      <ValidationTag color="green" prefix="Valid" message="No restriction value set." icon={<CheckCircleOutlined />} />
    );
  }

  if (!valid) {
    return (
      <ValidationTag
        color="red"
        prefix="Invalid"
        message={reason || 'Values are valid.'}
        icon={<CloseCircleOutlined />}
      />
    );
  }

  return (
    <ValidationTag color="green" prefix="Valid" message={reason || 'Values are valid.'} icon={<CheckCircleOutlined />} />
  );
};

export default RestrictionValidator;
