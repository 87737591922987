import { validate as isValidUUID } from 'uuid';

const hextoASCII = (hex: string) => {
  let str = '';
  for (let n = 0; n < hex.length; n += 2) {
    str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
  }

  str = str.replace(/\0+$/gm, '');
  const unprintables = str.match(/[^\x20-\x7E]+/g);
  if (unprintables?.length || !str.length) {
    return '(Non-ASCII ID)';
  }
  return str.substring(0, 8);
};

const ASCIItoHex = (ascii: string) => {
  let hex = '';
  for (let n = 0; n < ascii.length; n++) {
    hex += ascii.charCodeAt(n).toString(16);
  }
  if (hex.length < 1) {
    return '';
  }
  return hex
    .substring(0, 16)
    .padEnd(16, '0')
    .toLowerCase();
};

const REGEXP = {
  manufacturerModelId: /^[0-9A-Fa-f]{16}$/,
  manufacturerModelIdASCII: /^[ -~].*$/,
  emailDomain: /^@([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+)$/
};

const isValidUUIDv4 = (uuid: string) => {
  return isValidUUID(uuid);
};

export { hextoASCII, ASCIItoHex, isValidUUIDv4, REGEXP };
