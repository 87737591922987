import { AccountInfo } from '@azure/msal-browser';
import { get } from 'lodash';

// @ts-ignore
declare const CONFIG;
const { groupRead, groupWrite } = CONFIG.authms;

const getUserGroups = (user: AccountInfo | null): string[] => {
  const groups = get(user, "idTokenClaims.groups");
  return groups || [];
};

const hasAccess = (user: AccountInfo | null | undefined) => {
  if (user) {
    const groups = getUserGroups(user);
    if ([groupRead, groupWrite].some(group => groups.includes(group))) {
      return true;
    }
  }
  return false;
};

const hasWriteAccess = (user: AccountInfo | null | undefined) => {
  if (user) {
    const groups = getUserGroups(user);
    if (groups.includes(groupWrite)) {
      return true;
    }
  }
  return false;
};

export { hasAccess, hasWriteAccess };
