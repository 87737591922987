// @ts-nocheck
import React from 'react';
import { useTable, useSortBy } from 'react-table';
import styled from 'styled-components';

export const StyledTable = styled.div`
  margin-top: ${props => (props.negativeMargin ? -20 : -5)}px;
  .psp-table {
    text-align: center;
    width: 100%;
    border-spacing: 1px;
    border: 1px solid ${p => p.theme.colour.lightSilver};
  }

  .psp-table-tr {
    td {
      padding: 0.3rem;
      border-right: 1px solid ${p => p.theme.colour.lightSilver};
    }
    background-color: ${p => p.theme.colour.darkSilver};
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  .psp-table-tr:nth-child(odd) {
    background-color: ${p => p.theme.colour.silver};
  }

  .psp-table-th {
    color: ${p => p.theme.colour.white};
    font-weight: bold;
    border-right: 1px solid ${p => p.theme.colour.lightSilver};
    background-color: ${p => p.theme.colour.mediumGrey};
  }

  .psp-table-th,
  .psp-table-td {
    margin: 0;
    padding: 0.5rem;
    :last-child {
      border-right: 0;
    }
  }

  .psp-table-sublabel {
    font-size: 11px;
    margin-bottom: 2px;
  }
`;

export const DataColumns = [
  {
    accessor: 'label',
  },
  {
    accessor: 'value',
  },
];

interface TableProps {
  columns: any;
  data: any;
  sorting?: boolean;
  negativeMargin?: boolean;
  headless?: boolean;
  tableName?: string;
}

const Table: React.FC<TableProps> = ({ columns, data, negativeMargin = false, sorting = true, headless = false, tableName }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    sorting ? useSortBy : undefined
  );

  // Render the UI for your table
  return (
    <StyledTable negativeMargin={negativeMargin}>
      <table className="psp-table" {...getTableProps()}>
        {!headless && (
          <thead>
            {headerGroups.map(headerGroup => (
              <tr className="psp-table-tr" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th
                    className="psp-table-th"
                    {...column.getHeaderProps({
                      style: {
                        minWidth: column.minWidth,
                        width: column.width,
                        maxWidth: column.maxWidth,
                      },
                    })}
                  >
                    {column.render('Header')}
                    {/* Add a sort direction indicator */}
                    <span>{column.isSorted ? (column.isSortedDesc ? ' ⇂' : ' ↿') : ''}</span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        )}
        <tbody {...getTableBodyProps()}>
          {!rows.length && (
            <tr className="psp-table-tr">
              <td colSpan={columns.length} data="psp-table-td">
                No data.
              </td>
            </tr>
          )}
          {rows.map((row: any, i: any) => {
            prepareRow(row);
            return (
              <tr className="psp-table-tr" {...row.getRowProps()}>
                {/* Add a custom column for the row number */}
                {tableName === "poolOutbound" && (
                <td className="psp-table-td-no" {...row.getRowProps()}>{ i + 1 }</td>
                )}
                {row.cells.map((cell: any, i: any) => {
                  const headlessLabel = headless && i === 0;
                  // Skip the first column
                  if (tableName === "poolOutbound" && i === 0) {
                    return null;
                  }
                  return (
                    <td
                      data="psp-table-td"
                      style={{ width: headlessLabel ? '40%' : undefined }}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </StyledTable>
  );
};

export default Table;
