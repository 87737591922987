import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { RouteProps, useSearchParams } from 'react-router-dom';
import DataLayout from '../components/layout/DataLayout';
import Select, { SingleValue } from 'react-select';
import Button, { ButtonList } from '../components/Button';
import Loader from '../components/Loader';
import Table from '../components/Table';
import { PATHS } from '../components/layout/Routes';
import { InboundAllocationsWithFilter, OutboundAllocationsWithFilter, PaginatedOutboundAllocationsWithFilter, PoolsWithFilter } from '../graphql/queries';
import { ClientType, getClient } from '../graphql/client';
import { formatPoolName, formatTimestamp } from '../formatter/pool';
import { InboundColumnsWithAction, OutboundColumnsWithAction } from './Pool';
import { orderBy, uniqBy } from 'lodash';
import { Error } from './Errors';
import { SearchOutlined } from '@ant-design/icons';
import { Checkbox, Input } from 'antd';
import { PaginatedTable } from '../components/PaginatedTable';
import { useMsal } from '@azure/msal-react';

const SEARCH_BY_OPTIONS = [
  { value: 'poolUUID', label: 'Pool UUID' },
  { value: 'txnId', label: 'Order reference (eg: FastSpring)' },
  { value: 'email', label: 'Customer email' },
  { value: 'manufModel', label: 'Manufacturer ID : Model ID' },
];

const FormOptions = styled.div`
  display: flex;
  flex-direction: column:
  width: 100%;
  `;

const FormOption = styled.div`
  display: flex;
  button {
    width: 120px;
    height: 35px;
    margin-left: 8px;
  }

  label {
    margin-right: 12px;
    line-height: 45px;
  }
  input {
    padding: 5px;
    border-radius: 4px;
    width: 350px;
  }
  > div {
    width: 100%;
  }
  > div {
    width: 250px;
  }
`;

const Results = styled.div`
  margin-top: 3rem;
  div {
    table {
      padding-bottom: 1rem;
      border-bottom: 1px dotted black;
    }
  }
  div {
    table {
      padding-bottom: 1rem;
      border-bottom: 1px dotted black;
    }
  }
  div:last-of-type {
    table {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
`;

const PoolsColumns = [
  {
    accessor: 'created',
    Header: 'Date / time',
    minWidth: 200,
    Cell: ({ cell }: { cell: any }) => formatTimestamp(cell.value?.at || "-"),
  },
  {
    Header: 'Owner',
    accessor: 'owner.email',
  },
  {
    Header: 'Name',
    accessor: 'name',
    minWidth: 200,
    Cell: ({ cell }: { cell: any }) => formatPoolName(cell.value),
    sortType: (rowA:any, rowB:any, columnId:any) => {
      return rowA.original[columnId].localeCompare(rowB.original[columnId]);
    },
  },
  {
    Header: 'SKU',
    accessor: 'productSKU',
    minWidth: 200,
    // @ts-ignore
    Cell: ({ cell }) => cell.value?.sku,
  },
  {
    Header: 'Feature SKUs',
    accessor: 'featureSKUs',
    minWidth: 200,
    // @ts-ignore
    Cell: ({ cell }) =>
      (cell.value || [])
        .sort((a: any, b: any) => b.id.localeCompare(a.id))
        .map((sku: any) => (
          <span>
            {sku.sku}
            <br />
          </span>
        )),
  },
  {
    Header: 'Balance',
    accessor: 'balance',
    minWidth: 150,
    // @ts-ignore
    Cell: ({ cell }) => cell.value || 0,
  },
  {
    Header: 'Actions',
    accessor: 'uuid',
    // @ts-ignore
    Cell: ({ cell }) => (
      <ButtonList>
        <Button
          label="More Details"
          path={`${PATHS.POOLS}/${cell.value}?refParams=${encodeURIComponent(window.location.search)}`}
        />
      </ButtonList>
    ),
  },
];

const Pools: React.FC<RouteProps> = () => {
  const themeContext = useContext(ThemeContext);

  const { instance } = useMsal();

  let [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [showDeactivated, setShowDeactivated] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const [category, setCategory] = useState<string>(searchParams.get('category') || SEARCH_BY_OPTIONS[0].value);
  const [search, setSearch] = useState<string>(searchParams.get('search') || '');

  const [pools, setPools] = useState<any[]>([]);
  const [inboundAllocations, setInboundAllocations] = useState<any[]>([]);
  const [outboundAllocations, setOutboundAllocations] = useState<any[]>([]);

  const deactivatedCount = (outboundAllocations || []).filter((oa: any) => oa.deactivated).length;
  const filteredOutboundAllocations = (outboundAllocations || []).filter((oa: any) =>
    showDeactivated ? true : !oa.deactivated
  );

  const onSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setSearch(value);
  };

  const onSelectChange = (newValue: SingleValue<{ value: string; label: string }>) => {
    if (newValue) {
      setCategory(newValue.value);
    }
  };

  useEffect(
    () => {
      if (!searchParams.get('search') && !searchParams.get('category')) {
        setSearchParams({
          search,
          category,
        });
      } else if (search.length && category.length) {
        handleSearch();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  
  // End User pools before IFE 1.7 are formatted to have default string with 64 in length (Now it is `IFE User-Pays Order (fastspring)`)
  // Previous pools before IFE 1.7 also don't have created object
  const formatPoolData = (pools: any) => {
    pools.map((pools: { name: string; created: any; }) => {
      const poolName = (pools.name).replace(/\s/g, "");
      if (poolName.length === 64 && !(pools.created)) {
        return pools.name = "IFE User-Pays Order (fastspring)";
      } else {
        return pools;
      }
    });
  }

  const sanitizeQueryParam = (param: string) => {
    return String(param).replace(/[&<>"'/]/g, '');
  }

  // If category is "txnId", show pools and inbound and outbound allocations where reference ID = query.
  // If category is "email", show pools where owner = query.
  // If category is "poolUUID", return pool matching that UUID.
  // If category is "manufModel", show pools for a specific manufacturer and model ID ("abcd:1234").
  const handleSearch = useCallback(async () => {
    const trimmedQuery = search.trim();
    if (!trimmedQuery) {
      alert('Please enter something to search for.');
      return;
    }

    setSearchParams({
      search,
      category,
    });

    let variables: any = { q: trimmedQuery };

    clear();
    setLoading(true);

    try {
      const client = getClient(ClientType.poolAllocations, instance);
      if (category === 'txnId') {
        const inboundsQuery = await client.query({
          query: InboundAllocationsWithFilter(`$q: String!`, `source: { reference_in: [$q] }, pool: { uuid_exists: true }`),
          variables,
        });

        const inboundAllocations = orderBy(inboundsQuery.data?.inboundAllocations || [], ['created.at'], ['desc']);

        if (inboundAllocations.length) {
          const poolUUIDs = uniqBy(
            inboundAllocations.map((inboundAllocation: any) => inboundAllocation.pool),
            'uuid'
          );

          const ids = poolUUIDs.map((pool: any) => pool.uuid);

          variables = { uuids: ids || [] };
          const poolsQuery = await client.query({
            query: PoolsWithFilter(`$uuids: [String!]`, `uuid_in: $uuids`),
            variables,
          });

          const outboundQuery = await client.query({
            query: OutboundAllocationsWithFilter(`$uuids: [String!]`, `pool: { uuid_in: $uuids}`),
            variables,
          });
    
          const outboundAllocations = orderBy(outboundQuery.data?.outboundAllocations || [], ['created.at'], ['desc']);
          const pools = poolsQuery?.data?.pools || [];
          formatPoolData(pools);
          setPools(pools);
          setInboundAllocations(inboundAllocations);
          setOutboundAllocations(outboundAllocations);
        }
      } else if (category === 'email') {
        const poolsQuery = await client.query({
          query: PoolsWithFilter(`$q: String`, `owner: {email: $q}`),
          variables,
        });

        const pools = poolsQuery.data?.pools || [];

        formatPoolData(pools);
        setPools(pools);
      } else if (category === 'manufModel') {
        const split = trimmedQuery.split(':');
        let outboundAllocations = [];

        // Only search by manufacturer.
        if (split.length === 1) {
          const manufacturerId = split[0].trim();

          // Query outbound total based on manufacturer
          const query = await client.query({
            query: PaginatedOutboundAllocationsWithFilter(1, 1, `destination: { device: { manufacturer: { id: "${manufacturerId}" }}}`),
          });
          const outboundTotal = query.data?.paginatedOutboundData?.pageInfo?.totalCount || 0;

          // Query all outbound based on manufacturer
          const outboundQuery = await client.query({
            query: PaginatedOutboundAllocationsWithFilter(1, outboundTotal, `destination: { device: { manufacturer: { id: "${manufacturerId}" }}}`),
          });

          outboundAllocations = outboundQuery.data?.paginatedOutboundData?.result || [];

          // Search by manufacturer and model.
        } else {
          const manufacturerId = split[0].trim();
          const modelId = split[1].trim();

          // Query outbound total based on manufacturer and model
          const query = await client.query({
            query: PaginatedOutboundAllocationsWithFilter(1, 1, `destination: { device: { manufacturer: { id: "${manufacturerId}" }, model: { id: "${modelId}" }}}`),
          });
          const outboundTotal = query.data?.paginatedOutboundData?.pageInfo?.totalCount || 0;

          // Query all outbound based on manufacturer and model
          const outboundQuery = await client.query({
            query: PaginatedOutboundAllocationsWithFilter(1, outboundTotal, `destination: { device: { manufacturer: { id: "${manufacturerId}" }, model: { id: "${modelId}" }}}`),
          });
          
          outboundAllocations = outboundQuery.data?.paginatedOutboundData?.result || [];
        }

        if (outboundAllocations.length) {
          const poolUUIDs = uniqBy(
            outboundAllocations.map((outboundAllocation: any) => outboundAllocation),
            'pool'
          );

          const ids = poolUUIDs.map((pool: any) => pool.pool);

          variables = { uuids: ids || [] };
          const poolsQuery = await client.query({
            query: PoolsWithFilter(`$uuids: [String!]`, `uuid_in: $uuids`),
            variables,
          });

          const pools = poolsQuery?.data?.pools || [];

          formatPoolData(pools);
          setPools(pools);
        }
      } else if (category === 'poolUUID') {

        const poolId = sanitizeQueryParam(variables.q);
        const queryString = new URLSearchParams(encodeURIComponent(window.location.search)).toString();
        window.location.href = `${PATHS.POOLS}/${poolId}?${queryString}`;

      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      if (error instanceof Error) {
        setError(error.toString());
      }
    }
    setLoading(false);
  }, [setError, setLoading, search, category, instance, setSearchParams]);

  const onFormSubmit = (event: React.FormEvent<HTMLButtonElement | HTMLInputElement>) => {
    event.preventDefault();
    handleSearch();
  };

  const clear = () => {
    setError(undefined);
    setPools([]);
    setInboundAllocations([]);
    setOutboundAllocations([]);
  };

  let content = <Loader />;

  if (!loading && error) {
    content = <Error message={error.toString()} />;
  } else if (!loading) {
    content = (
      <Results>
        <DataLayout nested title={`[ ${pools.length} ] Pool(s)`}>
          <PaginatedTable columns={PoolsColumns} data={pools || []} tableName={`poolSummary`} />
        </DataLayout>
        {!!(inboundAllocations || []).length && (
        <DataLayout nested title={`[ ${inboundAllocations.length} ] Inbound allocations`}>
          <Table columns={InboundColumnsWithAction} data={inboundAllocations || []} />
        </DataLayout>
        )}
        {!!(filteredOutboundAllocations || []).length && (
        <DataLayout nested title={`[ ${filteredOutboundAllocations.length} ] Outbound allocations (purchases)`}>
          <>
            {deactivatedCount > 0 && (
              <Checkbox
                style={{ float: 'right', marginBottom: 12 }}
                value={showDeactivated}
                onChange={() => setShowDeactivated(!showDeactivated)}
              >
                Show {deactivatedCount} deactivated allocation(s)?
              </Checkbox>
            )}
            <Table columns={OutboundColumnsWithAction} data={filteredOutboundAllocations || []} tableName={`poolOutbound`} />
          </>
        </DataLayout>
        )}
      </Results>
    );
  }
  return (
    <DataLayout title="Pools &amp; Transactions - Search">
      <form>
        <FormOptions>
          <FormOption style={{ flex: 4 }}>
            <label>Search by:</label>
            <div style={{ position: 'relative', zIndex: '2' }}>
              <Select
                isDisabled={loading}
                styles={{
                  control: base => ({
                    ...base,
                    height: 40,
                    minHeight: 40,
                  }),
                }}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: themeContext.colour.darkSilver,
                    primary: themeContext.colour.mediumGrey,
                  },
                })}
                isSearchable
                value={SEARCH_BY_OPTIONS.find((s: any) => s.value === category)}
                options={SEARCH_BY_OPTIONS}
                onChange={onSelectChange}
              />
            </div>
          </FormOption>
          <FormOption style={{ flex: 6, display: 'flex' }}>
            <label>Search for:</label>
            <div style={{ display: 'inline-block', flex: '1' }}>
              <Input
                autoFocus
                type="text"
                disabled={loading}
                value={search}
                onChange={onSearchChange}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter') {
                    onFormSubmit(e);
                  }
                }}
              />
              <Button
                type="primary"
                loading={loading}
                icon={<SearchOutlined />}
                disabled={loading}
                label="Search"
                onClick={onFormSubmit}
              />
            </div>
          </FormOption>
        </FormOptions>
      </form>
      {content}
    </DataLayout>
  );
};

export default Pools;
