import { DownloadOutlined } from '@ant-design/icons';
import { gql } from '@apollo/client';
import { message } from 'antd';
import { get } from 'lodash';
import { useCallback, useState } from 'react';
import { ClientType, getClient } from '../graphql/client';
import { EntitlementActivationJSONQuery } from '../graphql/queries';
import Button from './Button';
import { useMsal } from '@azure/msal-react';

const EntitlementDownload = ({ entitlementId }: { entitlementId: string | undefined }) => {
  const [loading, setLoading] = useState(false);
  const { instance } = useMsal();

  const onDownloadEntitlement = useCallback(() => {
    setLoading(true);
    const client = getClient(ClientType.poolAllocations, instance);
    client
      .query({
        query: gql`
          ${EntitlementActivationJSONQuery}
        `,
        variables: {
          entitlementId,
        },
      })
      .then(response => {
        const data = get(response, 'data.getEntitlementActivationJSON');
        if (!data) {
          return message.error('Unable to retrieve activation JSON.');
        }

        const parsed = JSON.parse(data);
        const pretty = JSON.stringify(parsed, null, 2);
        const url = window.URL.createObjectURL(new Blob([pretty]));

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${entitlementId}-activation.json`);

        document.body.appendChild(link);
        link.click();

        if (link && link.parentNode) {
          link.parentNode.removeChild(link);
        }
      })
      .catch(() => {
        message.error('Unable to retrieve activation JSON');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [instance, entitlementId]);

  if (!entitlementId) {
    return null;
  }
  return (
    <Button
      label={
        <span>
          Download Activation JSON for <strong>{entitlementId}</strong>
        </span>
      }
      loading={loading}
      disabled={loading}
      icon={<DownloadOutlined />}
      style={{ fontFamily: 'monospace', fontSize: 12, display: 'inline-block', marginLeft: 0 }}
      onClick={() => onDownloadEntitlement()}
    />
  );
};

export default EntitlementDownload;
