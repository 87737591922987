import React from 'react';
import styled from 'styled-components';

const Spinner = styled.div`
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  border: 10px solid ${p => p.theme.colour.accent};
  border-top: 10px solid ${p => p.theme.colour.lightSilver};
  border-bottom: 10px solid ${p => p.theme.colour.lightSilver};
  border-radius: 100%;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  margin-top: 50px;
  -webkit-animation: spin 2s ease infinite;
  animation: spin 2s ease infinite;
`;

const Loader: React.FunctionComponent = () => <Spinner />;

export default Loader;
