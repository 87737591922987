import styled from 'styled-components';
import DanteLogo from '../DanteLogo';
import { UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

const Flexed = styled.div`
  flex: 1;
  padding: 0 20px;
  line-height: 40px;
  user-select: none;
`;

const Profile = styled.div`
  float: right;
  margin-right: 4px;
  line-height: 40px;
  color: ${p => p.theme.colour.white};
  cursor: pointer;
`;

const Heading = styled.div`
  svg {
    margin-right: 4px;
    vertical-align: text-top;
  }
  color: ${p => p.theme.colour.white};
  font-weight: bold;
  cursor: pointer;
`;

const ProfileLabel = styled.div`
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  text-align: right;
  vertical-align: top;
`;

const Avatar = styled(UserOutlined)`
  color: ${p => p.theme.colour.white};
  width: auto;
  height: 16px;
  margin-left: 6px;
`;

const NavBar = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: row;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background-color: ${p => p.theme.colour.charcoalGrey};
  z-index: 6;
`;

const TopNav = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const msuser= instance.getActiveAccount();
  
  const userLabel = msuser ? `${msuser.name} (${msuser.username})` : 'Unknown User';
  const profileLabel = (
    <ProfileLabel>
      {userLabel}
    </ProfileLabel>
  );

  return (
    <NavBar>
      <Flexed style={{ marginRight: 'auto' }}>
        <Heading onClick={() => navigate('/products')}>
          <DanteLogo icon /> IFE Product Support Portal
        </Heading>
      </Flexed>
      <Flexed>
        <Profile>
          {profileLabel} <Avatar />
        </Profile>
      </Flexed>
    </NavBar>
  );
};

export default TopNav;
