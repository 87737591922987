import '@fontsource/open-sans';
import './Theme.less';

const Theme: any = {
  colour: {
    white: '#fff',
    buttonGrey: '#b3b3b3',
    lightSilver: '#f2f2f2',
    silver: '#e2e2e2',
    darkSilver: '#d2d2d2',
    lightGrey: '#9b9b9c',
    mediumGrey: '#666665',
    charcoalGrey: '#323232',
    midnightGrey: '#1c1c1c',
    babyBlue: '#5bc0de',
    errorRed: '#CC0000',
    stroke: '#666665',
    accent: '#cc092f',
    accentDark: '#930723',
    accentDarker: '#62081a',
    inactive: '#e4e4e4',
    emeraldGreen: '#23c064',
  },
};

export default Theme;
