import { flatMap, get } from 'lodash';
import moment from 'moment';
import Button from '../components/Button';
import { PATHS } from '../components/layout/Routes';
import { CopyOutlined, EyeOutlined } from '@ant-design/icons';
import { notification } from 'antd';
// @ts-ignore
declare const CONFIG;

const flatMapFilter = (pools: any[], key: string, filter: string, match: string) => {
  return flatMap(pools, (pool: any) => {
    const data = pool[key];
    return data
      .filter((d: any) => get(d, filter, null) === match)
      .map((d: any) => ({
        ...d,
        poolUUID: pool.uuid,
      }));
  });
};

const formatDescription = (description: string, length = 30) => {
  const value = description || '';
  return value.length > length ? `${value.substring(0, length)}...` : value;
};

const formatPoolName = (name: string, length = 40) => {
  const value = name || '';
  return value.length > length ? `${value.substring(0, length)}...` : value;
};

const formatTimestamp = (inStamp: string) => {
  const formatted = moment.utc(inStamp).format('YYYY-MM-DD HH:mm:ss');
  return `${formatted || '-'} (UTC)`;
};

const calculateLastTransaction = (row: any) => {
  let flatTransactions = [...(row.inboundAllocations || []), ...(row.ouboundAllocations || [])];
  const sorted = flatTransactions.sort((a: any, b: any) => {
    return moment(b.created.at).unix() - moment(a.created.at).unix();
  });
  return sorted[0] ? formatTimestamp(sorted[0].created.at) : 'None';
};

const formatFastspringLink = (source: any) => {
  if (!source || !source.name || !source.reference) return '-';
  const { fastspring } = CONFIG.externalUrlPrefixes;
  if (source.name.toLowerCase() !== 'fastspring' || !source.externalId) {
    return source.reference;
  }
  return (
    <a target="_blank" rel="noreferrer" href={`${fastspring}${source.externalId}`}>
      {source.reference}
    </a>
  );
};

const formatDestination = (outboundAllocation: any) => {
  const entitlementId = get(outboundAllocation, 'entitlement', null);
  if (!entitlementId) {
    return '-';
  }

  return (
    <>
      <div style={{ fontFamily: 'monospace', margin: '6px 0' }}>{entitlementId}</div>
      <Button
        label="View"
        type="dashed"
        icon={<EyeOutlined />}
        path={`${PATHS.ENTITLEMENTS}/?search=${entitlementId}&category=entitlementId&refParams=${encodeURIComponent(
          window.location.search
        )}`}
      />
      <Button
        label="Copy to Clipboard"
        icon={<CopyOutlined />}
        onClick={() => {
          notification.success({ message: 'Entitlement ID copied to your clipboard' });
          navigator.clipboard.writeText(entitlementId);
        }}
      />
    </>
  );
};

const mapPoolSKUFeatures = (pool: any) => {
  if (!pool) {
    return null;
  }
  const skus = [pool.productSKU, ...pool.featureSKUs];
  return skus.reduce((acc: any, curr: any) => {
    for (const feature of curr.features) {
      acc.push({
        sku: curr.sku,
        type: curr.type,
        id: feature.id,
        value: feature.value,
      });
    }
    return acc;
  }, []);
};

export {
  flatMapFilter,
  formatTimestamp,
  formatDestination,
  mapPoolSKUFeatures,
  formatFastspringLink,
  calculateLastTransaction,
  formatDescription,
  formatPoolName,
};
