import { AimOutlined, GoldOutlined, InfoCircleOutlined, RadarChartOutlined, ShoppingOutlined } from '@ant-design/icons';
import { Route, Routes, Navigate } from 'react-router-dom';

import { Products, Product, NotFound, Pools, Pool, Entitlements, SKUs, About } from '../../pages';

export const PATHS = {
  PRODUCTS: '/products',
  PRODUCT: '/products/:id',
  POOLS: '/pools',
  POOL: '/pools/:id',
  ENTITLEMENTS: '/entitlements',
  SKUS: '/skus',
  ABOUT: '/about',
};

export const Navigation = [
  {
    path: PATHS.PRODUCTS,
    label: 'Products',
    Icon: ShoppingOutlined,
  },
  {
    path: PATHS.POOLS,
    label: 'Pools & Transactions',
    Icon: GoldOutlined,
  },
  {
    path: PATHS.ENTITLEMENTS,
    label: 'Device Entitlements',
    Icon: AimOutlined,
  },
  {
    path: PATHS.SKUS,
    label: 'SKU Viewer',
    Icon: RadarChartOutlined,
  },
  {
    path: PATHS.ABOUT,
    label: 'About',
    Icon: InfoCircleOutlined,
  },
];

const Router = (props: any) => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to={PATHS.PRODUCTS} />} />
      <Route path={PATHS.PRODUCTS} element={<Products />} />
      <Route path={PATHS.PRODUCT} element={<Product />} />
      <Route path={PATHS.POOLS} element={<Pools />} />
      <Route path={PATHS.POOL} element={<Pool />} />
      <Route path={PATHS.ENTITLEMENTS} element={<Entitlements />} />
      <Route path={PATHS.SKUS} element={<SKUs />} />
      <Route path={PATHS.ABOUT} element={<About />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Router;
