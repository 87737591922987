import React, { useCallback, useEffect, useState } from 'react';
import { RouteProps } from 'react-router-dom';
import DataLayout from '../components/layout/DataLayout';
import Button, { ButtonList } from '../components/Button';
import { PATHS } from '../components/layout/Routes';
import { hextoASCII } from '../formatter/productConfig';
import { ClientType, getClient } from '../graphql/client';
import { ProductConfigsQuery } from '../graphql/queries';
import gql from 'graphql-tag';
import Table from '../components/Table';
import { Error } from './Errors';
import Loader from '../components/Loader';
import { PlusCircleOutlined } from '@ant-design/icons';
import { hasWriteAccess } from '../helper/authms';
import { useMsal } from '@azure/msal-react';

const ProductsColumns = [
  {
    accessor: 'manufacturerId',
    Header: 'Manufacturer name',
    Cell: ({ cell }: { cell: any }) => (
      <span>
        {hextoASCII(cell.value)}
        <div style={{ fontFamily: 'monospace' }} className="psp-table-sublabel">
          ( {cell.value} )
        </div>
      </span>
    ),
  },
  {
    accessor: 'modelId',
    Header: 'Model name',
    Cell: ({ cell }: { cell: any }) => (
      <span>
        {hextoASCII(cell.value)}
        <div style={{ fontFamily: 'monospace' }} className="psp-table-sublabel">
          ( {cell.value} )
        </div>
      </span>
    ),
  },
  {
    accessor: 'version',
    Header: 'Product version',
    Cell: ({ cell }: { cell: any }) => {
      return <span style={{ fontFamily: 'monospace' }}>{cell.value ? `${cell.value}+` : 'Default'}</span>;
    },
  },
  {
    accessor: 'active',
    sort: true,
    Header: 'Enabled / Active',
    Cell: ({ cell }: { cell: any }) => (cell.value ? 'Yes' : <strong>No</strong>),
  },
  {
    accessor: 'baseSKU',
    Header: 'Product Base SKU',
    minWidth: 300,
    Cell: ({ cell }: { cell: any }) => {
      return cell.value ? cell.value.sku : '-';
    },
  },
  {
    accessor: '_id',
    Header: 'Actions',
    Cell: ({ cell }: { cell: any }) => {
      const data = cell.row.original;
      const searchVal = `?search=${data.manufacturerId}:${data.modelId}&category=manufModel`;
      return (
        <ButtonList>
          <Button label="More Details" path={`${PATHS.PRODUCTS}/${cell.value}`} />
          <Button label="Transaction Search" path={`${PATHS.POOLS}${searchVal}`} />
        </ButtonList>
      );
    },
  },
];
const Products: React.FC<RouteProps> = () => {

  const { instance } = useMsal();
  const client = getClient(ClientType.productConfiguration, instance);;
  const canEdit = hasWriteAccess(instance.getActiveAccount());

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<string | undefined>(undefined);

  const load = useCallback(() => {
    setError(undefined);
    setData(null);
    setLoading(true);

    client
      .query({
        query: gql`
          ${ProductConfigsQuery}
        `,
      })
      .then((response: any) => {
        setData(response.data.configurations);
      })
      .catch((error: any) => {
        console.error(error);
        setData(null);
        setError(error.toString());
      })
      .finally(() => setLoading(false));
  }, [client]);

  useEffect(() => {
    load();
  }, [load]);

  let content = <Loader />;
  if (error) {
    content = <Error message={error.toString()} />;
  }

  if (!loading && data) {
    content = (
      <DataLayout nested title="" showRefresh={true} refreshAction={() => load()}>
        <Table negativeMargin={true} columns={ProductsColumns} data={data} />
      </DataLayout>
    );
  }

  return (
    <DataLayout
      title="Products - Summary"
      action={
        canEdit ? (
          <Button
            disabled={loading}
            type="primary"
            label="New Product"
            icon={<PlusCircleOutlined />}
            path={`/products/new`}
          />
        ) : null
      }
    >
      {content}
    </DataLayout>
  );
};

export default Products;
