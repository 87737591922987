import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import MainLayout from './components/layout/MainLayout';
import theme from './styles/Theme';
import AuthManagerMS from './components/AuthManagerMS';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthManagerMS>
        <BrowserRouter>
          <MainLayout />
        </BrowserRouter>
      </AuthManagerMS>
    </ThemeProvider>
  );
}

export default App;
