import { formatDescription } from '../formatter/pool';

const textLayout = {
  vertical: {
    title: {
      textAnchor: 'start',
      x: 40,
    },
    attributes: {
      x: 40,
      y: 20,
    },
    type: {
      x: 40,
      y: -20,
    },
  },
};

const SKUTreeElement = ({ nodeDatum, orientation, toggleNode, onNodeClick }) => {
  const description = nodeDatum.attributes.description || '';
  return (
    <>
      <circle r={20} onClick={toggleNode}></circle>
      <g className="rd3t-label">
        <text className="rd3t-label__attributes" style={{ fontSize: 10 }} {...textLayout[orientation].type}>
          {nodeDatum.attributes.type}
        </text>
        <text className="rd3t-label__title" {...textLayout[orientation].title} onClick={onNodeClick}>
          {nodeDatum.name}
        </text>
        <text className="rd3t-label__attributes" {...textLayout[orientation].attributes}>
          {formatDescription(description)}
        </text>
      </g>
    </>
  );
};

export default SKUTreeElement;
