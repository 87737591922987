import { ApolloClient, createHttpLink, DefaultOptions, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { AuthenticationResult, InteractionRequiredAuthError, IPublicClientApplication } from '@azure/msal-browser';

// @ts-ignore
const { ifeApps } = CONFIG;

enum ClientType {
  productConfiguration,
  poolAllocations,
}

type SingletonClientMap = {
  [key in ClientType]: ApolloClient<any> | undefined;
};
let singletonClients: SingletonClientMap = {
  [ClientType.productConfiguration]: undefined,
  [ClientType.poolAllocations]: undefined,
};

const getClient = (type: ClientType, instance: IPublicClientApplication ) => {
  
  if (singletonClients[type] !== undefined) {
    return singletonClients[type] as ApolloClient<any>;
  }

  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  };

  const endpoint = type === ClientType.poolAllocations ? ifeApps.poolAllocations.endpoint : ifeApps.productConfiguration.endpoint;

  const getAzureToken = async () => {

    const request = {
      scopes: ["User.Read"],
    };

    let accessToken;

    try {
      const responseToken = await instance.acquireTokenSilent(request);
      console.info("Access token refreshed silently.");
      accessToken = responseToken.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        console.info("Silent token acquisition failed. Redirecting for login...");
        instance.acquireTokenRedirect(request);
        instance.handleRedirectPromise().then((responseToken: AuthenticationResult | null) => {
          if (responseToken) {
            accessToken = responseToken.accessToken;
            console.log("Access token received from redirect");
          } else {
            console.log("No token acquired from redirect.");
            return null;
          }
        }).catch((error) => {
          console.error("Error acquiring token after redirect:", error);
          return null;
        });
        console.log(accessToken);
      } else {
        console.error("Error acquiring token silently:", error);
        return null;
      }
    }
    return accessToken;
  };


  const httpLink = createHttpLink({
    uri: endpoint,
  });

  const authenticator = setContext(async (_, { headers }) => {
    const token = await getAzureToken();
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    };
  });

  const client = new ApolloClient({
    link: authenticator.concat(httpLink),
    cache: new InMemoryCache({
      addTypename: false,
    }),
    defaultOptions,
  });

  singletonClients[type] = client;
  return client as ApolloClient<any>;
};

export { getClient, ClientType };
