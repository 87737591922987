import { Button } from 'antd';
import styled from 'styled-components';
import DanteLogo from './DanteLogo';
import { MouseEvent } from 'react';

const Wrap = styled.div`
  text-align: center;
  padding: 24px;
  p {
    font-weight: bold;
    font-size: 18px;
    padding: 12px;
  }
`;

const ErrorDetails = styled.div`
  background-color: '#EEE';
  border-radius: 5;
  width: 800;
  margin: 15px auto;
  padding: 5;
`;

const handleRetry = (e: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
  e.preventDefault();
  sessionStorage.clear();
  localStorage.clear();
  window.location.reload();
};

const AuthError = ({ error = undefined, message = '' }: { error: Error | undefined; message: string }) => (
  <Wrap>
    <DanteLogo />

    <p>{message}</p>
    {error && (
      <ErrorDetails>
        <p>Error: {error.message}</p>
        <pre>{error.stack || 'No Additional Information'}</pre>
      </ErrorDetails>
    )}
    <p>
      <Button type="primary" onClick={e => handleRetry(e)}>
        Try Again
      </Button>
    </p>
  </Wrap>
);

export default AuthError;
