import React from 'react';
import { RouteProps } from 'react-router-dom';
import DataLayout from '../components/layout/DataLayout';
import { PATHS } from '../components/layout/Routes';
import { Card, List, Typography } from 'antd';
import DanteLogo from '../components/DanteLogo';
import Meta from 'antd/lib/card/Meta';

// @ts-ignore
declare const CONFIG;
const { groupRead, groupWrite } = CONFIG.authms;

const gridFormat = {
  gutter: 16,
  column: 2,
};

const buildInfo = [
  {
    label: 'Build Date',
    value: process.env.REACT_APP_BUILD_DATE || 'Unknown',
  },
  {
    label: 'Package Version',
    value: process.env.REACT_APP_NAME ? `${process.env.REACT_APP_NAME} @ ${process.env.REACT_APP_VERSION}` : 'Unknown',
  },
  {
    label: 'Git Short SHA-1',
    value: process.env.REACT_APP_GIT_SHA || 'Unknown',
  },
];

const endpointInfo = [
  {
    label: 'IDP AD Groups',
    value: (
      <>
        <div>
          Read/Write:
          <span style={{ fontFamily: 'monospace' }}> {groupWrite}</span>
        </div>
        <div>
          Read:
          <span style={{ fontFamily: 'monospace' }}> {groupRead}</span>
        </div>
      </>
    ),
  },
  {
    label: 'Product Configuration Manager (PCM)',
    value: CONFIG.ifeApps.productConfiguration.id,
  },
  {
    label: 'Pools & Allocations Manager (PAM)',
    value: CONFIG.ifeApps.poolAllocations.id,
  },
  {
    label: 'Software License Server (SLS)',
    value: CONFIG.externalUrlPrefixes.softwareLicenseServer,
  },
];

const About: React.FC<RouteProps> = () => {
  return (
    <DataLayout backPath={PATHS.POOLS} title={`About`}>
      <>
        <Card style={{ width: 300 }}>
          Audinate In-field Enablement (IFE) <strong>Product Support Portal</strong>
          <Meta
            style={{ marginTop: 24 }}
            avatar={<DanteLogo />}
            description={`Copyright © ${new Date().getFullYear()} Audinate Pty Ltd.`}
          />
        </Card>
        <Typography.Title style={{ marginTop: 24 }} level={5}>
          Endpoints
        </Typography.Title>
        <List
          grid={gridFormat}
          dataSource={endpointInfo}
          renderItem={item => (
            <List.Item>
              <Card title={item.label}>{item.value}</Card>
            </List.Item>
          )}
        />
        <Typography.Title style={{ marginTop: 24 }} level={5}>
          Build Information
        </Typography.Title>
        <List
          grid={gridFormat}
          dataSource={buildInfo}
          renderItem={item => (
            <List.Item>
              <Card title={item.label}>{item.value}</Card>
            </List.Item>
          )}
        />
      </>
    </DataLayout>
  );
};

export default About;
