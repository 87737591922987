import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 25em auto;
  text-align: center;
  font-weight: bold;
`;

export const NotFound = () => {
  return <Wrapper>Page not found.</Wrapper>;
};

export const IDNotFound = () => {
  return <Wrapper>Item not found.</Wrapper>;
};

type ErrorProps = {
  message?: string | undefined;
};

export const Error: React.FC<ErrorProps> = ({ message }) => {
  return (
    <Wrapper>
      An error occured processing your request.
      {message && <pre>{message}</pre>}
    </Wrapper>
  );
};
