import { createRoot } from 'react-dom/client';
import App from './App';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import AuthError from './components/AuthError';

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  // @ts-ignore
  declare const CONFIG;
  const { clientId, authority, redirectUri } = CONFIG.authms;

  const instanceConfig = {
    auth: {
      clientId: clientId,
      authority: authority,
      redirectUri: redirectUri
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false
    }
  }
  const instance = new PublicClientApplication(instanceConfig);
  instance.initialize().then(() => {
    root.render(
      <MsalProvider instance={instance}>
        <App />
      </MsalProvider>
    );
  }).catch((error) => {
    console.error("MSAL Initialization failed:", error);
    return <AuthError error={undefined} message={"We couldn't log you in. Please try again later or contact Nimbus."} />;
  });
}
