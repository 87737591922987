import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

interface NestedProps {
  nested?: boolean;
}

const Header = styled.div`
  display: flex;
  margin: 2rem 0;
  flex-direction: row;
  width: ${(p: NestedProps) => (p.nested ? 'auto' : '80vw')};
  justify-content: space-between;
`;

const BottomNav = styled.div`
  display: flex;
  width: 80vw;
  margin: 2rem 0;
  flex-direction: row;
  justify-content: flex-end;
`;

const Title = styled.div`
  font-weight: ${(p: NestedProps) => (p.nested ? 500 : 'bold')};
  font-size: 18px;
`;

const BackNav = styled.div`
  div {
    text-decoration: underline;
    text-align: right;
    cursor: pointer;
  }
  text-align: end;
  align-self: flex-end;
  align-items: flex-end;
  flex: 1;
`;

const RightNav = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Action = styled.div`
  justify-content: flex-end;
  flex-direction: row;
  align-items: flex-end;
`;

const DataWrap = styled.div`
  width: ${(p: NestedProps) => (p.nested ? '80vw' : 'auto')};
  margin: ${(p: NestedProps) => (p.nested ? '0' : '2rem')};
`;

interface DataLayoutProps {
  title?: string;
  backLabel?: string;
  backPath?: string;
  showRefresh?: boolean;
  refreshAction?: Function;
  nested?: boolean;
  action?: JSX.Element | null;
  duplicateActionAtBottom?: boolean;
  children?: JSX.Element | JSX.Element[] | string;
}

const DataLayout: React.FC<DataLayoutProps> = ({
  title,
  backLabel,
  backPath,
  showRefresh = false,
  refreshAction,
  children,
  nested,
  action,
  duplicateActionAtBottom,
}) => {
  const navigate = useNavigate();

  // eslint-disable-next-line
  let [searchParams, _] = useSearchParams();
  const refParams = searchParams.get('refParams');

  const backURL = backPath ? `${backPath}${refParams ? decodeURIComponent(refParams) : ''}` : null;

  return (
    <DataWrap nested={nested}>
      <Header>
        {title && <Title nested={nested}>{title}</Title>}
        <RightNav>
          {action && <Action>{action}</Action>}
          {(showRefresh || (backLabel && backURL)) && (
            <BackNav>
              {backLabel && backURL && (
                <div style={{ display: showRefresh ? 'inline-block' : 'block' }} onClick={() => navigate(backURL)}>
                  {backLabel}
                </div>
              )}
              {showRefresh && (
                <>
                  {backLabel ? ' | ' : ''}
                  <div style={{ display: 'inline-block' }} onClick={() => refreshAction && refreshAction()}>
                    Refresh
                  </div>
                </>
              )}
            </BackNav>
          )}
        </RightNav>
      </Header>
      {children ? children : null}
      {duplicateActionAtBottom && action && <BottomNav>{action}</BottomNav>}
    </DataWrap>
  );
};

export default DataLayout;
