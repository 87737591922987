import React from 'react';
import styled from 'styled-components';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip as AntdTooltip } from 'antd';

const Icon = styled(QuestionCircleOutlined)`
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  color: ${p => p.theme.colour.charcoalGrey};
  border-radius: 25px;
  width: auto;
  height: 15px;
  padding: 1px;
  vertical-align: sub;
  margin-bottom: 0px;
  margin-left: 4px;
`;

interface TooltipProps {
  message: string;
}

const Tooltip: React.FunctionComponent<TooltipProps> = ({ message }) => {
  return (
    <AntdTooltip title={message}>
      <Icon data-rh={message} />
    </AntdTooltip>
  );
};

export default Tooltip;
