import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Button as AntButton } from 'antd';
import { ButtonProps as AntButtonProps } from 'antd';
const ButtonElm = styled(AntButton)`
  display: inline-block;
  margin: 2px;
  width: auto;
  min-height: 38px;
  min-width: ${(p: ButtonProps) => (p.label ? 130 : 40)}px;
`;

interface ButtonProps extends AntButtonProps {
  path?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  label?: JSX.Element | string;
  disabled?: boolean;
  isExternal?: boolean;
  submit?: boolean;
  icon?: JSX.Element;
  block?: boolean;
}

const navigateExternal = (path: string) => {
  window.open(path, '_blank');
};

export const ButtonList = styled.div`
  button {
    width: 175px;
    margin: 0 auto;
    display: block;
  }
  button:first-of-type {
    margin-bottom: 4px;
  }
`;

const Button: React.FunctionComponent<ButtonProps> = ({
  path,
  label,
  disabled,
  isExternal,
  onClick,
  icon,
  type,
  style,
  block,
  ...props
}) => {
  const navigate = useNavigate();
  return (
    <ButtonElm
      icon={icon}
      type={type}
      style={style}
      block={block}
      onClick={onClick || !path ? onClick : () => (isExternal ? navigateExternal(path) : navigate(path))}
      disabled={disabled}
      {...props}
    >
      {label ? label : null}
    </ButtonElm>
  );
};

export default Button;
