import React from 'react';
import styled from 'styled-components';
import { Navigation } from './Routes';
import { NavLink, useMatch, useNavigate } from 'react-router-dom';
import { default as AntIcon } from '@ant-design/icons';

const NavBar = styled.div`
  position: fixed;
  top: 40px;
  left: 0;
  width: 200px;
  height: calc(100vh - 40px);
  background-color: ${p => p.theme.colour.darkSilver};
  z-index: 5;
`;

const SidebarList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Item = styled.li`
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid ${p => p.theme.colour.charcoalGrey};
  border-right: 1px solid ${p => p.theme.colour.charcoalGrey};
  transition: border 0.2s;
  transition: background-color 0.2s;
  > *:link,
  > a {
    color: ${p => p.theme.colour.charcoalGrey};
    text-decoration: none;
  }
  svg {
    margin-bottom: -2px;
    margin-left: 4px;
    margin-right: 4px;
    width: auto;
    height: 16px;
  }
  &.nav-active {
    background-color: ${p => p.theme.colour.lightSilver};
    border-right: 8px solid ${p => p.theme.colour.charcoalGrey};
  }
`;

interface SidebarItemProps {
  label: string;
  path: string;
  Icon: typeof AntIcon;
}

const SidebarItem: React.FC<SidebarItemProps> = ({ path, label, Icon }) => {
  let navigate = useNavigate();
  const active = !!useMatch(`${path}/*`);
  return (
    <Item onClick={() => navigate(path)} className={active ? 'nav-active' : 'nav-inactive'}>
      <Icon style={{ marginRight: 2, verticalAlign: 'unset' }} onClick={() => navigate(path)} />
      <NavLink to={path}>{label}</NavLink>
    </Item>
  );
};

const SideNav = () => {
  return (
    <NavBar>
      <SidebarList>
        {Navigation.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <SidebarItem {...item} />
            </React.Fragment>
          );
        })}
      </SidebarList>
    </NavBar>
  );
};

export default SideNav;
