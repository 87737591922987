// @ts-nocheck
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTable, useSortBy, usePagination, useFilters } from 'react-table';
import { ClientType, getClient } from '../graphql/client';
import { PaginatedOutboundAllocationsWithFilter } from '../graphql/queries';

import styled from 'styled-components';

export const StyledTable = styled.div`
  margin-top: ${props => (props.negativeMargin ? -20 : -5)}px;
  .psp-table-scroll-container {
    max-height: 700px;
    overflow-y: auto;
  }

  .psp-table {
    text-align: center;
    width: 100%;
  }

  .psp-table-tr {
    td {
      padding: 0.3rem;
      border-right: 1px solid ${p => p.theme.colour.lightSilver};
    }
    background-color: ${p => p.theme.colour.darkSilver};
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  .psp-table-tr:nth-child(odd) {
    background-color: ${p => p.theme.colour.silver};
  }

  .psp-table-th {
    color: ${p => p.theme.colour.white};
    font-weight: bold;
    border-right: 1px solid ${p => p.theme.colour.lightSilver};
    background-color: ${p => p.theme.colour.mediumGrey};
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .psp-table-th,
  .psp-table-td {
    margin: 0;
    padding: 0.5rem;
    :last-child {
      border-right: 0;
    }
  }

  .psp-table-sublabel {
    font-size: 11px;
    margin-bottom: 2px;
  }

  .psp-table-pagination {
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .psp-table-tbody {
    max-height: 350px;
    overflow-y: auto;
  }
`;

export const DataColumns = [
  {
    accessor: 'label',
  },
  {
    accessor: 'value',
  },
];

interface TableProps {
  tableName: string;
  columns: any;
  data?: any;
  sorting?: boolean;
  negativeMargin?: boolean;
  headless?: boolean;
  filter?: string;
  instance?: any;
}

// Default Paginated Table without API call (Data will just be based on the passed parameter)
const PaginatedTable: React.FC<TableProps> = ({ tableName, columns, data, negativeMargin = false, sorting = true, headless = false }) => {
  const [pageIndex, setPageIndex] = useState(0); // State to track current page index
  const pageSize = 50; // Default no.of rows per page

  const totalItems = data.length; // Total number of items
  const totalPages = Math.ceil(totalItems / pageSize); // Total number of pages


  const paginatedData = useMemo(() => {
    const start = pageIndex * pageSize;
    const end = start + pageSize;
    return data.slice(start, end);
  }, [data, pageIndex, pageSize]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, } = useTable(
    {
      columns,
      data: paginatedData,
    },
    useFilters,
    sorting ? useSortBy : undefined,
    usePagination
  );
  /* Pagination controls */
  const Pagination = ({ pageIndex }) => {
    return (

      <div className="psp-table-pagination" {...getTableProps()}>
        <button onClick={() => setPageIndex(pageIndex - 1)} disabled={pageIndex === 0} style={{ marginRight: '50px' }}>{` << `}Previous</button>
        <span>
         <strong>{`Page ` +(pageIndex + 1)}</strong>{` of `}<strong>{`Page ` +totalPages}</strong><strong>{` | Total: ` + totalItems}</strong>
        </span>
        <button onClick={() => setPageIndex(pageIndex + 1)} disabled={pageIndex === totalPages - 1 || totalPages === 0}style={{ marginLeft: '50px' }}>Next{` >> `}</button>
      </div>

    );
  };

  // Render the UI for your table
  return (
    <StyledTable negativeMargin={negativeMargin}>
       {totalItems > pageSize && (<Pagination pageIndex={pageIndex}/>)}
      <br/>  
      <div className="psp-table-scroll-container">    
      <table className="psp-table" {...getTableProps()}>
        {!headless && (
          <thead>
            {headerGroups.map(headerGroup => (
              <tr className="psp-table-tr" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th
                    className="psp-table-th"
                    {...column.getHeaderProps({
                      style: {
                        minWidth: column.minWidth,
                        width: column.width,
                        maxWidth: column.maxWidth,
                      },
                    })}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        )}
        <tbody className="psp-table-tbody" {...getTableBodyProps()}>
          {!rows.length && (
            <tr className="psp-table-tr">
              <td colSpan={columns.length} data="psp-table-td">
                No data.
              </td>
            </tr>
          )}
          {rows.map((row: any, i: any) => {
            prepareRow(row);
            return (
              <tr className="psp-table-tr" {...row.getRowProps()}>
                {/* Add a custom column for the row number */}
                {tableName === "poolOutbound" && (
                <td className="psp-table-td-no" {...row.getRowProps()}>{pageIndex * pageSize + i + 1}</td>
                )}
                {row.cells.map((cell: any, i: any) => {
                  const headlessLabel = headless && i === 0;
                  // Skip the first column
                  if (tableName === "poolOutbound" && i === 0) {
                    return null;
                  }
                  return (
                    <td
                      data="psp-table-td"
                      style={{ width: headlessLabel ? '40%' : undefined }}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      </div>
      <br/>
      {totalItems > pageSize && (<Pagination pageIndex={pageIndex}/>)}
    </StyledTable>
  );
};
// Paginated Table with API call
const PaginatedOutboundTable: React.FC<TableProps> = ({ tableName, columns, negativeMargin = false, sorting = true, headless = false, filter, instance}) => {
  const [pageIndex, setPageIndex] = useState(0); // State to track current page index
  const pageSize = 50; // Default no.of rows per page
  const [data, setData] = useState([]);
  const [pageData, setPageData] = useState({});
  
  const fetchPaginatedData = useCallback(async ({ pageIndex }) => {
    const client = getClient(ClientType.poolAllocations, instance);
    try {
      const currentPage:number = pageIndex + 1;
      const outboundQuery = await client.query({
        query: PaginatedOutboundAllocationsWithFilter(currentPage, pageSize, filter),
      }); 
      setData(outboundQuery.data?.paginatedOutboundData?.result || []);
      setPageData(outboundQuery.data?.paginatedOutboundData?.pageInfo || {});
    } catch (error) {
      console.error(error);
    }
  }, [filter, instance]);
  
  useEffect(() => {
    fetchPaginatedData({ pageIndex });
  }, [fetchPaginatedData, pageIndex]);

  const paginatedData = useMemo(() => {
    return data;
  }, [data]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: paginatedData ? paginatedData : [],
    },
    useFilters,
    sorting ? useSortBy : undefined,
    usePagination
  );
  // Set Pagination controls
  const Pagination = ({ pageIndex }) => {
    return (

    <div className="psp-table-pagination" {...getTableProps()}>
      <button onClick={() => setPageIndex(pageIndex - 1)} disabled={pageIndex === 0} style={{ marginRight: '50px' }}>{` << `}Previous</button>
      <span>
       <strong>{`Page ` + (pageIndex + 1)}</strong>{` of `}<strong>{`Page ` + pageData?.totalPages}</strong><strong>{` | Total: ` + pageData?.totalCount}</strong>
      </span>
      <button onClick={() => setPageIndex(pageIndex + 1)} disabled={pageIndex === pageData?.totalPages - 1 || pageData?.totalPages === 0}style={{ marginLeft: '50px' }}>Next{` >> `}</button>
    </div>

    );
  };

  // Render the UI for your table
  return (
    <StyledTable negativeMargin={negativeMargin}>
      {pageData?.totalCount > pageSize && (<Pagination pageIndex={pageIndex}/>)}
      <br/>  
      <div className="psp-table-scroll-container">    
      <table className="psp-table" {...getTableProps()}>
        {!headless && (
          <thead>
            {headerGroups.map(headerGroup => (
              <tr className="psp-table-tr" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th
                    className="psp-table-th"
                    {...column.getHeaderProps({
                      style: {
                        minWidth: column.minWidth,
                        width: column.width,
                        maxWidth: column.maxWidth,
                      },
                    })}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        )}
        <tbody className="psp-table-tbody" {...getTableBodyProps()}>
          {!rows.length && (
            <tr className="psp-table-tr">
              <td colSpan={columns.length} data="psp-table-td">
                No data.
              </td>
            </tr>
          )}
          {rows.map((row: any, i: any) => {
            prepareRow(row);
            return (
              <tr className="psp-table-tr" {...row.getRowProps()}>
                {/* Add a custom column for the row number */}
                {tableName === "poolOutbound" && (
                <td className="psp-table-td-no" {...row.getRowProps()}>{pageIndex * pageSize + i + 1}</td>
                )}
                {row.cells.map((cell: any, i: any) => {
                  const headlessLabel = headless && i === 0;
                  // Skip the first column
                  if (tableName === "poolOutbound" && i === 0) {
                    return null;
                  }
                  return (
                    <td
                      data="psp-table-td"
                      style={{ width: headlessLabel ? '40%' : undefined }}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      </div>
      <br/>
      {pageData?.totalCount > pageSize && (<Pagination pageIndex={pageIndex}/>)}
    </StyledTable>
  );
};

export {
   PaginatedTable,
   PaginatedOutboundTable
}
