import { Input } from 'antd';
import styled from 'styled-components';

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
`;

const LanguageGroup = styled(Input.Group)`
  width: 80%;
  max-width: 800px;
  margin-bottom: 16px;
  & > div {
    margin: 0;
  }
  .ant-input-group-wrapper {
    margin-top: 6px;
  }
  .ant-input-group-wrapper > .ant-input-wrapper > .ant-input-group-addon {
    min-width: 140px;
  }
`;

const ProductConfigurationHeader = styled.div`
  background-color: ${p => p.theme.colour.mediumGrey};
  color: ${p => p.theme.colour.white};
  height: 60px;
  line-height: 60px;
  display: flex;
  flex-direction: row;
  label {
    font-weight: bold;
    margin-left: 12px;
    flex-grow: 1;
  }
  div {
    margin-right: 12px;
  }
  div > button {
    background: none;
    height: 40px;
    width: 45px;
  }
  div > button > span > svg {
    width: 24px;
    height: 24px;
  }
`;

export { LanguageGroup, ProductConfigurationHeader, ButtonGroup };
