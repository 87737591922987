import { chain, get, isEmpty, isEqual, isNil, isNull, isNumber, isUndefined, keyBy, xorWith } from 'lodash';
import { hextoASCII } from './form';

export const DEFAULT_LOCALE = 'EN';
export const LOCALES = ['EN', 'DE', 'ES', 'FR', 'JA', 'KO', 'PT', 'ZH'];

export const PRODUCT_CONFIGURATION_DEFAULTS = {
  active: 'false',
  featureSKUs: [],
  activationClients: [],
  entitlementCreationClients: [],
  notes: null,
  reference: null,
};

// Build a product configuration from the form.
const formToProductConfig = (formData: any, existingProductData: any, id: string | undefined) => {
  const hasVersion =
    (formData.versionA || '').toString().trim() ||
    (formData.versionB || '').toString().trim() ||
    (formData.versionC || '').toString().trim();

  const reference = (formData.reference || '').trim();
  const notes = (formData.notes || '').trim();
  let productConfig = {
    ...PRODUCT_CONFIGURATION_DEFAULTS,

    // Overlay existing attributes.
    ...(existingProductData ? existingProductData : {}),

    // ID
    ...(id ? { _id: id } : {}),

    // Active
    active: formData.active === 'true',

    // Owner
    owner: {
      email: formData.owner,
    },

    version: hasVersion
      ? `${formData.versionA || '0'}.${formData.versionB || '0'}.${formData.versionC || '0'}`
      : undefined,

    // Model & Manufacturer
    modelId: formData.modelIdHex.toLowerCase(),
    manufacturerId: formData.manufacturerIdHex.toLowerCase(),

    // Allowed Entitlement Creation Clients
    ...(formData.preActivationOption === 'false'
      ? {
          entitlementCreationClients: ['com.audinate.ife-gui'],
        }
      : { entitlementCreationClients: [] }),

    // Allowed Activation Clients
    activationClients: ['com.audinate.ife-gui'],

    productSKUs: (formData.productSKUs || []).map(generatePCMSKU),
    featureSKUs: (formData.featureSKUs || []).map(generatePCMSKU),
    baseSKU: {
      link: formData.baseSKU.sku,
    },
    reference: reference.length ? reference : null,
    notes: notes.length ? notes : null,
  };
  return chain(productConfig)
    .omit(['__typename', '_id'])
    .omitBy(isUndefined)
    .omitBy(isNull)
    .value();
};

const generateContentLabels = (formFeatureSet: any, prefix: string) => {
  const labels = [];
  for (const locale of LOCALES) {
    const localeMessage = formFeatureSet[`${prefix}${locale}`];
    if (localeMessage) {
      labels.push({
        locale: locale.toUpperCase(),
        string: localeMessage,
      });
    }
  }
  return labels;
};

const generatePCMSKU = (productSKU: any) => {
  return {
    // Label
    label: generateContentLabels(productSKU, 'label'),
    sku: productSKU.sku.sku,

    // Pricing Multiplier
    ...(isNumber(parseFloat(productSKU.overridePriceUSD))
      ? {
          overridePriceUSD: parseFloat(productSKU.overridePriceUSD),
        }
      : {}),

    // OEM Pool UUID
    ...(productSKU.oemPaysOption === 'true' && productSKU.oemPoolUUID
      ? {
          associatedPoolId: productSKU.oemPoolUUID,
        }
      : {}),

    // Domain Locking Option
    ...(productSKU.domainPoolOption && productSKU.oemPoolUUID
    ? {
      isPoolIdDomainLocked: productSKU.domainPoolOption,
      }
    : {}),

    // Additional Information
    ...(productSKU.additionalInformationType && productSKU.aiLabelEN
      ? {
          message: {
            type: productSKU.additionalInformationType,
            content: generateContentLabels(productSKU, 'aiLabel'),
          },
        }
      : {}),
  };
};

const parseAdditionalInformationLabels = (labels: any, prefix: string) => {
  const findLocale = (locale: string) => {
    const existing = labels.find((l: any) => l.locale === locale);
    if (!existing || !existing.string) return undefined;
    return existing.string;
  };
  return {
    [`${prefix}EN`]: findLocale('EN'),
    [`${prefix}DE`]: findLocale('DE'),
    [`${prefix}ES`]: findLocale('ES'),
    [`${prefix}FR`]: findLocale('FR'),
    [`${prefix}JA`]: findLocale('JA'),
    [`${prefix}KO`]: findLocale('KO'),
    [`${prefix}PT`]: findLocale('PT'),
    [`${prefix}ZH`]: findLocale('ZH'),
  };
};

const generateSKUForm = (skus: any[]) => (sku: any) => {
  const parsed = {
    sku: skus[sku.sku],
    oemPaysOption: (isNil(get(sku, 'associatedPoolId')) === false).toString(),
    oemPoolUUID: get(sku, 'associatedPoolId'),
    domainPoolOption: get(sku, 'isPoolIdDomainLocked') ? (get(sku, 'isPoolIdDomainLocked')).toString() : 'false',
    overridePriceUSD: get(sku, 'overridePriceUSD', undefined),
    ...parseAdditionalInformationLabels(get(sku, 'label') || [], 'label'),
    additionalInformationType: get(sku, 'message.type')
      ? (get(sku, 'message.type') as string).toUpperCase()
      : undefined,
    ...parseAdditionalInformationLabels(get(sku, 'message.content') || [], 'aiLabel'),
  };
  return chain(parsed)
    .omitBy(isUndefined)
    .omitBy(isNull)
    .value();
};

const productConfigToForm = (productConfig: any, skuData: any[]) => {
  const baseSKU = productConfig.baseSKU;
  const productSKUs = productConfig.productSKUs || [];
  const versionParts = (productConfig.version || '').split('.');
  const hasVersion = versionParts && versionParts.length === 3;
  const skus = keyBy(skuData, 'sku') as any;
  const formData = {
    id: get(productConfig, '_id'),
    owner: get(productConfig, 'owner.email'),
    notes: get(productConfig, 'notes', ''),
    reference: get(productConfig, 'reference', ''),
    active: (productConfig.active || false).toString(),
    modelIdHex: get(productConfig, 'modelId'),
    manufacturerIdHex: get(productConfig, 'manufacturerId'),
    modelIdASCII: hextoASCII(get(productConfig, 'modelId')),
    manufacturerIdASCII: hextoASCII(get(productConfig, 'manufacturerId')),
    versionA: hasVersion ? versionParts[0] : null,
    versionB: hasVersion ? versionParts[1] : null,
    versionC: hasVersion ? versionParts[2] : null,

    preActivationOption: (
      (get(productConfig, 'entitlementCreationClients') || []).includes('com.audinate.ife-gui') === false
    ).toString(),

    baseSKU: {
      ...baseSKU,
      label: baseSKU.sku,
    },
    productSKUs: productSKUs.map(generateSKUForm(skus)),
    featureSKUs: (productConfig.featureSKUs || []).map(generateSKUForm(skus)),
  };
  const result = chain(formData)
    .omitBy(isUndefined)
    .omitBy(isNull)
    .value();
  return result;
};

const arrayEqual = function(x: any[], y: any[]) {
  // Make sure the comparison is with quantity-positive features.
  const xored = xorWith(
    x.filter(sku => sku.quantity),
    y.filter(sku => sku.quantity),
    isEqual
  );
  return isEmpty(xored);
};

const initConfigFromSKU = function(sku: any) {
  return {
    sku: sku,
    overridePriceUSD: '',
    labelEN: sku.description,
    oemPaysOption: 'false',
    domainPoolOption: 'false',
  };
};

const generatePrefixedLocaleList = (prefix: string) => {
  return LOCALES.map((locale: string) => `${prefix}${locale}`);
};

export { formToProductConfig, productConfigToForm, arrayEqual, initConfigFromSKU, generatePrefixedLocaleList };
