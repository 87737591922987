import { List, Modal } from 'antd';
import { formatTimestamp } from '../formatter/pool';

const SKUDetailModal: React.FunctionComponent<any> = ({ detail, onClose }) => {
  return (
    <Modal
      cancelText="Close"
      width={600}
      title={`SKU "${detail.sku}"`}
      visible={true}
      onCancel={() => onClose()}
      okButtonProps={{ style: { display: 'none' } }}
    >
      <List
        size="small"
        dataSource={[
          {
            label: 'Type',
            value: detail.type,
          },
          {
            label: 'Created at',
            value: formatTimestamp(detail.created?.at),
          },
          {
            label: 'Description',
            value: detail.description || '',
          },
          {
            label: 'Enabled?',
            value: detail.enabled ? 'Yes' : 'No',
          },
          {
            label: 'Factory Only?',
            value: detail.factoryOnly ? 'Yes' : 'No',
          },
          {
            label: 'Price (USD)',
            value: `$${detail.priceUSD || 0}`,
          },
          {
            label: 'Product Family',
            value: detail.productFamily || 'NONE',
          },
          {
            label: 'Features',
            value: (
              <ul>
                {detail.features
                  .map((feature: any) => (
                    <span>
                      <strong>{feature.id}</strong>
                      {': '}
                      {feature.value.join(', ')}
                    </span>
                  ))
                  .map((value: string, index: number) => (
                    <li key={index}>{value}</li>
                  ))}
              </ul>
            ),
          },
        ]}
        renderItem={(item: any) => (
          <List.Item>
            <List.Item.Meta title={item.label} description={item.value} />
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default SKUDetailModal;
