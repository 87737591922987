// This provider ensures that the user logs in before they can use the application.
// It protects all routes.

import React from 'react';
import AuthError from './AuthError';
import { useMsal } from '@azure/msal-react';
import { AccountInfo, InteractionStatus, IPublicClientApplication } from '@azure/msal-browser';
import { hasAccess } from '../helper/authms';
import Loader from './Loader';

type Props = React.PropsWithChildren<unknown>;

const checkAuthStatus = async (instance: IPublicClientApplication, activeAccount: AccountInfo | null) => {
  try {
    if (activeAccount) {
      instance.setActiveAccount(activeAccount);
    } else {
      await instance.handleRedirectPromise().then((loginResponse) => {
        if (loginResponse) {
          instance.setActiveAccount(loginResponse.account);
        }
      }).catch((e) => {
        console.error('Error handling redirect promise', e);
        return <AuthError error={e as Error} message={"We couldn't log you in. Please try again later or contact Nimbus."} />;
      });
    }
  } catch (e) {
    console.error('Error checking authentication status', e);
    return <AuthError error={e as Error} message={"We couldn't log you in. Please try again later or contact Nimbus."} />;
  }
};

const AuthManagerMS: React.FunctionComponent<Props> = ({ children }) => {
  const { instance, accounts, inProgress } = useMsal();
  const activeAccount = instance.getActiveAccount();

  checkAuthStatus(instance, activeAccount).then(() => {
    if (!activeAccount && ((inProgress !== InteractionStatus.Startup))) {
      console.error("No logged in user");
      instance.loginRedirect({
        scopes: ['User.Read'],
      }).catch((e) => {
        console.error('Login redirect failed', e);
        return <AuthError error={e as Error} message={"We couldn't log you in. Please try again later or contact Nimbus."} />;
      });
    }
  })
    .catch((error: any) => {
      console.error(error);
      return <AuthError error={error as Error} message={"We couldn't log you in. Please try again later or contact Nimbus."} />;
    });

  checkAuthStatus(instance, activeAccount);

  if (accounts && accounts.length === 0) {
    return <Loader />;
  }

  if (!hasAccess(activeAccount) && activeAccount) {
    return <AuthError error={undefined} message={"You do not have access to this application."} />;
  }

  return <>{children}</>;

};

export default AuthManagerMS;
